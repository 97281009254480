import React, { useContext, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { SIZE_CHART } from "../../constants";
import { UserContext } from "../../../context/UserContext";
import Option from "../../../product/component/Option";

export default function SizeChartOptions(props) {
  const { user } = useContext(UserContext);
  const { productType, value } = props;
  let sizeChart = user.config.sizeChart || SIZE_CHART;

  const [tab, setTab] = useState(Object.keys(sizeChart[productType])?.[0]);

  return (
    <Box>
      <Tabs value={tab} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
        {Object.keys(sizeChart[productType]).sort().map(k => {
          return <Tab value={k} sx={{ fontSize: ".75rem" }} label={k} onClick={() => setTab(k)} />;
        })}
      </Tabs>
      {Object.entries(sizeChart[productType]).map(([k, v]) => {
        if (!tab || tab === k) {
          return (
            <Box my={2}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                {v.map((option) => {
                  return (
                    <Option
                      title={option.size}
                      description={`${option.servings} servings`}
                      onClick={(selected) => props.onChange(selected ? { shape: k, size: option.size, servings: option.servings } : "")}
                      selected={value?.size === option.size && value?.shape === k}
                    />
                  );
                })}
              </Box>
            </Box>
          );
        } else {
          return <Box />;
        }
      })}
    </Box>
  );
}
