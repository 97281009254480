import React from "react";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FeedbackIcon from "@mui/icons-material/Feedback";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChatBubbleTwoToneIcon from "@mui/icons-material/ChatBubbleTwoTone";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LocalAtmTwoToneIcon from "@mui/icons-material/LocalAtmTwoTone";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { Box } from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { STORAGE_BUCKET } from "../constants";
import LocalMallTwoToneIcon from "@mui/icons-material/LocalMallTwoTone";

export default function NotificationIcon(props) {
  const { type, status } = props;

  let icon = <AssignmentIcon />;
  let color = "#999";
  let backgroundColor = "#ECECEC";

  if (type === "Quote") {
    icon = <VisibilityOutlinedIcon />;
  } else if (type.includes("New Order") || type.includes("Manual Order") || type.includes("Order for")) {
    icon = <LocalMallTwoToneIcon />;
    color = "#16a085";
  } else if (type.includes("message")) {
    icon = <ChatBubbleTwoToneIcon />;

    color = "#2980b9";
  } else if (type.includes("Text")) {
    icon = <SmsOutlinedIcon />;
  } else if (type.includes("New Quote Request") || type.includes("New Request")) {
    icon = <LocalAtmTwoToneIcon />;
    color = "#e67e22";
  } else if (type.includes("Invoice")) {
    icon = <ReceiptOutlinedIcon />;
  } else if (type.includes("Payment")) {
    icon = <LocalAtmIcon />;
  } else if (type === "Reopen") {
    icon = <LockOpenIcon />;
  } else if (type === "Review" || type.includes("Review")) {
    icon = <img src={`${STORAGE_BUCKET}/img/icon/goog.png`} style={{ width: "100%" }} />;
  } else if (type === "Feedback") {
    icon = <FeedbackIcon />;
  } else if (type.includes("Order Updated")) {
    icon = <EditOutlinedIcon />;
  } else if (type.includes("Preponed")) {
    icon = <HistoryOutlinedIcon />;
  } else if (type.includes("Cash Advance")) {
    icon = <AttachMoneyIcon />;
    backgroundColor = "#16a085";
    color = "#FFF";
  } else if (type.includes("App Version")) {
    icon = <span>🚀</span>;
  } else if (type.includes("Valentine")) {
    icon = <span>❤️</span>;
  }

  if (status === "closed") {
    backgroundColor = "#DDD";
    color = "#999";
  }

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "40px",
        width: "40px",
        fontSize: "24px",
        backgroundColor,
        color
      }}
    >
      {icon}
    </Box>
  );
}
