import React, { useState } from "react";
import { Box, Chip, Link, Typography } from "@mui/material";
import currency from "currency.js";
import LineItemDetails from "./LineItemDetails";
import ImageCarousel from "../ImageCarousel";
import StandardItem from "../../../product/component/StandardItem";
import CustomItem from "../../../product/component/CustomItem";

export default function LineItemV3(props) {
  const { item } = props;

  const [showMore, setShowMore] = useState(Boolean(props.showMore));

  return (
    <Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Chip size="small" label={item.quantity} />
        <Box sx={{ width: "40px", height: "40px" }}>
          <ImageCarousel images={item.images} />
        </Box>
        <Box sx={{ flexGrow: "1" }}>
          <Typography sx={{ fontSize: "14px" }}>{item.title}</Typography>
          {props.view === "min" ? (
            <Box>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Link underline="none" sx={{ cursor: "pointer", fontSize: "12px" }} onClick={() => setShowMore(!showMore)}>
                  {showMore ? "HIDE" : "VIEW"}
                </Link>
                {item.productId ? (
                  <StandardItem item={item} onChange={props.onChange} />
                ) : (
                  <CustomItem item={item} onChange={props.onChange} />
                )}
              </Box>
            </Box>
          ) : (
            <LineItemDetails item={item} />
          )}
        </Box>
        <Box>
          <Typography variant="overline">{currency(item.total || item.price).format()}</Typography>
        </Box>
      </Box>
      {showMore && (
        <Box sx={{ borderLeft: "2px solid #ECECEC", paddingLeft: "1rem" }}>
          <LineItemDetails item={item} />
        </Box>
      )}
    </Box>
  );
}
