import React, { useState } from "react";
import { Alert, Box, Button, Dialog, DialogContent, IconButton, Container, TextField, InputAdornment, Typography } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { auth } from "../firebase";
import bkstApi from "../api";
import CloseIcon from "@mui/icons-material/Close";

export default function SwitchUser(props) {
  const [code, setCode] = useState("");
  const [reveal, setReveal] = React.useState(false);

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const login = (code) => {
    bkstApi
      .post(`/login-with-code?code=${code}`)
      .then((res) => {
        auth.signInWithCustomToken(res.data.token).then((userCredential) => {
          // Signed in
          var user = userCredential.user;
          console.log(user);
          setOpen(false);
          setError("");
          setCode("");
        });
      })
      .catch((error) => {
        setError("Incorrect Access Code");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getButton = (key) => {
    return (
      <Button
        variant="contained"
        fullWidth
        color="secondary"
        onClick={(e) => {
          if (key === "⌫") {
            setCode(code.slice(0, -1));
          } else if (key === "↵") {
            login(code);
          } else {
            setCode(`${code}${key}`);
          }
        }}
        sx={{ height: "4rem", fontWeight: "700", fontSize: "32px" }}
      >
        {key}
      </Button>
    );
  };

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <SwapHorizIcon />
      </IconButton>
      <Dialog fullScreen={true} open={open} scroll="paper" onClose={handleClose}>
        <DialogContent sx={{ backgroundColor: "#555", padding: "0" }}>
          <Box textAlign={"right"}>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Container maxWidth="xs">
            <Box>
              {error && (
                <Box my={2}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
              <Box my={6} textAlign={"center"}>
                <Typography variant="h4" sx={{ color: "#CCC" }}>
                  Enter your Access Code
                </Typography>
              </Box>
              <Box>
                <Box my={2} sx={{ display: "flex", gap: "2rem", justifyContent: "space-between" }}>
                  {[1, 2, 3].map((num) => getButton(num))}
                </Box>
                <Box my={2} sx={{ display: "flex", gap: "2rem", justifyContent: "space-between" }}>
                  {[4, 5, 6].map((num) => getButton(num))}
                </Box>
                <Box my={2} sx={{ display: "flex", gap: "2rem", justifyContent: "space-between" }}>
                  {[7, 8, 9].map((num) => getButton(num))}
                </Box>
                <Box my={2} sx={{ display: "flex", gap: "2rem", justifyContent: "space-between" }}>
                  {["⌫", 0, "↵"].map((num) => getButton(num))}
                </Box>
                <TextField
                  disabled={true}
                  value={code}
                  color="secondary"
                  name="code"
                  variant="outlined"
                  fullWidth
                  id="code"
                  label=""
                  type={reveal ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={() => setReveal(!reveal)} edge="end">
                          {reveal ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  sx={{
                    backgroundColor: "#777",
                    borderRadius: "5px"
                  }}
                />
              </Box>
            </Box>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
}
