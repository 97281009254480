import React, { useEffect, useState, useRef } from "react";
import { Autocomplete, CircularProgress, InputAdornment, TextField } from "@mui/material";
import bkstApi from "../../api";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

export default function LookupTags(props) {
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    useEffect(() => {
      // Cleanup the previous timeout on re-render
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    const debouncedCallback = (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };

    return debouncedCallback;
  };

  const handleSearch = useDebounce((query) => {
    callApi(query);
  }, 300);

  const callApi = () => {
    bkstApi(`/my/lookup/tag?q=${q}`).then((res) => {
      const results = res.data;
      setOptions(results);
      setLoading(false);
    });
  };

  useEffect(() => {
    //if (q.length > 1) {
    setLoading(true);
    handleSearch(q);
    //} else {
    //  setOptions([]);
    //}
  }, [q]);

  return (
    <Autocomplete
      id="lookup"
      onChange={(e, v) => props.onChange(v?.tag || "")}
      noOptionsText={q.length > 0 ? "No matching tags found" : loading ? "Loading Suggestions" : "Start typing to lookup tags..."}
      style={{ flexGrow: "1" }}
      getOptionLabel={(option) => option.tag}
      options={options}
      blurOnSelect={true}
      forcePopupIcon={false}
      renderInput={(params) => (
        <TextField
          {...params}
          value={q}
          variant="outlined"
          placeholder="Filter by Tag"
          onChange={(e) => setQ(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <FilterListOutlinedIcon style={{ color: "#888" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      )}
    />
  );
}
