import React, { useState, useEffect, useRef } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Link,
  Paper,
  IconButton,
  Tab,
  Tabs,
  Typography,
  TextField
} from "@mui/material";
import { capitalize, getItemBasePrice, getItemPrice } from "../../common/util";
import { STORAGE_BUCKET, IMG_PLACEHOLDER } from "../../common/constants";
import currency from "currency.js";
import ProductVariantSelectionV3 from "./ProductVariantSelectionV3";
import Quantity from "../../common/component/Quantity";
import ImageCarousel from "../../common/component/ImageCarousel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import bkstApi from "../../api";
import VariantSelectionStatusBar from "./VariantSelectionStatusBar";

export default function StandardItem(props) {
  const errorRef = useRef(null);

  const [product, setProduct] = useState(props.product || "");

  const [item, setItem] = useState(props.item || "");
  const [errors, setErrors] = useState([]);

  const [tab, setTab] = useState("variant");
  const [open, setOpen] = useState(false);

  const loadProduct = (productId) => {
    bkstApi(`/my/product/${productId}`).then((res) => {
      setProduct(res.data);
    });
  };

  useEffect(() => {
    if (item.variant) {
      const variantPrice = getItemPrice(item.variant, getItemBasePrice(item.variant));
      const total = currency(variantPrice).multiply(item.quantity).value;
      setItem({ ...item, price: variantPrice, total });
    }
  }, [item.variant, item.quantity]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const arr = validate(item);
    setErrors(arr);
    if (arr.length > 0) {
      errorRef.current?.scrollIntoView({ behavior: "smooth" });
      return;
    } else {
      props.onChange(item);
      handleClose();
    }
  };

  const validate = (item) => {
    let arr = [];
    if (!item.type?.trim()) {
      arr.push("Item Type is required");
    }
    if (item.type === "Other" && !item.title?.trim()) {
      arr.push("Title is required");
    }

    Object.entries(product.options).map(([key, value]) => {
      if (key === "add-on") {
      } else if (key === "filling") {
        if (!item.variant["flavor"]?.filling) {
          arr.push(`${capitalize(key)} is required`);
        }
      } else if (!item.variant[key]) {
        arr.push(`${capitalize(key)} is required`);
      }
    });

    if (!(+item.price > 0)) {
      arr.push("Variant is required");
    }
    if (!(+item.quantity > 0)) {
      arr.push("Quantity is required");
    }

    return arr;
  };

  const handleDelete = () => {
    props.onChange("", "delete");
    handleClose();
  };

  const onBackButtonClick = () => {
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setErrors([]);
    setItem("");
  };

  return (
    <>
      {props.item ? (
        <Link
          underline="none"
          sx={{ cursor: "pointer", fontSize: "12px" }}
          onClick={() => {
            loadProduct(props.item.productId);
            setItem(props.item);
            setOpen(true);
          }}
        >
          EDIT
        </Link>
      ) : (
        <Link
          color="inherit"
          underline="never"
          sx={{ cursor: "pointer", textAlign: "center" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setProduct(props.product);
            setItem({
              productId: product.id,
              images: [product.images?.[0]],
              title: product.title,
              type: product.type,
              variant: product.options?.size?.length === 1 ? { size: product.options?.size?.[0] } : "",
              price: "",
              quantity: 1,
              includeFees: true
            });
            setOpen(true);
          }}
        >
          <Box sx={{ border: "1px solid #ECECEC", backgroundColor: "#FFF", borderRadius: "4px", width: "120px" }}>
            <img src={product.images[0] || `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`} style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }} />
            <Box>
              <Typography
                color="textSecondary"
                sx={{
                  fontSize: "12px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                  paddingBottom: "4px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis"
                }}
              >
                {product.title}
              </Typography>
            </Box>
          </Box>
        </Link>
      )}
      <Dialog fullScreen={true} open={open} scroll="paper" onClose={handleClose}>
        <form
          onSubmit={handleSubmit}
          style={{
            display: "contents"
          }}
        >
          <DialogTitle
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0",
              backgroundColor: "#DEDEDE",
              borderBottom: "3px solid #DEDEDE",
              paddingBottom: "10px"
            }}
          >
            <IconButton onClick={onBackButtonClick}>
              <ArrowBackIcon />
            </IconButton>
            <Box>
              <Typography variant="h5">{item.title}</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center", justifyContent: "right", flexGrow: "1" }}>
              <Quantity size="small" label="QTY" width="130px" value={item.quantity} onChange={(val) => setItem({ ...item, quantity: val })} />
              <Button type="submit" color="primary" variant="contained" sx={{ width: "130px", height: "56px", whiteSpace: "nowrap" }}>
                {currency(item.total).format()} - Save
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ backgroundColor: "#F7F7F7", padding: "0" }}>
            <Box ref={errorRef}>
              {errors.length > 0 && (
                <Alert sx={{ justifyContent: "center" }} severity="error">
                  {errors.join(". ")}
                </Alert>
              )}
            </Box>
            <Box px={2} py={4}>
              {item && (
                <Box>
                  <Grid container>
                    <Grid item sm={4}></Grid>
                    <Grid item sm={8}>
                      <Tabs value={tab} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                        <Tab value="variant" sx={{ fontSize: ".75rem" }} label={"Variant"} onClick={() => setTab("variant")} />
                        <Tab value="notes" sx={{ fontSize: ".75rem" }} label={"Notes"} onClick={() => setTab("notes")} />
                      </Tabs>
                    </Grid>
                    <Grid item sm={4}>
                      <Box pr={4} sx={{ position: "-webkit-sticky", position: "sticky", top: "0" }}>
                        <ImageCarousel images={item.images} />
                      </Box>
                    </Grid>
                    <Grid item sm={8}>
                      {tab === "variant" && (
                        <Paper elevation={0} sx={{ minHeight: "100%" }}>
                          {product && (
                            <VariantSelectionStatusBar
                              variant={item.variant || ""}
                              options={product.options}
                              onChange={(variant) => {
                                setItem({ ...item, variant });
                              }}
                            />
                          )}
                          <Box p={2} py={4}>
                            {product && (
                              <ProductVariantSelectionV3
                                variant={item.variant || ""}
                                options={product.options}
                                onChange={(variant) => {
                                  setItem({ ...item, variant });
                                }}
                              />
                            )}
                          </Box>
                        </Paper>
                      )}
                      {tab === "notes" && (
                        <Paper elevation={0} sx={{ minHeight: "100%" }}>
                          <Box p={2} pt={4}>
                            <TextField
                              id="description"
                              value={item.description}
                              label="Notes (optional)"
                              name="description"
                              variant="outlined"
                              fullWidth
                              multiline
                              rows={5}
                              onChange={(e) => setItem({ ...item, description: e.target.value })}
                            />
                          </Box>
                        </Paper>
                      )}
                    </Grid>
                    <Grid item sm={4}></Grid>
                    <Grid item sm={8}>
                      {props.item && (
                        <Box my={4}>
                          <Button color="error" onClick={handleDelete} fullWidth>
                            Delete This Item
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </DialogContent>
        </form>
      </Dialog>
    </>
  );
}
