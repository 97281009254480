import React, { useContext } from "react";
import { Box, Grid, Link, Paper, Typography } from "@mui/material";
import moment from "moment";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { STORAGE_BUCKET, IMG_PLACEHOLDER } from "../../common/constants";
import { getCustomerName } from "../../common/util";
import { UserContext } from "../../context/UserContext";
import { Link as RouterLink } from "react-router-dom";
import OrderProgress from "./OrderProgress";

export default function KitchenOrderSummary(props) {
  const { order } = props;
  const { user } = useContext(UserContext);

  let image = `${STORAGE_BUCKET}${IMG_PLACEHOLDER}`;
  if (order.items && order.items.length > 0) {
    for (const item of order.items) {
      if (item.images && item.images.length > 0) {
        image = item.images[0];
        break;
      }
    }
  } else if (order.details && order.details.pics) {
    image = order.details.pics[0];
  }

  return (
    <Box my={2}>
      <Paper elevation={0}>
        <Link to={`/order/${order.id}`} component={RouterLink} underline="none" color="inherit">
          <Box px={2} pt={2}>
            <Grid container spacing={2}>
              <Grid item sm={3} xs={4} sx={{}}>
                <img src={image} style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }} />
              </Grid>
              <Grid item sm={9} xs={8}>
                <Typography variant="h6" gutterBottom sx={{ textTransform: "capitalize" }}>
                  {getCustomerName(order.customer)?.toLowerCase()}
                </Typography>
                <Box style={{ color: "#888" }}>
                  <Box my={0.75}>
                    <Typography variant="overline" style={{ display: "flex", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}>
                      <EventOutlinedIcon style={{ fontSize: "1.2rem" }} />
                      {moment(order.fulfillment.date).format("ddd, MMM D")}
                      {` ● `}
                      {order.fulfillment.time}
                    </Typography>
                  </Box>
                  {order.fulfillment.type === "delivery" && (
                    <Box my={0.75}>
                      <Typography
                        variant="overline"
                        style={{ display: "flex", alignItems: "center", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}
                      >
                        <LocalShippingOutlinedIcon style={{ fontSize: "1.2rem" }} />
                        {order.fulfillment.type}
                      </Typography>
                    </Box>
                  )}
                  {order.fulfillment.type === "pickup" && (
                    <Box my={0.75}>
                      <Typography variant="overline" style={{ display: "flex", fontSize: ".8rem", textTransform: "uppercase", gap: "5px" }}>
                        <LocalMallOutlinedIcon style={{ fontSize: "1.2rem" }} />
                        {order.fulfillment.type}
                        {` ● `}
                        {order.fulfillment.location ? order.fulfillment.location.street : order.fulfillment.address.split(",")[0]}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Link>
        <Box px={2}>
          <OrderProgress progress={order.progress} orderId={order.id} />
        </Box>
      </Paper>
    </Box>
  );
}
