import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link as RouterLink } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { ListItemIcon } from "@mui/material";

export default function CreateMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="create-button"
        startIcon={<AddIcon />}
        aria-controls={open ? "create-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        fullWidth
      >
        Create
      </Button>
      <Menu
        id="create-menu"
        aria-labelledby="create-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        sx={{
          "& .MuiMenu-list": {
            minWidth: "240px",
            paddingBottom: "15px"
          }
        }}
      >
        <MenuItem onClick={handleClose} component={RouterLink} to={`/invoice`}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          Create Invoice
        </MenuItem>
        <MenuItem onClick={handleClose} component={RouterLink} to={`/create`}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          Create Order
        </MenuItem>
      </Menu>
    </div>
  );
}
