import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import FilterChip from "../../common/component/FilterChip";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { capitalize } from "../../common/util";

export default function ItemFilter(props) {
  const { config } = props;

  const [filter, setFilter] = useState("");

  useEffect(() => {
    props.onChange(filter);
  }, [filter]);

  const cleanupFilterOptions = (arr) => {
    let set = new Set();
    if (arr) {
      for (const a of arr) {
        // standardize values
        if (a) {
          const aMod = a.trim().toLowerCase();
          if (aMod) {
            set.add(aMod);
          }
        }
      }
    }

    return [...set];
  };

  if (config) {
    return (
      <Box sx={{ display: "flex", gap: "4px", alignItems: "center", padding: "7px", backgroundColor: "#FFF", flexWrap: "wrap", borderRadius: "4px" }}>
        <FilterListOutlinedIcon sx={{ color: "#CCC" }} />
        {config.map((o, idx) => {
          // cleanup values
          const values = cleanupFilterOptions(o?.options);

          // don't show filter if no values
          if (values.length > 0) {
            return (
              <FilterChip
                key={idx}
                label={capitalize(o.name)}
                values={values.sort()}
                onChange={(value) => {
                  setFilter({ ...filter, [o.name]: { value } });
                }}
              />
            );
          } else {
            return <></>;
          }
        })}
      </Box>
    );
  } else {
    return <></>;
  }
}
