import React, { useContext, useState, useEffect } from "react";
import { Box, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Link, LinearProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import bkstApi from "../../api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LookupTags from "../search/LookupTags";
import { UserContext } from "../../context/UserContext";
import CloudinaryUploadWidget from "../component/CloudinaryUploadWidget";

export default function AttachPhotos(props) {
  const { user } = useContext(UserContext);

  const [tag, setTag] = useState("");
  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);

  const getPhotos = (tag) => {
    setLoading(true);
    bkstApi(`/my/photo?tag=${tag || ""}`).then((res) => {
      setProducts(res.data.products);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (open) {
      getPhotos();
    }
  }, [open]);

  const handleClick = (id) => {
    setProducts(products.map((p) => (p.id === id ? { ...p, selected: !p.selected } : p)));
  };

  const handleSubmit = () => {
    props.onChange(products.filter((o) => o.selected).map((o) => o.img));
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setTag("");
    setProducts([]);
  };

  return (
    <>
      <span onClick={() => setOpen(true)}>{props.children}</span>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <DialogTitle>
          Select or Upload Photos
          <IconButton
            color="inherit"
            size="small"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon sx={{ color: "#999" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {loading && <LinearProgress />}
          <Box my={2}>
            <LookupTags
              onChange={(str) => {
                getPhotos(str);
              }}
            />
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <CloudinaryUploadWidget
                folder={`s/${user.acctId}/${props.folder}`}
                onComplete={(images) => {
                  props.onChange(images);
                  setOpen(false);
                }}
              >
                <Button sx={{ height: "100%", width: "100%" }} variant="contained" color="secondary">
                  Upload Photo
                </Button>
              </CloudinaryUploadWidget>
            </Grid>
            {products &&
              products.map((value, index) => {
                return (
                  <Grid item xs={6} sm={4} md={3} lg={2} key={index} align="center">
                    <Box>
                      <Link onClick={(e) => handleClick(value.id)}>
                        <Box sx={{ position: "relative" }}>
                          <img
                            src={value.img}
                            style={{ width: "100%", aspectRatio: "1", objectFit: "cover", border: value.selected ? "10px solid #DEDEDE" : 0 }}
                          />

                          {value.selected && (
                            <Box>
                              <CheckCircleIcon sx={{ position: "absolute", right: -5, top: -5 }} />
                            </Box>
                          )}
                          <Box></Box>
                        </Box>
                      </Link>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="contained" color="primary" onClick={handleSubmit} disabled={products.filter((o) => o.selected).length === 0}>
            ATTACH
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
