import React, { useEffect, useState, useContext } from "react";
import { Alert, Backdrop, Box, Button, Container, Grid, CircularProgress, Typography, AlertTitle } from "@mui/material";
import bkstApi from "../../api";
import { UserContext } from "../../context/UserContext";
import GalleryItem from "../components/GalleryItem";
import CloudinaryUploadWidget from "../../common/component/CloudinaryUploadWidget";
import LookupTags from "../../common/search/LookupTags";

export default function Gallery(props) {
  const { user } = useContext(UserContext);

  const [tag, setTag] = useState("");
  const [limit, setLimit] = useState("100");
  const [uploadCount, setUploadCount] = useState(0);

  const [products, setProducts] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    search("", 10);
  }, []);

  const addPhotos = (images) => {
    setLoading(true);
    bkstApi.post(`/my/photos`, { images }).then((res) => {
      setUploadCount(images.length);
      setLoading(false);
      //search("", 100);
    });
  };

  const search = (tag, limit) => {
    setLoading(true);
    setProducts([]);
    setUploadCount(0);

    bkstApi(`/my/photo?tag=${tag}&limit=${limit}`).then((res) => {
      setProducts(res.data.products);
      setLimit(res.data.limit);

      setLoading(false);
    });
  };

  return (
    <Container maxWidth="md">
      <Box my={6}>
        <Box mb={4}>
          <Box mb={2} sx={{ display: "flex", gap: "10px", justifyContent: "space-between" }}>
            <Box textAlign={"left"}>
              <Typography variant="h3">Photo Gallery</Typography>
            </Box>
            <Box>
              <CloudinaryUploadWidget folder={`s/${user.acctId}/gallery`} onComplete={(images) => addPhotos(images)}>
                <Button variant="contained" color="primary">
                  Upload
                </Button>
              </CloudinaryUploadWidget>
            </Box>
          </Box>
          <Typography color="textSecondary" variant="body1">
            These photos will show up in the Photo Gallery on your website. They will also show up when customers search for custom designs on your
            website.
          </Typography>
        </Box>
        <Box my={2}>
          <LookupTags onChange={(str) => search(str, 100)} />
        </Box>
        {+uploadCount > 0 && (
          <Box my={2} sx={{ backgroundColor: "" }}>
            <Alert
              severity="info"
              action={
                <Button size="small" onClick={() => search("", 100)}>
                  View Photos
                </Button>
              }
            >
              {uploadCount} new photo(s) uploaded
            </Alert>
          </Box>
        )}
        <Grid container spacing={3}>
          {products?.map((value, index) => {
            return (
              <Grid item xs={12} sm={4} key={index} align="center">
                <GalleryItem product={value} />
              </Grid>
            );
          })}
          {products.length > +limit && (
            <Button fullWidth variant="contained" color="primary" onClick={() => search(tag, +limit * 2)}>
              Show More...
            </Button>
          )}
        </Grid>
      </Box>
      {loading && (
        <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </Container>
  );
}
