import React, { useState, useEffect } from "react";
import {
  Alert,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import PricingVariant from "../component/PricingVariant";
import Images from "../../request/components/Images";
import ProductTypeAutocomplete from "../../common/component/ProductTypeAutocomplete";
import ProductRules from "../component/ProductRules";
import { ExpandMoreOutlined } from "@mui/icons-material";
import QuillRTE from "../../common/component/QuillRTE";
import moment from "moment";
import ProductOptions from "../component/ProductOptions";
import { capitalize } from "../../common/util";
import FeatureAccess from "../../baker/components/FeatureAccess";
import AddOption from "../component/AddOption";

export default function ProductEntry(props) {
  const { siteId, productId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [product, setProduct] = useState("");

  const [type, setType] = useState("");
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [options, setOptions] = useState("");
  const [customization, setCustomization] = useState("");
  const [rules, setRules] = useState("");
  const [status, setStatus] = useState("ACTIVE");

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (productId) {
      setLoading(true);
      bkstApi(`/my/product/${productId}`).then((res) => {
        setProduct(res.data);

        setType(res.data.type);
        setImages(res.data.images);
        setTitle(res.data.title);
        setDescription(res.data.description);

        setOptions(res.data.options);
        setCustomization(res.data.customization);

        setRules(res.data.rules);
        setStatus(res.data.status);

        setLoading(false);
      });
    } else {
      console.log("-->", location.state);
      if (location.state) {
        const p = location.state.product;
        setProduct(p);

        setType(p.type);
        setImages(p.images);
        setTitle(p.title);
        setDescription(p.description);

        setOptions(p.options);
        setCustomization(p.customization);

        setRules(p.rules);
        setStatus(p.status);
      }
      setLoading(false);
    }
  }, [productId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // validate
    let arr = [];
    if (status === "ACTIVE") {
      if (options) {
        for (const [k, v] of Object.entries(options)) {
          if (Array.isArray(v) && v.length === 0) {
            arr.push(`${capitalize(k)} is missing options`);
          }
        }
      }

      if (rules?.date?.max) {
        if (moment().isAfter(moment(rules?.date?.max), "day")) {
          arr.push("Check Advanced Options. Max date can't be in the past");
        }

        if (rules?.date?.min && moment(rules.date.min).isAfter(moment(rules?.date?.max), "day")) {
          arr.push("Check Advanced Options. Min date can't be after Max date");
        }
      }
    }

    setErrors(arr);
    if (arr.length > 0) {
      return;
    }

    const payload = {
      type,
      images,
      title,
      description,
      options,
      //tags,
      rules,
      customization,
      status
    };

    if (productId) {
      bkstApi.put(`/my/product/${productId}`, payload).then((res) => {
        navigate("/shop");
      });
    } else {
      bkstApi.post(`/my/product`, payload).then((res) => {
        navigate("/shop");
      });
    }
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Container maxWidth="sm">
        <FeatureAccess plan={["professional", "premium"]} />
        <Box my={6}>
          <Box mb={6} align="center">
            <Typography variant="h3" gutterBottom>
              Online Shop Item
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Be sure to include high-quality photos, item description, size & servings details to ensure customers feel confident ordering online
              from you.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <Paper elevation={0}>
              <Box px={2} pt={4} pb={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Images edit={true} showArrows={true} pics={images} onChange={(arr) => setImages(arr)} folder={`img/site/${siteId}/tmp`} />
                      </Grid>
                      <Grid item xs={8}>
                        <ProductTypeAutocomplete value={type} label="Product Type" onChange={(value) => setType(value)} required />
                        <Box mt={3}>
                          <TextField
                            id="title"
                            value={title}
                            label="Title"
                            name="title"
                            variant="outlined"
                            fullWidth
                            required
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <QuillRTE value={description} onChange={(html) => setDescription(html)} />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Box my={4}>
              <PricingVariant productType={type} value={options?.size || []} onChange={(data) => setOptions({ ...options, size: data })} />
            </Box>
            <Box my={4}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Typography variant="h6">Options</Typography>
                  </Grid>
                  <Grid item xs={3} align="right">
                    <AddOption
                      options={["flavor", "filling", "icing"]}
                      value={Object.keys(options)}
                      onChange={(opt) => {
                        let o = { ...options };
                        if (opt.state) {
                          o[opt.key] = product?.options?.[opt.key] || [];
                        } else {
                          delete o[opt.key];
                        }
                        setOptions(o);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Paper elevation={0}>
                <Box px={2} pt={3} pb={2}>
                  {Object.keys(options).length === 0 && (
                    <Box px={2} py={4} textAlign="center">
                      <Typography variant="subtitle1">Add Options</Typography>
                      <Typography variant="body1" color="textSecondary">
                        Click + to add options like flavor, filling, etc
                      </Typography>
                    </Box>
                  )}
                  <Grid container spacing={2}>
                    {Object.entries(options).map(([key, value]) => {
                      if (key === "size" || key === "add-on") {
                        return <></>;
                      } else {
                        return (
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={Boolean(options?.[key])}
                                  onChange={(e) => {
                                    let o = { ...options };
                                    if (e.target.checked) {
                                      o[key] = product?.options?.[key] || [];
                                    } else {
                                      delete o[key];
                                    }
                                    setOptions(o);
                                  }}
                                />
                              }
                              label={`Allow customer to select ${capitalize(key)}`}
                            />
                            {options?.[key] && (
                              <Box my={2} sx={{ paddingLeft: "3rem" }}>
                                <Paper variant="outlined">
                                  <ProductOptions
                                    type={capitalize(key)}
                                    value={options?.[key]}
                                    onChange={(data) => setOptions({ ...options, [key]: data })}
                                  />
                                </Paper>
                              </Box>
                            )}
                          </Grid>
                        );
                      }
                    })}
                  </Grid>
                </Box>
              </Paper>
            </Box>
            <Box my={4}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Typography variant="h6">Customization</Typography>
                  </Grid>
                  <Grid item xs={3} align="right"></Grid>
                </Grid>
              </Box>
              <Paper elevation={0}>
                <Box px={2} pt={3} pb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(customization?.inscription)}
                            onChange={(e) => {
                              let o = { ...customization };
                              if (e.target.checked) {
                                o.inscription = { text: "" };
                              } else {
                                delete o.inscription;
                              }
                              setCustomization(o);
                            }}
                          />
                        }
                        label="Inscription - allow customer to add an inscription"
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(customization?.photo)}
                            onChange={(e) => {
                              let o = { ...customization };
                              if (e.target.checked) {
                                o.photo = { limit: 5 };
                              } else {
                                delete o.photo;
                              }
                              setCustomization(o);
                            }}
                          />
                        }
                        label="Photo - allow customer to upload photos"
                      />
                    </Grid>*/}
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={Boolean(customization?.notes)}
                            onChange={(e) => {
                              let o = { ...customization };
                              if (e.target.checked) {
                                o.notes = { text: "" };
                              } else {
                                delete o.notes;
                              }
                              setCustomization(o);
                            }}
                          />
                        }
                        label="Special Request - allow customer to add a note"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
            <Box my={4}>
              <Box>
                <Grid container alignItems="center">
                  <Grid item xs={9}>
                    <Typography variant="h6">Availability</Typography>
                  </Grid>
                  <Grid item xs={3} align="right"></Grid>
                </Grid>
              </Box>
              <Paper elevation={0}>
                <Box px={2} pt={3} pb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Switch checked={status === "ACTIVE"} onChange={(e) => setStatus(e.target.checked ? "ACTIVE" : "DRAFT")} />}
                        label="Show this item on my online store"
                      />
                    </Grid>
                  </Grid>
                  {status === "ACTIVE" && (
                    <Box mt={3}>
                      <Divider />
                      <Accordion elevation={0}>
                        <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                          <Box>
                            <Typography variant="h6">Advanced Options</Typography>
                            <Typography variant="body2" color="textSecondary">
                              Limit availability by date range & lead time
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails sx={{ marginLeft: "15px", borderLeft: "3px solid #EEE" }}>
                          <ProductRules value={rules} onChange={(data) => setRules(data)} />
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )}
                </Box>
              </Paper>
            </Box>
            <Box my={4}>
              {errors?.length > 0 && (
                <Box my={2}>
                  <Alert severity="error">{errors.join(". ")}</Alert>
                </Box>
              )}
              <Button type="submit" variant="contained" color="primary" fullWidth disabled={!options.size || options.size.length === 0}>
                Save Item
              </Button>
              <Box my={2} textAlign={"center"}>
                <Button variant="text" color="secondary" onClick={() => navigate(-1)}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Container>
    );
  }
}
