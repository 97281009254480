import React, { useContext, useState } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography
} from "@mui/material";
import bkstApi from "../../api";
import BDatePicker from "../../common/input/BDatePicker";
import { useNavigate } from "react-router";
import StripePaymentElement from "../../common/component/StripePaymentElement";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { isEmailValid } from "../../common/util";
import QuillRTE from "../../common/component/QuillRTE";
import FeatureAccess from "../../baker/components/FeatureAccess";
import CustomerDetailEntry from "../../customer/component/CustomerDetailEntry";
import Price from "../../common/component/Price";
import { UserContext } from "../../context/UserContext";

const key = process.env.REACT_APP_STRIPE_KEY;
const promise = loadStripe(key);

export default function MotoOrder(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [customer, setCustomer] = useState("");

  const [shipping, setShipping] = useState("pickup");
  const [date, setDate] = useState("");

  const [amount, setAmount] = useState("");
  const [includeFees, setIncludeFees] = useState(user.config?.transferFees === "false" ? false : true);
  const [total, setTotal] = useState("");

  const [showMore, setShowMore] = useState(false);
  const [title, setTitle] = useState("Custom Order");
  const [description, setDescription] = useState("");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let arr = [];
    if (!amount) {
      arr.push("Amount is missing");
    }
    if (!date) {
      arr.push("Date is missing");
    }
    if (!customer.firstName) {
      arr.push("First Name is missing");
    }
    if (!customer.phone) {
      arr.push("Phone is missing");
    }
    if (customer.email && isEmailValid(customer.email)) {
      arr.push("Email is incorrect");
    }

    return arr;
  };

  const chargePaymentMethod = (paymentMethodId) => {
    setLoading(true);

    const payload = {
      amount,
      total,
      date,
      shipping,
      customer,
      title,
      description,
      paymentMethodId
    };

    bkstApi
      .put(`/my/order/quick-charge`, payload)
      .then((res) => {
        navigate(`/order/${res.data.orderId}`);
      })
      .catch((e) => {
        setError(`Payment Failed: ${e.response?.data?.message}`);
        setLoading(false);
      });
  };

  return (
    <Container maxWidth="sm" disableGutters>
      <FeatureAccess plan={["starter", "professional", "premium"]} />
      <Box>
        {!props.hideHeader && (
          <Box mt={6} mb={4} align="center">
            <Typography variant="h3" gutterBottom>
              Virtual Terminal
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Virtual Terminal turns your device into a credit card terminal so you can collect payment over phone or in-person. Simply enter the
              customer's card details below to confirm their order.
            </Typography>
          </Box>
        )}
        <Paper elevation={0}>
          <Box pt={4} px={2} pb={1}>
            <Price
              value={{ amount, includeFees }}
              onChange={(value) => {
                setAmount(value.amount);
                setIncludeFees(value.includeFees);
                setTotal(value.total);
              }}
            />
            <Box py={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" fullWidth>
                    <BDatePicker value={date} label="Date" onChange={(d) => setDate(d)} disablePast required autoOk />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" required fullWidth>
                    <InputLabel id="shipping-label">Type</InputLabel>
                    <Select
                      value={shipping}
                      labelId="shipping-label"
                      label="Type"
                      id="shipping"
                      name="shipping"
                      onChange={(e) => setShipping(e.target.value)}
                    >
                      <MenuItem value="pickup">Pickup</MenuItem>
                      <MenuItem value="delivery">Delivery</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Box my={2}>
              <CustomerDetailEntry
                customer={customer}
                onChange={(o) => {
                  setCustomer(o);
                }}
              />
            </Box>
            {!showMore && (
              <Box mt={2} align="center">
                <Button color="secondary" size="small" variant="text" fullWidth onClick={() => setShowMore(true)}>
                  Add Note
                </Button>
              </Box>
            )}
            {showMore && (
              <Box my={2}>
                <QuillRTE value={description} placeholder="Add notes.." onChange={(html) => setDescription(html)} />
              </Box>
            )}
          </Box>
        </Paper>
        {error && (
          <Box my={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Box my={4}>
          <Elements stripe={promise}>
            <StripePaymentElement
              disabled={loading}
              validate={validate}
              chargePaymentMethod={chargePaymentMethod}
              amount={total}
              name={`${customer.firstName} ${customer.lastName}`} 
              phone={customer.phone }
            />
          </Elements>
        </Box>
      </Box>
      <Backdrop open={loading} style={{ zIndex: "1000", color: "#fff" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
