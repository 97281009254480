import React from "react";
import { Box, Chip, LinearProgress, Typography } from "@mui/material";
import { capitalize } from "../../common/util";

export default function VariantSelectionStatusBar(props) {
  const { options, variant } = props;

  const getFilterChip = (key, displayValue, onDelete) => {
    return (
      <Chip
        label={
          <Box>
            {capitalize(key)} <span style={{ color: "#999" }}>|</span>{" "}
            <span style={{ color: "#78374a", textTransform: "capitalize" }}>{displayValue}</span>
          </Box>
        }
        onDelete={onDelete}
      ></Chip>
    );
  };

  let selections = [];
  for (const [key, value] of Object.entries(variant)) {
    if (value) {
      if (key === "size") {
        let fn = () => props.onChange({ ...variant, [key]: "" });
        selections.push(getFilterChip(key, value.size, fn));
      } else if (key === "flavor") {
        if (value?.base) {
          selections.push(
            <Chip
              label={
                <Box>
                  Flavor <span style={{ color: "#999" }}>|</span>{" "}
                  <span style={{ color: "#78374a", textTransform: "capitalize" }}>{value?.base?.name}</span>
                </Box>
              }
              onDelete={() => props.onChange({ ...variant, flavor: { ...variant.flavor, base: "" } })}
            />
          );
        }

        if (value.filling) {
          selections.push(
            <Chip
              label={
                <Box>
                  Filling <span style={{ color: "#999" }}>|</span>{" "}
                  <span style={{ color: "#78374a", textTransform: "capitalize" }}>{value?.filling?.name}</span>
                </Box>
              }
              onDelete={() => {
                props.onChange({ ...variant, flavor: { ...variant.flavor, filling: "" } });
              }}
            />
          );
        }
      } else if (key === "add-on") {
        /*const displayString = value.map((o) => o.name).join(", ");
        let v = { ...variant };
        delete v["add-on"];
        const fn = () => props.onChange(v);
        selections.push(getFilterChip(key, displayString, fn));*/
      } else {
        selections.push(getFilterChip(key, value.name, () => props.onChange({ ...variant, [key]: "" })));
      }
    }
  }

  let selectionsRequired = Object.keys(options).filter((o) => o !== "add-on").length;
  const requirementsMet = selections.length >= selectionsRequired;

  let title = "Selections";
  if (selections.length == 0) {
    title += ` (${selectionsRequired} Required)`;
  } else {
    title += ` (${selections.length}/${selectionsRequired})`;
  }

  return (
    <Box>
      <Box sx={{ backgroundColor: requirementsMet ? "#ecf7f5" : "#e5f6fd" }}>
        <Box px={2} pt={1.5} pb={1}>
          <Typography variant="overline" color="textSecondary">
            {title}
          </Typography>
          {selections}
        </Box>
        <LinearProgress
          variant="determinate"
          color={requirementsMet ? "success" : "info"}
          value={requirementsMet ? 100 : (selections.length / selectionsRequired) * 100}
        />
      </Box>
      {requirementsMet && (
        <Box py={4} px={2}>
          <Typography variant="h5" gutterBottom>
            All required fields selected!
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Review the quantity & price on top & click the button to Save your changes. Alternatively, you can enter notes & optional fields before
            saving.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
