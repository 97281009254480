import moment from "moment";
import currency from "currency.js";

export const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  var match = phoneNumber.match(/^(\d{1,3}|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? `+${match[1]} ` : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  } else {
    return value;
  }
};

export function getQueryParam(queryStr, p) {
  const arr = queryStr.replace("?", "").split("&");
  for (const a of arr) {
    if (a.startsWith(`${p}=`)) {
      return decodeURIComponent(a.substring(p.length + 1));
    }
  }
  return undefined;
}

export function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function createSnippets(productType, shape, size, servings) {
  const data = [];
  if (productType === "Cake") {
    if (shape && size) {
      data.push(`${shape}, ${size} cake`);
    }
    if (servings) {
      data.push(`${servings} servings`);
    }
  } else {
    if (productType && servings) {
      data.push(`${servings} ${productType}`);
    }
  }

  return data;
}

export function isV2SaaSPlan(plan) {
  return plan === "starter" || plan === "professional" || plan === "premium";
}

export function getDisplayName(firstName, lastName, name) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  } else if (firstName) {
    return firstName;
  } else {
    return name;
  }
}

export function getDefaultQuoteExpiration(eventDate) {
  if (eventDate) {
    const daysToEvent = moment(eventDate).diff(moment(), "days");
    if (daysToEvent < 3) {
      return moment().add(4, "hours");
    } else if (daysToEvent < 5) {
      return moment().add(1, "days");
    } else if (daysToEvent < 8) {
      return moment().add(2, "days");
    } else if (daysToEvent < 15) {
      return moment().add(3, "days");
    } else if (daysToEvent < 22) {
      return moment().add(4, "days");
    } else if (daysToEvent <= 30) {
      return moment().add(5, "days");
    } else {
      return moment().add(7, "days");
    }
  } else {
    return moment().add(1, "days");
  }
}

export function getDefaultQuoteExtension(eventDate) {
  const daysToEvent = moment(eventDate).diff(moment(), "days");
  console.log("daysToEvent", daysToEvent);

  const exdate = moment();
  if (daysToEvent < 3) {
    exdate.add(2, "hours");
  } else if (daysToEvent < 8) {
    exdate.add(12, "hours");
  } else {
    exdate.add(24, "hours");
  }

  return exdate.format();
}

export function isPWA() {
  return window.navigator.standalone || window.matchMedia("(display-mode: standalone)").matches;
}

export function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

export function generateServingsSnippet(servings) {
  if (servings.length < 7) {
    return servings + " servings";
  } else {
    return servings;
  }
}

export function getAmountWithTxnFee(amount, fees = { net: "0.045" }) {
  const feeFactor = +fees.payment + +fees.success;
  return currency(amount).add(currency(amount).multiply(fees.net)).toString();
}

export function getAmountWithoutTxnFee(amount, fees = { net: "0.045" }) {
  return currency(amount)
    .divide(1 + +fees.net)
    .toString();
}

export function getOriginalAmount(amount, includeFees, fees) {
  return (includeFees ? getAmountWithoutTxnFee(amount, fees) : amount) || "";
}

export function getApplicationFee(amount, fees = { net: "0.045", payment: "0.035", success: "0.01", flat: "0" }) {
  const feeFactor = +fees.payment + +fees.success;
  return currency(amount).multiply(feeFactor).add(fees.flat).toString();
}

export function getDataFromLocalStorage(key) {
  const value = localStorage.getItem(key);

  if (value) {
    // parse to JSON
    return JSON.parse(value);
  } else {
    return "";
  }
}

export function setDataInLocalStorage(key, object) {
  localStorage.setItem(key, JSON.stringify({ ...object, ts: Date.now() }));
}

export function getCachedState(key, maxCacheAge) {
  let cache = "";
  const landing = getDataFromLocalStorage("landing");
  // is this the same page that was refreshed?
  if (landing && landing.page === window.location.pathname) {
    const localData = getDataFromLocalStorage(key);
    cache = localData;

    if (maxCacheAge) {
      const cacheAge = Date.now - +localData.ts;
      if (cacheAge > +maxCacheAge) {
        cache = "";
      }
    }
  }
  return cache;
}

export function getCustomerName(customer) {
  if (customer.firstName) {
    return `${customer.firstName} ${customer.lastName || ""}`;
  } else if (customer.name && customer.lastName) {
    return `${customer.name} ${customer.lastName}`;
  } else {
    return customer.name || "";
  }
}

export function getCustomerFirstNameLastInitial(name) {
  // Create an array containing each of the words in the name
  var names = name.split(/\s+/);

  if (names.length > 1) {
    // Replaces the first name with an initial, followed by a period.
    names[1] = names[1].substr(0, 1) + ".";
  }

  // Glue the pieces back together.
  return names.join(" ");
}

export function getStartTime(str) {
  let hour = 0;
  const time = (str?.trim() || "").toLowerCase();

  const amIdx = time.indexOf("am");
  const noonIdx = time.indexOf("noon");
  const pmIdx = time.indexOf("pm");

  // find first occurence
  const arr = [amIdx, noonIdx, pmIdx].filter((idx) => idx >= 0);
  if (arr.length > 0) {
    // split time string at the first match
    const splitIdx = Math.min(...arr);
    if (noonIdx === splitIdx) {
      hour = 12;
    } else {
      // split string and extract hour
      hour = parseInt(time.substring(0, splitIdx));
      if (pmIdx === splitIdx) {
        if (hour !== 12) {
          hour = hour + 12;
        }
      }
    }
  }

  return hour;
}

export function sortByOrderDateTime(o1, o2) {
  let result = 0;

  try {
    result = o1.fulfillment.date.localeCompare(o2.fulfillment.date);
    if (result === 0) {
      // if date is same, sort by time
      result = getStartTime(o1.fulfillment.time) - getStartTime(o2.fulfillment.time);
    }
  } catch (error) {
    console.log("invalid order date/time");
  }
  return result;
}

export function generateSizeSummary(variant, productType) {
  let summary = "";

  if (productType) {
    summary += `${productType} • `;
  }

  if (variant) {
    if (variant.shape) {
      summary += `${variant.shape} • `;
    }
    if (variant.size) {
      summary += `${variant.size} • `;
    }
    if (variant.servings) {
      summary += `${variant.servings} servings`;
    }
  }

  return summary;
}

export function generateFlavorSummary(arr) {
  let summary = "";
  if (Array.isArray(arr)) {
    for (const o of arr) {
      if (o.name) {
        summary = `Flavor: ${o.name}`;
      }

      if (o.filling) {
        summary += ` • Filling: ${o.filling}`;
      }
    }
  } else if (typeof arr === "object") {
    if (arr.base) {
      summary = `Flavor: ${arr.base.name}`;
    }
    if (arr.filling) {
      summary += ` • Filling: ${arr.filling.name}`;
    }
  }

  return summary;
}

export function getPolicyBasedRefund(order) {
  let cxlFee = 0;

  const advanceBookingDays = moment(order.fulfillment.date).diff(moment(), "days");
  console.log("advanceBookingDays", advanceBookingDays);

  if (advanceBookingDays >= 22) {
    cxlFee = currency(order.payment.total).multiply(0.25);
  } else if (advanceBookingDays >= 8) {
    cxlFee = currency(order.payment.total).multiply(0.5);
  } else {
    cxlFee = currency(order.payment.total).subtract(order.payment.tip).subtract(order.payment.delivery);
  }

  let refundAmount = currency(order.payment.paid).subtract(cxlFee);

  return +refundAmount > 0 ? refundAmount.toString() : 0;
}

export function getSaasWebsiteUrl(domain) {
  if (domain.includes("localhost")) {
    return `http://localhost:3001`;
  } else {
    return `https://app.${domain}`;
  }
}

export function hasManagerAccess(role) {
  return role === "owner" || role === "manager" || role === "baker" || role === "rep";
}

export function hasOwnerAccess(role) {
  return role === "owner" || role === "baker";
}

export function isKitchenStaff(role) {
  return role === "kitchen" || role === "kitchen leader";
}

export function getVariantTotal(variant) {
  let total = 0;
  if (variant) {
    for (const [key, value] of Object.entries(variant)) {
      if (key === "size") {
        continue;
      } else {
        if (value?.pricing) {
          total += +value.pricing.amount;
        } else if (typeof value === "object") {
          total += getVariantTotal(value);
        }
      }
    }
  }
  return +total;
}

export function getItemBasePrice(variant) {
  for (const [key, value] of Object.entries(variant)) {
    if (key === "size") {
      return +value.pricing?.amount;
    }
  }

  return 0;
}

export function getItemPrice(variant, basePrice) {
  let total = 0;
  for (const [key, value] of Object.entries(variant)) {
    if (value?.pricing) {
      if (value.pricing.amount) {
        total += +value.pricing.amount;
      } else if (value.pricing.factor) {
        total += currency(basePrice).multiply(value.pricing.factor).value;
      }
    } else if (typeof value === "object") {
      total += getItemPrice(value, basePrice);
    }
  }
  return +total;
}

export function getItemMetadata(product) {
  let [minPrice, maxPrice, leadTime] = [0, 0, 0];
  let dietary = new Set();
  if (product.options?.size) {
    for (const variant of product.options.size) {
      const price = +variant.pricing.amount;
      if (minPrice === 0) {
        minPrice = price;
      } else if (price <= minPrice) {
        minPrice = price;
      } else if (price >= maxPrice) {
        maxPrice = price;
      }

      const title = variant.size.toLowerCase();
      if (title.includes("vegan")) {
        dietary.add("VG");
      }

      if (title.includes("gluten") && title.includes("free")) {
        dietary.add("GF");
      }

      leadTime = product.rules?.date?.leadTime || 0;
    }
  }

  return { price: { min: minPrice, max: maxPrice }, dietary: Array.from(dietary), leadTime };
}

export function isTruncated(element) {
  if (element && (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)) {
    return true;
  } else {
    return false;
  }
}

export function normalizeFlavor(value) {
  if (Array.isArray(value)) {
    return value[0];
  } else if (typeof value === "object") {
    return { name: value?.base?.name, filling: value?.filling?.name };
  } else {
    return "";
  }
}

export function isAllowedByFilter(order, filter) {
  if (filter === "standard" && order.type !== "shop") {
    return false;
  } else if (filter === "custom" && order.type === "shop") {
    return false;
  } else {
    return true;
  }
}

export function sortByNumericPrefix(s1, s2) {
  let result = "";

  try {
    let num1 = s1.replace(/(^\d+)(.+$)/i, "$1");
    let num2 = s2.replace(/(^\d+)(.+$)/i, "$1");

    if (num1 && num2) {
      // compare as numbers
      result = num1 - num2;
      if (result === 0) {
        result = s1.localeCompare(s2);
      }
    } else {
      result = s1.localeCompare(s2);
    }

    return result;
  } catch (error) {
    console.log("invalid values");
  }
  return result;
}

export function calculatePaymentSummary(items, deliveryFee, hasDeposit, depositAmount) {
  const subtotal = currency(items.reduce((acc, o) => +acc + +o.total, 0));
  const delivery = currency(deliveryFee || 0);

  const total = subtotal.add(delivery);
  let due = total;

  if (hasDeposit && +depositAmount <= total.value) {
    due = currency(depositAmount);
  } else {
    // pay in full
  }

  return { subtotal: subtotal.value, delivery: delivery.value, total: total.value, due: due.value };
}

export function isEmailValid(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export function getFormattedAddress(location) {
  return `${location.street}, ${location.city}, ${location.state} ${location.zip}`;
}

export function getFormattedAddressAsArray(location, includeUnit) {
  let arr = [];

  if (location) {
    let line1 = location.street;
    if (includeUnit && location.unit) {
      line1 += `, ${location.unit}`;
    }
    arr.push(line1);
    arr.push(`${location.city}, ${location.state} ${location.zip}`);
  }
  return arr;
}

export function equalsIgnoreCase(str1, str2) {
  if (str1 && str2) {
    return str1.trim().toLowerCase() === str2.trim().toLowerCase();
  }
  return false;
}

export function arrayContainsIgnoreCase(arr, str) {
  if (arr && str) {
    return arr.filter((o) => o.trim().toLowerCase() == str.trim().toLowerCase()).length > 0;
  }
  return false;
}

export function isAdmin(uid) {
  return uid === "j2tO1dfIh9ZnUnb40p4lK60gXaI2" || uid === "hNGQFaTpqOYdtnWpoClwsHe49fq2";
}

export function anyWordStartsWith(string, prefix) {
  string = string.toLowerCase();
  prefix = prefix.toLowerCase();

  if (string.startsWith(prefix)) {
    return true;
  }

  let tokens = string.toLowerCase().split(" ");
  for (const token of tokens) {
    if (token.trim().startsWith(prefix)) {
      return true;
    }
  }

  return false;
}
