import React, { useContext } from "react";
import { AlertTitle, AppBar, Box, Button, Hidden, IconButton, Snackbar, Toolbar } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import UserLoggedInButton from "./common/notify/UserLoggedInButton";
import { Alert } from "@mui/material";
import { UserContext } from "./context/UserContext";
import { hasManagerAccess } from "./common/util";
import Notifications from "./common/notify/Notifications";
import Lookup from "./common/search/Lookup";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import SwitchUser from "./common/SwitchUser";

function Header(props) {
  const { user, toast, setToast } = useContext(UserContext);

  // always show hamburgerMenu to Kitchen
  const hamburgerMenu = (
    <IconButton aria-label="open drawer" edge="start" onClick={props.toggleMobileDrawer} size="large">
      <MenuRoundedIcon />
    </IconButton>
  );

  const showNotifications = hasManagerAccess(user.role) || user.role === "kitchen leader";

  return (
    <div>
      {user && (
        <AppBar position="static" style={{ background: "#fff", boxShadow: "none", borderBottom: "1px solid #eee" }}>
          <Toolbar style={{ display: "flex", justifyContent: "space-between", gap: "10px", alignItems: "center" }}>
            <Box>
              {user.role === "clerk" || user.role === "kitchen" || user.role === "kitchen leader" || window.location.pathname === `/pos` ? (
                hamburgerMenu
              ) : (
                <Hidden smUp>{hamburgerMenu}</Hidden>
              )}
            </Box>
            <Box style={{ display: "flex", justifyContent: "flex-end", flexGrow: "1", alignItems: "center", gap: "5px" }}>
              <IconButton
                onClick={() => {
                  window.$zoho.salesiq.visitor.name(`${user.profile?.bizName} ${user.firstName ? `: ${user.firstName} ${user.lastName || ""}` : ""}`);
                  window.$zoho.salesiq.chat.waittime(600);
                  window.$zoho.salesiq.chat.start();
                }}
              >
                <LiveHelpOutlinedIcon />
              </IconButton>
              {user.acctId === "-MX2oaNrh4TcKVvnhcT9" && <SwitchUser />}
              <Lookup />
              {showNotifications && <Notifications />}
              <UserLoggedInButton user={user} />
            </Box>
          </Toolbar>
        </AppBar>
      )}
      {user && user.alert && (
        <Box>
          <Alert
            severity="warning"
            action={
              <Button color="inherit" size="small" onClick={() => (window.location.href = user.alert.link)}>
                {user.alert.cta}
              </Button>
            }
          >
            <AlertTitle>{user.alert.title}</AlertTitle>
            {user.alert.description}
          </Alert>
        </Box>
      )}
      <Snackbar
        open={Boolean(toast)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => {
          setToast("");
        }}
      >
        <Alert
          variant="filled"
          severity={toast.severity}
          onClose={() => {
            setToast("");
          }}
        >
          {toast.msg}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default Header;
