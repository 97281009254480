import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { UserContext } from "../../../context/UserContext";
import Option from "../../../product/component/Option";

export default function FlavorOptions(props) {
  let { productType, value } = props;
  const { user } = useContext(UserContext);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="textSecondary" gutterBottom>
            Flavor
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
            {Object.entries(user.config?.flavors).map(([key, option]) => {
              return (
                <Option
                  title={option.name}
                  description={option.description}
                  onClick={(selected) => {
                      props.onChange({ base: { name: selected ? option.name : "" }, filling: value?.filling });                    
                  }}
                  selected={value?.base?.name === option.name}
                />
              );
            })}
          </Box>
        </Grid>
        {productType === "Cake" && (
          <Grid item xs={12}>
            <Typography variant="h5" color="textSecondary" gutterBottom>
              Filling
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
              {Object.entries(user.config?.fillings).map(([key, option]) => {
                return (
                  <Option
                    title={option.name}
                    description={option.description}
                    onClick={(selected) => props.onChange({ base: value?.base, filling: { name: selected ? option.name : "" } })}
                    selected={value?.filling?.name === option.name}
                  />
                );
              })}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
