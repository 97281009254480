import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Container,
  Chip,
  Button,
  Grid,
  LinearProgress,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import bkstApi from "../../api";
import OrderDetails from "../component/OrderDetails";
import OrderFulfillment from "../component/OrderFulfillment";
import BakerOrderPayment from "../component/BakerOrderPayment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Link as RouterLink } from "react-router-dom";
import { Alert } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router";
import OrderCancelationPolicy from "../component/OrderCancelationPolicy";
import LatePaymentPolicy from "../component/LatePaymentPolicy";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { hasManagerAccess } from "../../common/util";
import CustomerDetailsButton from "../../common/component/CustomerDetailsButton";
import BakerMktOrderPolicy from "../component/BakerMktOrderPolicy";
import { UserContext } from "../../context/UserContext";
import OrderChat from "../component/OrderChat";
import MarkOrderCompleted from "../component/MarkOrderCompleted";
import { useLocation } from "react-router-dom";
import OrderPrint from "../../baker/screen/OrderPrint";
import Review from "../../review/component/Review";
import PendingReviewOrder from "./PendingReviewOrder";
import KitchenInstructions from "../component/KitchenInstructions";
import OrderProgress from "../component/OrderProgress";
import CustomerContactInfoButton from "../../common/component/CustomerContactInfoButton";
import KitchenOrder from "../../baker/components/KitchenOrder";
import { ExpandMoreOutlined } from "@mui/icons-material";
import UserAvatar from "../../common/UserAvatar";
import OrderReceiptPrint from "../../baker/screen/OrderReceiptPrint";
import OrderRefundPolicy from "../component/OrderRefundPolicy";
import { Timeline, TimelineContent, TimelineConnector, TimelineSeparator } from "@mui/lab";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";

export default function Order(props) {
  const { user } = useContext(UserContext);
  let location = useLocation();

  const navigate = useNavigate();

  const { orderId } = useParams();
  const [order, setOrder] = useState();

  const [history, setHistory] = useState("");

  useEffect(() => {
    setOrder();
    bkstApi(`/my/order/${orderId}`)
      .then((res) => {
        document.title = res.data.details?.title;
        setOrder(res.data);
      })
      .catch((error) => {
        navigate(`/dashboard`);
      });
  }, [orderId, location.key]);

  const printReceipt = () => {
    bkstApi.post(`/my/order/${orderId}/print-receipt`).then((res) => {});
  };

  const getHistory = () => {
    return bkstApi(`/my/order/${orderId}/history`).then((res) => {
      let arr = [];
      for (let o of res.data.history) {
        if (!o.updTs) {
          o.updTs = o.ts._seconds * 1000;
        }
        arr.push(o);
      }
      arr.sort((o1, o2) => +o2.updTs - +o1.updTs);

      setHistory(arr);
    });
  };

  if (!order) {
    return <LinearProgress />;
  } else {
    let isManager = hasManagerAccess(user.role) || user.role === "kitchen leader";
    let isClerk = user.role === "clerk";

    const showAddOnButton = isManager && order.status === "confirmed" && order.paymentType !== "offline";
    const showEditButton = isManager && order.status === "confirmed";
    const showChat =
      (isManager && order.src === "direct" && order.paymentType !== "offline") ||
      (isManager && order.src === "mkt" && (order.status === "confirmed" || order.status === "fulfilled"));

    const orderDetailsWidget = (
      <Box mt={2} mb={6}>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            {order.customer.id ? (
              <CustomerDetailsButton customer={order.customer} />
            ) : order.customer ? (
              <CustomerContactInfoButton
                customer={{
                  firstName: order.customer.firstName,
                  lastName: order.customer.lastName,
                  name: order.customer.name,
                  phone: ((order.status === "confirmed" || order.status === "fulfilled") && order.customer.phone) || ""
                }}
              />
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "flex-end" }}>
              {showChat && <OrderChat order={order} />}
              {showAddOnButton && (
                <IconButton size="small" component={RouterLink} to={`/order/${orderId}/create-invoice`}>
                  <AddShoppingCartOutlinedIcon />
                </IconButton>
              )}
              {showEditButton && (
                <IconButton size="small" component={RouterLink} to={`/order/${orderId}/edit`}>
                  <EditOutlinedIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
        </Grid>
        <OrderDetails order={order} allowDownload={true} />
      </Box>
    );

    const kitchenWidget = (
      <Box mt={2} mb={6}>
        <Grid container style={{ marginBottom: "5px", alignItems: "center" }}>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
              {order.uid && <UserAvatar uid={order.uid} />}
              <Typography variant="overline" color="textSecondary">
                PRODUCTION
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            {order.status === "confirmed" && (
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center", justifyContent: "flex-end" }}>
                {isManager && <OrderProgress progress={order.progress} orderId={order.id} />}
                <OrderPrint order={order} />
                {isManager && (
                  <IconButton size="small" component={RouterLink} to={`/order/${orderId}/kitchen/edit`}>
                    <EditOutlinedIcon />
                  </IconButton>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
        <KitchenInstructions data={order.kitchen} />
      </Box>
    );

    let content = <Box />;
    if (order.status === "pending") {
      content = (
        <Box>
          <Box mt={2} mb={6}>
            <OrderDetails order={order} allowDownload={true} />
          </Box>
          <Box mt={2} mb={6}>
            <OrderFulfillment type={order.fulfillment.type} order={order.fulfillment} />
          </Box>
        </Box>
      );
    } else if (order.status === "canceled" && order.src === "mkt") {
      content = (
        <Box>
          <Alert severity="error">This order has been canceled</Alert>
          <Box mt={2} mb={6}>
            <OrderDetails order={order} />
          </Box>
        </Box>
      );
    } else if (order.status === "confirmed") {
      content = (
        <Box>
          {orderDetailsWidget}
          {(isManager || isClerk || isClerk) && kitchenWidget}
          <Box mt={2} mb={6}>
            <Grid container alignItems="center" style={{ marginBottom: "5px" }}>
              <Grid item xs={6}>
                <Typography variant="overline" color="textSecondary">
                  {order.fulfillment.type} DETAILS
                </Typography>
              </Grid>
              <Grid item xs={6} align="right">
                {isManager && (
                  <IconButton size="small" component={RouterLink} to={`/order/${orderId}/fulfillment/edit`}>
                    <EditOutlinedIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <OrderFulfillment type={order.fulfillment.type} order={order.fulfillment} />
          </Box>
          <Box mt={2} mb={6}>
            <BakerOrderPayment order={order} />
          </Box>
        </Box>
      );
    } else if (order.status === "fulfilled") {
      content = (
        <Box>
          {orderDetailsWidget}
          {(isManager || isClerk) && kitchenWidget}
          <Box mt={2} mb={6}>
            <Typography variant="overline" color="textSecondary">
              {order.fulfillment.type} Details
            </Typography>
            <OrderFulfillment type={order.fulfillment.type} order={order.fulfillment} />
          </Box>
          <Box mt={2} mb={6}>
            <BakerOrderPayment order={order} />
          </Box>
        </Box>
      );
    } else if (order.status === "completed" || order.status === "canceled") {
      content = (
        <Box>
          {orderDetailsWidget}
          {isManager && kitchenWidget}
          <Box mt={2} mb={6}>
            <Typography variant="overline" color="textSecondary">
              {order.fulfillment.type} Details
            </Typography>
            <OrderFulfillment type={order.fulfillment.type} order={order.fulfillment} />
          </Box>
          <Box mt={2} mb={6}>
            <BakerOrderPayment order={order} />
          </Box>
        </Box>
      );
    }

    if (user.role === "kitchen") {
      return (
        <Container maxWidth="md">
          <KitchenOrder order={order} />{" "}
        </Container>
      );
    } else if (order.status === "pending_review") {
      return <PendingReviewOrder order={order} />;
    } else {
      return (
        <Container maxWidth="sm">
          <Box my={2}>
            {(order.status === "fulfilled" || order.status === "completed") && <Alert severity="success">This order has been completed!</Alert>}
            {order.status === "canceled" && <Alert severity="error">This order has been canceled</Alert>}
            {order.paymentType === "offline" && (
              <Alert severity="warning">
                Cash orders are for internal tracking only. They don't track payment, support in-app chat or send automated notifications like order
                confirmation, reminders or solicit review.
              </Alert>
            )}
            {order.status === "confirmed" && (hasManagerAccess(user.role) || isClerk) && (
              <Box mt={2}>
                <MarkOrderCompleted order={order} />
              </Box>
            )}
          </Box>
          <Box py={2}>
            <Typography variant="h4" gutterBottom>
              Order Details
            </Typography>
            {(user.role === "owner" || user.role === "manager") && (
              <Typography variant="body2" color="textSecondary">
                Manage all aspects of this order, including communication with customer, payments, add-ons, order updates & more..
              </Typography>
            )}
          </Box>
          <Box>{content}</Box>
          {isManager && order.review && (
            <Box mt={2} mb={6}>
              <Typography variant="overline" color="textSecondary">
                REVIEW
              </Typography>
              <Review data={order.review} />
            </Box>
          )}
          {order.src === "mkt" && (
            <Box mt={2} mb={6}>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                BAKESTREET'S POLICIES
              </Typography>
              <Box p={2} sx={{ border: "1px solid #DDD" }}>
                <Box mb={2}>
                  <Typography variant="body2" color="textSecondary">
                    This is a BakeStreet marketplace order. The following policies are applicable to you.
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    ---
                  </Typography>
                </Box>
                <BakerMktOrderPolicy />
              </Box>
            </Box>
          )}
          <Box mt={2} mb={6}>
            {order.src === "direct" && order.paymentType !== "offline" && (
              <Accordion elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>Order Policy</AccordionSummary>
                <AccordionDetails sx={{ marginLeft: "15px", borderLeft: "3px solid #EEE" }}>
                  <Box p={2}>
                    <Box mb={2}>
                      <Typography variant="body2" color="textSecondary">
                        These policies were shown to the customer during checkout. They are also visible to the customer on their order page.
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        ---
                      </Typography>
                    </Box>
                    <OrderCancelationPolicy
                      date={order.fulfillment.date}
                      total={order.payment.total}
                      tip={order.payment.tip}
                      delivery={order.payment.delivery}
                      ts={order.ts._seconds * 1000}
                    />
                    <LatePaymentPolicy />
                    <OrderRefundPolicy />
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion elevation={0}>
              <AccordionSummary expandIcon={<ExpandMoreOutlined />}>Other Actions</AccordionSummary>
              <AccordionDetails sx={{ marginLeft: "15px", borderLeft: "3px solid #EEE" }}>
                <Box p={2}>
                  <Box my={4}>
                    <OrderReceiptPrint order={order} />
                  </Box>
                  {order.reqId && hasManagerAccess(user.role) && (
                    <Box my={4}>
                      <Button component={RouterLink} variant="contained" color="secondary" fullWidth to={`/quote/${order.reqId}`}>
                        View Quote
                      </Button>
                    </Box>
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
            {isManager && order.src === "direct" && (
              <Accordion
                elevation={0}
                onChange={(e, expanded) => {
                  if (expanded) {
                    getHistory();
                  }
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>History</AccordionSummary>
                <AccordionDetails sx={{ padding: 0 }}>
                  <Box>
                    {history && (
                      <Timeline
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0
                          }
                        }}
                      >
                        {history.map((o) => {
                          return (
                            <TimelineItem>
                              <TimelineSeparator>
                                <Avatar src={`https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/u/${o.uid}.webp`} />
                                <TimelineConnector />
                                <TimelineConnector />
                              </TimelineSeparator>
                              <TimelineContent ml={1} mb={4} sx={{ backgroundColor: "#F7F7F7" }}>
                                <Box mb={1}>
                                  <Typography variant="body2" display="block">
                                    {moment(o.updTs).format("LLLL")}
                                  </Typography>
                                  <Chip size="small" label={o.status} />
                                  <Box my={2}>
                                    <OrderDetails order={o} />
                                  </Box>
                                  {o.kitchen?.notes && (
                                    <Box my={2}>
                                      <Typography variant="overline" color="textSecondary">
                                        PRODUCTION
                                      </Typography>
                                      <KitchenInstructions data={o.kitchen} />
                                    </Box>
                                  )}
                                  <Box my={2}>
                                    <Typography variant="overline" color="textSecondary">
                                      FULFILLMENT
                                    </Typography>
                                    <OrderFulfillment type={o.fulfillment.type} order={o.fulfillment} />
                                  </Box>
                                  {o.payment.dueDate && (
                                    <Box my={2}>
                                      <Alert severity="info">Balance Due on {moment(o.payment.dueDate).format("MMM D")}</Alert>
                                    </Box>
                                  )}
                                </Box>
                              </TimelineContent>
                            </TimelineItem>
                          );
                        })}
                      </Timeline>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
          </Box>

          {isManager && order.status === "confirmed" && order.src === "direct" && (
            <Box align="center" mt={2} mb={6}>
              <Button color="error" variant="outlined" fullWidth to={`/order/${order.id}/cancel`} component={RouterLink}>
                Cancel This Order
              </Button>
              <Typography mt={2} variant="body2" color="textSecondary">{`This order was confirmed on ${moment(order.ts._seconds * 1000).format(
                "ddd, MMM DD, YYYY h:mm a"
              )} (${moment(order.ts._seconds * 1000).fromNow()})`}</Typography>
            </Box>
          )}
          {user.acctId === "-MX2oaNrh4TcKVvnhcT9" && (
            <Button fullWidth variant="outlined" onClick={printReceipt}>
              Print Receipt
            </Button>
          )}
        </Container>
      );
    }
  }
}
