import React, { useEffect, useState, useRef } from "react";
import { Autocomplete, Box, CircularProgress, Link, InputAdornment, TextField, Typography, Avatar } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Link as RouterLink } from "react-router-dom";
import { formatPhoneNumber } from "../util";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import bkstApi from "../../api";

export default function Lookup() {
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    useEffect(() => {
      // Cleanup the previous timeout on re-render
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    const debouncedCallback = (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };

    return debouncedCallback;
  };

  const handleSearch = useDebounce((query) => {
    callApi(query);
  }, 1000);

  const callApi = () => {
    bkstApi(`/my/lookup?q=${q}`).then((res) => {
      const results = res.data;
      setOptions(results);
      setLoading(false);
    });
  };

  const handleClick = (e) => {
    setQ("");
    document.activeElement.blur();
  };

  useEffect(() => {
    if (q.length > 1) {
      setLoading(true);
      handleSearch(q);
    } else {
      setOptions([]);
    }
  }, [q]);

  const getListItem = (obj, option) => {
    const orderCount = +option.summary?.orders?.past + +option.summary?.orders?.future;
    const futureQuotesCount = +option.summary?.quotes?.future;

    let summary = (
      <Box>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box>
            <Avatar>
              {option.firstName?.[0]}
              {option.lastName?.[0]}
            </Avatar>
          </Box>
          <Box>
            <Typography style={{ display: "flex", alignItems: "center", fontSize: ".9em" }}>
              {option.firstName} {option.lastName}
            </Typography>
            <Typography variant="overline" display="block" color="textSecondary">
              {formatPhoneNumber(option.phone) || ""}
            </Typography>
          </Box>
        </Box>
        <Box>
          {orderCount > 0 && (
            <Typography variant="overline" color="textSecondary" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <ShoppingBagOutlinedIcon sx={{ fontSize: "16px", color: "#999" }} />
              {orderCount} Orders {+option.summary.orders.future > 0 ? ` (${option.summary.orders.future} upcoming)` : ""}
            </Typography>
          )}
          {futureQuotesCount > 0 && (
            <Typography variant="overline" color="textSecondary" style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <LocalAtmOutlinedIcon sx={{ fontSize: "16px", color: "#999" }} />
              {futureQuotesCount} Quotes
            </Typography>
          )}
        </Box>
      </Box>
    );

    return (
      <Link
        key={option.objectID}
        component={RouterLink}
        to={`/customer/${option.customerId}`}
        style={{ textDecoration: "none", color: "inherit" }}
        onClick={handleClick}
      >
        <Box m={1} px={1} py={1} sx={{ backgroundColor: "#f7f7f7" }}>
          {summary}
        </Box>
      </Link>
    );
  };

  return (
    <Autocomplete
      id="lookup"
      freeSolo
      noOptionsText={"No matches found"}
      style={{ maxWidth: "220px", flexGrow: "1" }}
      filterOptions={(x) => x}
      renderOption={getListItem}
      getOptionLabel={(option) => option.customerId}
      options={options}
      disableClearable
      blurOnSelect={true}
      clearOnBlur={true}
      renderInput={(params) => (
        <TextField
          {...params}
          value={q}
          variant="outlined"
          size="small"
          placeholder="Name / Phone (last 4)"
          onChange={(e) => setQ(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? (
              <React.Fragment>
                <CircularProgress color="inherit" size={20} />
                {params.InputProps.endAdornment}
              </React.Fragment>
            ) : (
              <InputAdornment position="end">
                <SearchOutlinedIcon style={{ color: "#888" }} />
              </InputAdornment>
            )
          }}
        />
      )}
    />
  );
}
