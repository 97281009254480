import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  Chip,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Images from "../../request/components/Images";
import { PRODUCT_TYPES_V2, QUOTE_TAGS, STORAGE_BUCKET, IMG_PLACEHOLDER } from "../../common/constants";
import { Autocomplete } from "@mui/material";
import currency from "currency.js";
import { capitalize, getAmountWithTxnFee } from "../../common/util";
import Quantity from "../../common/component/Quantity";
import TextWithReadMore from "../../common/TextWithReadMore";
import QuillRTE from "../../common/component/QuillRTE";
import ItemCustomizationEntry from "../../common/component/lineitem/ItemCustomizationEntry";
import Price from "../../common/component/Price";
import FlavorOptions from "../../common/component/variant/FlavorOptions";
import Option from "./Option";
import { FROSTING } from "../../common/constants";
import SizeChartOptions from "../../common/component/variant/SizeChartOptions";

export default function CustomItem(props) {
  const { user } = useContext(UserContext);
  const errorRef = useRef();

  const { immutablePrice } = props;

  const [item, setItem] = useState(props.item || "");
  const [errors, setErrors] = useState([]);

  const [tab, setTab] = useState("size");
  const [open, setOpen] = useState(false);

  const validate = () => {
    let arr = [];
    if (!item.type?.trim()) {
      arr.push("Item Type is required");
    }
    if (item.type === "Other" && !item.title?.trim()) {
      arr.push("Title is required");
    }
    if (!(+item.price > 0)) {
      arr.push("Item Price is required");
    }
    if (!(+item.quantity > 0)) {
      arr.push("Quantity is required");
    }

    return arr;
  };

  const handleSubmit = () => {
    const arr = validate();
    setErrors(arr);

    if (arr.length > 0) {
      errorRef.current?.scrollIntoView({ behavior: "smooth" });

      return;
    } else {
      props.onChange(item);
      handleClose();
    }
  };

  const handleDelete = () => {
    props.onChange("", "delete");
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    setItem("");
    setTab("size");
    setErrors([]);
  };

  const updateTotal = (price, quantity, includeFees) => {
    const subTotal = currency(price).multiply(quantity);
    const total = includeFees ? getAmountWithTxnFee(subTotal, user.config?.fees) : subTotal;
    setItem({ ...item, price, quantity, includeFees, total });
  };

  const handleItemTypeChange = (type) => {
    let title = "";
    if (type !== "Other") {
      title = type;
    }
    setItem({ images: item.images, title, type, price: "", quantity: 1, includeFees: item.includeFees });
  };

  return (
    <>
      {props.item ? (
        <Link
          underline="none"
          sx={{ cursor: "pointer", fontSize: "12px" }}
          onClick={() => {
            setItem(props.item);
            setOpen(true);
          }}
        >
          EDIT
        </Link>
      ) : (
        <Link
          color="inherit"
          underline="never"
          sx={{ cursor: "pointer", textAlign: "center" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            setItem({
              images: [],
              title: "Custom Item",
              type: "",
              price: "",
              quantity: 1,
              includeFees: true
            });
            setOpen(true);
          }}
        >
          <Box sx={{ border: "1px solid #ECECEC", backgroundColor: "#FFF", borderRadius: "4px", width: "120px" }}>
            <img
              src={`https://res.cloudinary.com/draqf7k7f/image/upload/v1743477923/custom_r4qkyq.png`}
              style={{ width: "100%", aspectRatio: "1", objectFit: "cover" }}
            />
            <Box>
              <Typography
                color="textSecondary"
                sx={{
                  fontSize: "12px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                  paddingBottom: "4px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis"
                }}
              >
                Custom Item
              </Typography>
            </Box>
          </Box>
        </Link>
      )}
      {open && (
        <Dialog fullScreen={true} open={open} fullWidth={true}>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "contents"
            }}
          >
            <DialogTitle
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                alignItems: "flex-start",
                margin: "0",
                backgroundColor: "#DEDEDE",
                borderBottom: "1px solid #DDD",
                paddingBottom: "0"
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleClose}>
                  <ArrowBackIcon />
                </IconButton>
                <Box>
                  <Typography variant="h5">Custom Item</Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "1rem", alignItems: "flex-start", justifyContent: "right", flexGrow: "1" }}>
                <Price
                  label="Price"
                  value={{ amount: item.price, includeFees: item.includeFees }}
                  onChange={(o) => {
                    updateTotal(o.amount, item.quantity, o.includeFees);
                  }}
                />
                <Quantity
                  label="Qty"
                  width="130px"
                  value={item.quantity}
                  onChange={(quantity) => {
                    updateTotal(item.price, quantity, item.includeFees);
                  }}
                />
                <Button size="large" type="submit" color="primary" variant="contained" sx={{ width: "130px", height: "56px", whiteSpace: "nowrap" }}>
                  {currency(item.total).format()} - Save
                </Button>
              </Box>
            </DialogTitle>
            <DialogContent sx={{ backgroundColor: "#F7F7F7", padding: "0" }}>
              <Box ref={errorRef}>
                {errors.length > 0 && (
                  <Alert sx={{ justifyContent: "center" }} severity="error">
                    {errors.join(". ")}
                  </Alert>
                )}
              </Box>
              <Box px={2} py={2}>
                <Grid container>
                  <Grid item sm={4}></Grid>
                  <Grid item sm={8}>
                    <Box>
                      <Tabs value={tab} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                        <Tab value="size" label={"Item"} onClick={() => setTab("size")} />
                        <Tab value="options" label={"Options"} onClick={() => setTab("options")} />
                        <Tab value="notes" label={"Notes"} onClick={() => setTab("notes")} />
                      </Tabs>
                      {errors.length > 0 && (
                        <Box mb={2} sx={{ display: "block" }}>
                          <Alert severity="error">{errors.join(". ")}</Alert>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item sm={4}>
                    <Box pr={4} sx={{ position: "-webkit-sticky", position: "sticky", top: "0" }}>
                      <Images edit={true} pics={item.images} onChange={(images) => setItem({ ...item, images })} folder={`tmp`} />
                    </Box>
                  </Grid>
                  <Grid item sm={8}>
                    {tab === "size" && (
                      <Paper elevation={0} sx={{ minHeight: "100%" }}>
                        <Box px={2} py={3}>
                          <Box>
                            {item.type && (
                              <Chip
                                label={
                                  <Box>
                                    {`Item Type`} <span style={{ color: "#999" }}>|</span>{" "}
                                    <span style={{ color: "#78374a", textTransform: "capitalize" }}>{item.type}</span>
                                  </Box>
                                }
                                onDelete={() => handleItemTypeChange("")}
                              ></Chip>
                            )}
                            {item.variant?.size && (
                              <Chip
                                label={
                                  <Box>
                                    {`Size`} <span style={{ color: "#999" }}>|</span>{" "}
                                    <span style={{ color: "#78374a", textTransform: "capitalize" }}>{item.type}</span>
                                  </Box>
                                }
                                onDelete={() => setItem({ ...item, variant: { ...item.variant, size: "" } })}
                              ></Chip>
                            )}
                          </Box>
                          {!item.type && (
                            <Box sx={{ display: "flex", gap: "2rem", flexWrap: "wrap" }}>
                              {PRODUCT_TYPES_V2.map((o, idx) => (
                                <Option
                                  title={o.name}
                                  description={""}
                                  img={`${STORAGE_BUCKET}${o.img}`}
                                  onClick={(selected) => handleItemTypeChange(o.name)}
                                  selected={item.type === o.name}
                                />
                              ))}
                            </Box>
                          )}
                          {item.type === "Other" && (
                            <Box mt={3}>
                              <TextField
                                id="title"
                                value={item.title}
                                label="Title"
                                name="title"
                                variant="outlined"
                                fullWidth
                                onChange={(e) => setItem({ ...item, title: e.target.value })}
                                required
                                helperText="What's the item?"
                                disabled={immutablePrice}
                              />
                            </Box>
                          )}
                          <Box>
                            {item.type === "Cake" && !item.variant?.size && (
                              <Box my={3}>
                                <SizeChartOptions
                                  productType={item.type}
                                  value={item.variant?.size}
                                  onChange={(o) => setItem({ ...item, variant: { ...item.variant, size: o } })}
                                />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Paper>
                    )}
                    {tab === "options" && (
                      <Paper elevation={0} sx={{ minHeight: "100%" }}>
                        <Box px={2} py={3}>
                          {item.type === "Cake" && (
                            <Box mb={4}>
                              <Typography variant="h5" color="textSecondary" gutterBottom>
                                Frosting
                              </Typography>
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "15px" }}>
                                {(user.config?.frosting || FROSTING).map((option) => {
                                  return (
                                    <Option
                                      title={option}
                                      description={""}
                                      onClick={(selected) =>
                                        setItem({ ...item, variant: { ...item.variant, frosting: { name: selected ? option : "" } } })
                                      }
                                      selected={item.variant?.frosting?.name === option}
                                    />
                                  );
                                })}
                              </Box>
                            </Box>
                          )}
                          <Box>
                            <FlavorOptions
                              productType={item.type}
                              value={item.variant?.flavor}
                              onChange={(flavor) => setItem({ ...item, variant: { ...item.variant, flavor: flavor } })}
                            />
                          </Box>
                        </Box>
                      </Paper>
                    )}
                    {tab === "notes" && (
                      <Paper elevation={0} sx={{ minHeight: "100%" }}>
                        <Box px={2} py={3}>
                          {item.type === "Cake" && (
                            <Box>
                              <ItemCustomizationEntry
                                value={item.customization || ""}
                                type={item.type}
                                onChange={(customization) => setItem({ ...item, customization })}
                              />
                              <Box my={3}>
                                <Autocomplete
                                  value={item.tags || []}
                                  onChange={(e, tags) => setItem({ ...item, tags })}
                                  options={QUOTE_TAGS}
                                  filterSelectedOptions
                                  renderInput={(params) => <TextField variant="outlined" {...params} label="Tags" />}
                                  multiple
                                  freeSolo
                                />
                              </Box>
                            </Box>
                          )}
                          <QuillRTE value={item.description || ""} onChange={(description) => setItem({ ...item, description })} />
                          <Box py={0.4}>
                            <TextWithReadMore
                              data={`These notes are shown to customer. ${
                                item.type === "Cake"
                                  ? `Use it to describe decor (edible image, ruffles, sugar flowers..), toppers (edible 3D
                      fondant, 2D cardstock) & more. Propose alternative options when sending quotes.`
                                  : ""
                              }`}
                              limit={140}
                            />
                          </Box>
                        </Box>
                      </Paper>
                    )}
                  </Grid>
                  <Grid item sm={4}></Grid>
                  <Grid item sm={8}>
                    {props.item && (
                      <Box my={4}>
                        <Button color="error" onClick={handleDelete} fullWidth>
                          Delete This Item
                        </Button>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box py={20}></Box>
            </DialogContent>
          </form>
        </Dialog>
      )}
    </>
  );
}
