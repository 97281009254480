import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";

function ButtonWithConfirmation(props) {
  const { title, copy } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <span onClick={() => setOpen(true)}>{props.children}</span>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{title || "Confirm?"}</DialogTitle>
        <DialogContent>
          <Box pt={4} pb={2}>
            <Typography variant="subtitle1">{copy}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary" variant="outlined" fullWidth>
            CANCEL
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              props.onConfirm();
            }}
            color="primary"
            variant="contained"
            autoFocus
            fullWidth
          >
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ButtonWithConfirmation;
