import React, { useContext, useState, useRef } from "react";
import {
  Alert,
  Box,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Paper,
  Button,
  Link,
  IconButton,
  TextField,
  Typography,
  Container,
  Tab,
  Tabs,
  LinearProgress
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import { UserContext } from "../../../context/UserContext";
import AddressAutocomplete from "../../gmaps/AddressAutocomplete";
import { TIME_WINDOWS } from "../../constants";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Option from "../../../product/component/Option";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import Price from "../Price";
import { capitalize } from "../../util";
import bkstApi from "../../../api";
import currency from "currency.js";

export default function AddFulfillment(props) {
  const { user } = useContext(UserContext);
  const errorRef = useRef(null);

  const [fulfillment, setFulfillment] = useState("");
  const [deliveryQuote, setDeliveryQuote] = useState("");

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [errors, setErrors] = useState([]);

  const handleSelection = (val) => {
    if (val === "togo") {
      props.onChange({
        type: "togo",
        date: moment().format("YYYY-MM-DD"),
        location: user.config.storeLocation[0].address
      });
    } else {
      setFulfillment({ type: val, date: "", location: "" });
      if (val === "delivery") {
        resetDeliveryQuote();
      }
      setOpen(true);
    }
  };

  const resetDeliveryQuote = () => {
    setDeliveryQuote({ amount: "", includeFees: user.config?.transferFees === "false" ? false : true, total: "" });
  };

  const validate = () => {
    let arr = [];
    if (!fulfillment.location) {
      if (fulfillment.type === "pickup") {
        arr.push("Pickup Location is missing");
      } else if (fulfillment.type === "delivery") {
        arr.push("Delivery Address is missing");
      }
    }
    if (!fulfillment.date) {
      arr.push("Date is missing");
    }
    if (!fulfillment.time) {
      arr.push("Time is missing");
    }
    return arr;
  };

  const handleSubmit = (o) => {
    let arr = validate();
    if (fulfillment.type === "delivery") {
      if (!deliveryQuote.amount) {
        arr.push("Delivery Fee is missing");
      }
    }
    setErrors(arr);
    if (arr.length > 0) {
      return;
    } else {
      if (o.type === "delivery" && deliveryQuote) {
        o.option = { delivery: deliveryQuote };
      } else {
        delete o.option;
      }
      props.onChange(o);
      handleClose();
    }
  };

  const handleClose = () => {
    setFulfillment("");
    setDeliveryQuote("");
    setOpen(false);
  };

  const getDeliveryQuote = () => {
    const arr = validate();
    setErrors(arr);
    if (arr.length > 0) {
      return;
    } else {
      setLoading(true);
      bkstApi
        .post(`/my/delivery/quote`, { address: fulfillment.location, provider: { name: "metrobi" } })
        .then((res) => {
          setDeliveryQuote({ amount: res.data.quote, includeFees: false, total: res.data.quote });
          setLoading(false);
        })
        .catch((err) => {
          setErrors([err.response?.data?.msg]);
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Chip
          label="TO-GO"
          clickable
          onClick={() => handleSelection("togo")}
          color={props.fulfillment.type === "togo" ? "info" : "secondary"}
          variant={props.fulfillment.type === "togo" ? "contained" : "outlined"}
        />
        <Chip
          label="PICKUP"
          clickable
          onClick={() => handleSelection("pickup")}
          color={props.fulfillment.type === "pickup" ? "info" : "secondary"}
          variant={props.fulfillment.type === "pickup" ? "contained" : "outlined"}
        />
        <Chip
          label="DELIVERY"
          clickable
          onClick={() => handleSelection("delivery")}
          color={props.fulfillment.type === "delivery" ? "info" : "secondary"}
          variant={props.fulfillment.type === "delivery" ? "contained" : "outlined"}
        />
      </Box>
      {props.fulfillment.type && props.fulfillment.type !== "togo" && (
        <Box>
          <Link
            underline="none"
            onClick={() => {
              setFulfillment(props.fulfillment);
              console.log(props.fulfillment);

              if (props.fulfillment.type === "delivery") {
                setDeliveryQuote(props.fulfillment.option.delivery);
              }

              setOpen(true);
            }}
            sx={{ cursor: "pointer" }}
          >
            <Box pt={1} px={1}>
              <Typography color="textSecondary" variant="overline" style={{ display: "flex", alignItems: "center" }}>
                <AccessTimeOutlinedIcon style={{ fontSize: "1.3em", color: "#999", marginRight: "7px" }} />
                {moment(props.fulfillment.date).format("ddd, MMM D, YYYY")} [{props.fulfillment.time || ""}]
              </Typography>
              <Box>
                <Typography color="textSecondary" variant="overline" style={{ display: "flex", alignItems: "center" }}>
                  {props.fulfillment.type === "delivery" ? (
                    <LocalShippingOutlinedIcon style={{ fontSize: "1.3em", color: "#999", marginRight: "7px" }} />
                  ) : (
                    <ShoppingBagOutlinedIcon style={{ fontSize: "1.3em", color: "#999", marginRight: "7px" }} />
                  )}
                  {props.fulfillment.location.street}
                </Typography>
              </Box>
            </Box>
          </Link>
        </Box>
      )}
      <Dialog fullScreen={true} open={open} scroll="paper" onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0",
            backgroundColor: "#DEDEDE",
            borderBottom: "3px solid #DEDEDE",
            paddingBottom: "10px"
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => setOpen(false)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5">Set {capitalize(fulfillment?.type)} Details</Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "flex-start", justifyContent: "right", flexGrow: "1" }}>
            {fulfillment.provider?.name === "metrobi" && !deliveryQuote?.total ? (
              <Button color={"primary"} variant="outlined" onClick={getDeliveryQuote}>
                Get Delivery Quote
              </Button>
            ) : (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={(e) => {
                  handleSubmit(fulfillment);
                }}
              >
                {fulfillment?.type === "delivery"
                  ? `${+deliveryQuote?.total === 0 ? "Free" : currency(deliveryQuote.total).format()} Delivery | `
                  : ""}
                Save
              </Button>
            )}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#F7F7F7" }}>
          {loading && <LinearProgress />}
          <Box ref={errorRef}>
            {errors.length > 0 && (
              <Alert sx={{ justifyContent: "center" }} severity="error">
                {errors.join(". ")}
              </Alert>
            )}
          </Box>
          <Container maxWidth={fulfillment?.type === "delivery" ? "lg" : "md"}>
            <Box px={2} py={4} sx={{ display: "flex", gap: "2rem" }}>
              <Box sx={{ justifyContent: "flex-end", flexGrow: "1" }}>
                {fulfillment?.type === "pickup" && (
                  <Box>
                    <Box py={1}>
                      <Typography variant="overline">{fulfillment.type} Location</Typography>
                    </Box>
                    <Paper elevation={0}>
                      <Box px={2} py={4}>
                        {user.config.storeLocation.map((o, idx) => {
                          return (
                            <Box mb={4}>
                              <Option
                                title={o.address.street}
                                description={`${o.address.city}, ${o.address.state}`}
                                onClick={() => setFulfillment({ ...fulfillment, location: o.address })}
                                selected={fulfillment?.location.street === o.address.street}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Paper>
                  </Box>
                )}
                {fulfillment?.type === "delivery" && (
                  <Box sx={{ flexGrow: "1" }}>
                    <Tabs value={fulfillment?.provider?.name || "in-house"}>
                      <Tab
                        value={"in-house"}
                        sx={{ fontSize: ".75rem" }}
                        label={"In-House Delivery"}
                        onClick={() => {
                          setFulfillment({ ...fulfillment, provider: "" });
                          resetDeliveryQuote();
                        }}
                      />
                      <Tab
                        value="metrobi"
                        sx={{ fontSize: ".75rem" }}
                        label={"Metrobi"}
                        onClick={() => {
                          setFulfillment({ ...fulfillment, provider: { name: "metrobi" } });
                          resetDeliveryQuote();
                        }}
                      />
                    </Tabs>
                    <Box>
                      <Paper elevation={0}>
                        <Box p={2}>
                          <Box my={2}>
                            <FormControl variant="outlined" fullWidth>
                              <AddressAutocomplete
                                value={fulfillment?.location || ""}
                                onPlaceSelect={(loc) => {
                                  setFulfillment({ ...fulfillment, location: loc });
                                  resetDeliveryQuote();
                                }}
                                label="Delivery Address"
                                required
                              />
                            </FormControl>
                          </Box>
                          {fulfillment.provider?.name !== "metrobi" && (
                            <Box mt={3}>
                              <Price
                                label={"Delivery fee"}
                                value={deliveryQuote}
                                disableFees={true}
                                onChange={(o) => {
                                  setDeliveryQuote(o);
                                }}
                              />
                            </Box>
                          )}
                          <Box mt={2}>
                            <TextField
                              id="instructions"
                              label="Instructions (optional)"
                              name="instructions"
                              variant="outlined"
                              fullWidth
                              value={fulfillment?.instructions || ""}
                              onChange={(e) => setFulfillment({ ...fulfillment, instructions: e.target.value })}
                              helperText="Instructions for delivery driver"
                            />
                          </Box>
                        </Box>
                      </Paper>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box>
                <Box sx={{ display: "flex", gap: "2rem" }}>
                  <Box>
                    <Box py={1}>
                      <Typography variant="overline">{fulfillment.type} Date</Typography>
                    </Box>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <StaticDatePicker
                        sx={{ border: "1px solid #ECECEC" }}
                        slotProps={{
                          actionBar: {
                            actions: []
                          },
                          toolbar: { hidden: true }
                        }}
                        disablePast
                        value={fulfillment.date ? moment(fulfillment.date).toDate() : null}
                        format="MMM d, y"
                        onChange={(value) => {
                          setFulfillment({ ...fulfillment, date: (value && moment(value).format("YYYY-MM-DD")) || "" });
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <Box py={1}>
                      <Typography variant="overline">{fulfillment.type} Time</Typography>
                    </Box>
                    {TIME_WINDOWS.map((slot, idx) => {
                      const isSelected = fulfillment.time === slot;
                      return (
                        <Box mb={1.5}>
                          <Button
                            onClick={() => {
                              setFulfillment({ ...fulfillment, time: slot });
                            }}
                            variant={isSelected ? "contained" : "outlined"}
                            size="small"
                            color={"secondary"}
                            disableElevation
                            sx={{ width: "160px" }}
                          >
                            {slot}
                          </Button>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
}
