import React, { useContext, useState, useEffect } from "react";
import { Box, Container, Button, InputAdornment, IconButton, Typography, TextField } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import bkstApi from "../../api";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Link as RouterLink } from "react-router-dom";
import StandardItem from "./StandardItem";
import FilterListIcon from "@mui/icons-material/FilterList";
import CustomItem from "./CustomItem";
import { anyWordStartsWith, capitalize } from "../../common/util";

export default function AllItems(props) {
  const { user } = useContext(UserContext);

  const [query, setQuery] = useState("");

  const [products, setProducts] = useState([]);
  const [categoryMap, setCategoryMap] = useState("");

  const [filtered, setFiltered] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    bkstApi(`/my/product?filter=active`).then((res) => {
      let sortedByRank = res.data.products.sort((o1, o2) => {
        let rslt = (+o2.rank || 0) - (+o1.rank || 0);
        if (rslt === 0) {
          rslt = o1.title > o2.title ? 1 : -1;
        }
        return rslt;
      });
      setProducts(sortedByRank);
      setFiltered(sortedByRank);

      let map = {};
      for (const p of sortedByRank) {
        const cat = p.type.toLowerCase();
        const arr = map[cat] || [];
        arr.push(p);
        map[cat] = arr;
      }
      setCategoryMap(map);

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setFiltered([]);
    setFiltered(products.filter((o) => anyWordStartsWith(o.title, query) || anyWordStartsWith(o.type, query)));
  }, [query]);

  const sortCategories = (o1, o2) => {
    if (o1.includes("featured")) {
      return -1;
    } else if (o2.includes("featured")) {
      return 1;
    } else {
      return o1.localeCompare(o2);
    }
  };

  return (
    <Box>
      {products.length > 0 && (
        <Box>
          <Box
            sx={{
              position: "-webkit-sticky",
              position: "sticky",
              top: "0",
              zIndex: "1000",
              backgroundColor: "#F7F7F7",
              paddingBottom: "2rem"
            }}
          >
            <Box sx={{ backgroundColor: "#FFF" }} mb={1}>
              <TextField
                id="query"
                value={query}
                label=""
                name="query"
                variant="outlined"
                fullWidth
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Filter items by name or category"
                InputProps={{
                  startAdornment: (
                    <InputAdornment sx={{ paddingRight: "10px" }}>
                      <FilterListIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment>
                      {query && (
                        <IconButton onClick={(e) => setQuery("")}>
                          <CloseOutlinedIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            {!query && (
              <Box sx={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
                {Object.keys(categoryMap)
                  .sort(sortCategories)
                  .map((cat, idx) => (
                    <Button onClick={() => setQuery(capitalize(cat))} variant="outlined" size="small" color="secondary" disableElevation>
                      {cat}
                    </Button>
                  ))}
              </Box>
            )}
          </Box>
          <Box sx={{ display: "flex", gap: "15px", flexWrap: "wrap" }}>
            <CustomItem
              onChange={(item) => {
                setQuery("");
                props.onChange(item);
              }}
            />
            {filtered.map((o, index) => {
              return (
                <StandardItem
                  product={o}
                  key={o.id}
                  onChange={(item) => {
                    setQuery("");
                    props.onChange(item);
                  }}
                />
              );
            })}
          </Box>
        </Box>
      )}
      {!loading && products.length === 0 && (
        <Container maxWidth="sm">
          <Box py={4} textAlign={"center"}>
            <Typography variant="h5" color="error" gutterBottom>
              No Active Items Found!
            </Typography>
            <Typography variant="body1" color="textSecondary" gutterBottom>
              Click the button below to view your Online Shop & add one or more items. Add everyday, seasonal & limited-time items to make it easier
              to order.
            </Typography>
            <Box my={3}>
              <Button variant="contained" component={RouterLink} to={`/shop`}>
                View Online Shop
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </Box>
  );
}
