import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Button,
  FormControlLabel,
  InputAdornment,
  LinearProgress,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Alert
} from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { UserContext } from "../../context/UserContext";
import { useLocation } from "react-router-dom";
import BakerOrderSummary from "../../baker/components/BakerOrderSummary";
import currency from "currency.js";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import { getPolicyBasedRefund } from "../../common/util";

export default function OrderCancel(props) {
  const { user } = useContext(UserContext);
  let location = useLocation();

  const navigate = useNavigate();

  const { orderId } = useParams();

  const [order, setOrder] = useState("");
  const [refundType, setRefundType] = useState("policy");
  const [amount, setAmount] = useState(0);

  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setOrder();
    bkstApi(`/my/order/${orderId}`)
      .then((res) => {
        document.title = res.data.details?.title;
        setOrder(res.data);
      })
      .catch((error) => {
        navigate(`/dashboard`);
      });
  }, [orderId, location.key]);

  useEffect(() => {
    setAmount(0);

    if (order && order.paymentType !== "offline") {
      if (refundType === "policy") {
        setAmount(getPolicyBasedRefund(order));
      } else if (refundType === "full") {
        setAmount(+order.payment.paid);
      } else if (refundType === "custom") {
        setAmount("");
      }
    }
  }, [order, refundType]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);
    setError("");

    let payload = {};
    if (order.paymentType !== "offline") {
      payload.refund = { type: refundType, amount };
    }

    bkstApi
      .put(`/my/order/${orderId}/cancel`, payload)
      .then((res) => {
        navigate(`/order/${orderId}`);
      })
      .catch((error) => {
        setProcessing(false);
        setError(error.response?.data?.msg);
      });
  };

  if (!order) {
    return <LinearProgress />;
  } else {
    let policyBasedRefund = getPolicyBasedRefund(order);

    return (
      <Box py={6}>
        <Container maxWidth="sm">
          <form onSubmit={handleSubmit}>
            <Box>
              <Box align="center" mb={4}>
                <Typography variant="h3" gutterBottom>
                  Cancel Order?
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  Are you sure you want to cancel this order?
                </Typography>
              </Box>
              <BakerOrderSummary order={order} readOnly={true} />
            </Box>
            <Box my={4}>
              <Typography variant="overline" color="textSecondary">
                REFUND
              </Typography>
              <Paper elevation={0}>
                {order.paymentType !== "offline" && (
                  <Box p={2}>
                    <RadioGroup value={refundType} name="refundType" onChange={(e) => setRefundType(e.target.value)}>
                      <Box py={1}>
                        <FormControlLabel value="policy" control={<Radio required={true} />} label="Policy-based Refund" />
                        <Typography color="textSecondary">
                          {policyBasedRefund > 0 ? currency(policyBasedRefund).format() : "No"} refund - based on your order cancelation policy
                        </Typography>
                      </Box>
                      <Box py={1}>
                        <FormControlLabel value="full" control={<Radio required={true} />} label={`Full Refund`} />
                        <Typography variant="body1" color="textSecondary">
                          {currency(order.payment.paid).format()} refund - the full amount paid by customer
                        </Typography>
                      </Box>
                      <Box py={1}>
                        <FormControlLabel value="custom" control={<Radio required={true} />} label="Custom Refund" />
                        <Typography variant="body1" color="textSecondary">
                          Enter a specific amount for the refund
                        </Typography>
                      </Box>
                    </RadioGroup>
                    {refundType === "custom" && (
                      <Box mt={4}>
                        <TextField
                          id="amount"
                          type="number"
                          value={amount}
                          label="Refund Amount"
                          name="amount"
                          variant="outlined"
                          fullWidth
                          required
                          onChange={(e) => setAmount(e.target.value)}
                          onWheel={(e) => {
                            e.target && e.target.blur();
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <AttachMoneyOutlinedIcon />
                              </InputAdornment>
                            )
                          }}
                          disabled={refundType !== "custom"}
                          error={+amount > +order.payment.paid}
                          helperText={+amount > +order.payment.paid ? `Can't exceed ${currency(order.payment.paid).format()}` : ""}
                        />
                      </Box>
                    )}
                  </Box>
                )}
                {order.paymentType === "offline" && (
                  <Box p={2}>
                    <Alert severity="warning">Refund options are not available for this order since payment was not collected by BakeStreet</Alert>
                  </Box>
                )}
              </Paper>
            </Box>
            <Box my={4}>
              <Box sx={{ width: "100%" }} align="center">
                {error && (
                  <Box my={1}>
                    <Alert severity="error">{error}</Alert>
                  </Box>
                )}
              </Box>
              <Button variant="contained" size="large" fullWidth color="error" type="submit">
                CANCEL ORDER + {+amount === 0 ? "No" : currency(amount).format()} REFUND
              </Button>
              <Box mt={2}>
                <Button variant="text" color="secondary" fullWidth onClick={() => navigate(`/order/${order.id}`)}>
                  BACK TO ORDER
                </Button>
              </Box>
            </Box>
          </form>
        </Container>
        {processing && (
          <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </Box>
    );
  }
}
