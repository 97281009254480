import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  DialogTitle,
  Typography,
  Switch
} from "@mui/material";
import bkstApi from "../../api";
import EditTags from "../../common/search/EditTags";
import CloseIcon from "@mui/icons-material/Close";

export default function GalleryItem(props) {
  const [edit, setEdit] = useState(false);
  const [product, setProduct] = useState(props.product);
  const [status, setStatus] = useState(props.product.status || "");
  const [rank, setRank] = useState(props.product.rank || "");
  const [tags, setTags] = useState(props.product.tags || []);

  const save = () => {
    bkstApi({
      method: "PUT",
      url: `/my/photo/${props.product.id}`,
      data: { status, tags }
    }).then((res) => {
      setProduct({ ...product, status, tags });
      setEdit(false);
    });
  };

  return (
    <Box mb={2}>
      <Box align="center">
        <Link onClick={() => setEdit(true)}>
          <img
            src={product.img}
            style={{
              width: "100%",
              aspectRatio: "1",
              objectFit: "cover",
              borderRadius: "4px",
              opacity: product.status === "DRAFT" ? 0.3 : 1
            }}
          />
          <Box>
            {product.tags?.slice(0, 3).map((e, idx) => (
              <Chip key={idx} size="small" label={e} />
            ))}
            {product.tags?.length > 3 && <Chip size="small" label={`+${product.tags.length - 3} more`} />}
          </Box>
        </Link>
      </Box>
      <Dialog fullWidth open={edit} onClose={() => setEdit(false)}>
        <DialogTitle>
          Edit Details{" "}
          <IconButton
            onClick={() => setEdit(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Box mt={1} mb={4}>
              <Typography variant="body2" color="textSecondary">
                BakeStreet AI automatically scans & tags your photos to make them easier to find, but you can always add your own unique tags too.
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <img src={product.img} style={{ width: "100%", aspectRatio: "1", objectFit: "cover", borderRadius: "5px" }} />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box mb={4}>
                  <FormControl variant="outlined" fullWidth>
                    <EditTags value={tags} onChange={(val) => setTags(val)} multiple={true} />
                  </FormControl>
                </Box>
                <FormControlLabel
                  sx={{ color: "#888" }}
                  control={
                    <Switch
                      checked={status === "ACTIVE" ? true : false}
                      onChange={(e) => setStatus(e.target.checked ? "ACTIVE" : "DRAFT")}
                      color={status === "ACTIVE" ? "success" : "error"}
                    />
                  }
                  label="show in my photo gallery"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={save} fullWidth>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
