import React, { useState, useRef } from "react";
import { Backdrop, Badge, Box, Button, CircularProgress, Dialog, DialogContent, Grid, IconButton, Link, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { bakeStreetLogo, placeholderImg } from "../../common/constants";
import { upload } from "../../firebase";
import { useReactToPrint } from "react-to-print";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AttachPhotos from "../../common/chat/AttachPhotos";
import ExpandableImage from "../../common/ExpandableImage";

const navBtnStyle = {
  position: "absolute",
  zIndex: 2,
  top: "50%",
  transform: "translateY(-50%)",
  color: "#000",
  backgroundColor: "#fff",
  borderColor: "#ebebeb",
  opacity: ".7",
  padding: "0"
};

const navBtnPrevStyle = Object.assign({ left: "8px" }, navBtnStyle);
const navBtnNextStyle = Object.assign({ right: "8px" }, navBtnStyle);

export default function Images(props) {
  const printRef = useRef();

  const [pics, setPics] = useState(props.pics || []);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [open, setOpen] = useState(false);
  const [working, setWorking] = useState(false);

  let style = { width: "100%", aspectRatio: "1", objectFit: "cover" };

  const close = () => {
    setSelectedIdx(0);
    setOpen(false);
  };

  const print = useReactToPrint({
    content: () => printRef.current,
    documentTitle: window.document.title
  });

  const handleUpload = async (event, folder) => {
    if (props.limit) {
      const total = pics.length + event.target.files.length;
      if (total > props.limit) {
        window.alert(`Can't upload more than ${props.limit} photos`);
        return;
      }
    }
    let tasks = [];
    setWorking(true);

    for (var i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];

      // disable heic and heif
      if (file.name.endsWith(".heic") || file.name.endsWith(".heif")) {
        setWorking(false);
        window.alert(`Unsupported file format. Please upload jpeg or png file.`);
        return;
      }

      tasks.push(
        new Promise(async (resolve, reject) => {
          upload(file, folder).then((snapshot) => {
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              resolve(downloadURL);
            });
          });
        })
      );
    }

    const imageUrls = await Promise.all(tasks);
    const arr = pics.concat(imageUrls);

    setPics(arr);
    props.onChange(arr);

    setWorking(false);
  };

  const remove = (idx) => {
    let arr = [...pics];
    arr.splice(idx, 1);
    setPics(arr);

    props.onChange(arr);
    //close();
  };

  const inputComponent = (
    <AttachPhotos
      folder={`item`}
      onChange={(newPics) => {
        const arr = pics.concat(newPics);
        setPics(arr);
        props.onChange(arr);
      }}
    >
      <Button size="small" variant="outlined" sx={{ color: "inherit", border: "1px solid #DDD" }}>
        Add Photos
      </Button>
    </AttachPhotos>
  );

  const backdrop = (
    <Backdrop open={working} style={{ zIndex: "1000", color: "#fff" }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );

  const uploadPlaceholder = (
    <AttachPhotos
      folder={`item`}
      onChange={(newPics) => {
        const arr = pics.concat(newPics);
        setPics(arr);
        props.onChange(arr);
      }}
    >
      <Box>
        <img src={placeholderImg} style={style} />
        <Typography variant="overline">ADD PHOTOS</Typography>
      </Box>
    </AttachPhotos>
  );

  let header = (
    <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
      <IconButton color="inherit" onClick={close}>
        <ArrowBackIosNewIcon />
      </IconButton>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: "1", gap: "10px" }}>
        <Typography variant="h6">{`${pics.length} photos`}</Typography>
        {props.edit && inputComponent}
      </Box>
      {!props.edit && (
        <IconButton color="inherit" onClick={print}>
          <PrintOutlinedIcon />
        </IconButton>
      )}
      <IconButton color="inherit" onClick={close}>
        <CloseIcon />
      </IconButton>
    </Box>
  );

  return (
    <Badge badgeContent={pics.length > 1 ? pics.length : 0} color="info">
      <Box id="images" style={{ cursor: "pointer", backgroundColor: "#ECECEC", padding: "5px", borderRadius: "3px" }} align="center">
        {props.edit && pics.length === 0 ? (
          uploadPlaceholder
        ) : (
          <Carousel
            interval={5000}
            transitionTime={1000}
            showArrows={props.showArrows || false}
            showIndicators={false}
            showStatus={false}
            showThumbs={false}
            onClickItem={() => setOpen(true)}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
              hasPrev && (
                <IconButton size="small" onClick={onClickHandler} title={label} style={navBtnPrevStyle}>
                  <ChevronLeftIcon />
                </IconButton>
              )
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
              hasNext && (
                <IconButton size="small" onClick={onClickHandler} title={label} style={navBtnNextStyle}>
                  <ChevronRightIcon />
                </IconButton>
              )
            }
          >
            {(pics && pics.length > 0 ? pics : [placeholderImg]).map((pic, idx) => {
              return <img alt="" src={pic} key={idx} style={style} />;
            })}
          </Carousel>
        )}
        <Dialog fullScreen open={open} onClose={close}>
          <DialogContent style={{ backgroundColor: "#000", color: "#fff", alignItems: "center", padding: "10px" }}>
            {header}
            {backdrop}
            <Box mt={4} align="center">
              <Grid container spacing={3}>
                {pics?.map((pic, index) => {
                  return (
                    <Grid item xs={6} sm={3} key={index} align="center">
                      <ExpandableImage
                        src={pic}
                        style={{
                          width: "100%",
                          aspectRatio: "1",
                          objectFit: "cover",
                          borderRadius: "4px"
                        }}
                      />

                      <Box sx={{ fontSize: ".8rem" }}>
                        {(props.edit || props.allowDownload) && (
                          <Link underline="always" color="inherit" component="a" href={pic} download target="_blank">
                            download
                          </Link>
                        )}
                        {props.edit && (
                          <Link underline="always" color="inherit" onClick={() => remove(index)} style={{ cursor: "pointer", marginLeft: "1rem" }}>
                            remove
                          </Link>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
            <div style={{ display: "none" }}>
              <Box ref={printRef}>
                {pics.map((pic, idx) => {
                  return (
                    <Box sx={{ pageBreakAfter: pics.length > +idx + 1 ? "always" : "auto" }}>
                      <Box align="center" my={4}>
                        <img alt="logo" src={bakeStreetLogo} style={{ maxHeight: "20pt" }}></img>
                      </Box>
                      <img alt="" src={pic} key={idx} style={{ width: "100%", height: "100%", maxWidth: "100%", maxHeight: "100%" }} />
                    </Box>
                  );
                })}
              </Box>
            </div>
          </DialogContent>
        </Dialog>
      </Box>
    </Badge>
  );
}
