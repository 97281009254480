import React, { useContext, useState, useEffect } from "react";
import {
  Alert,
  Box,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Link,
  IconButton,
  InputAdornment,
  TextField,
  Button,
  Typography
} from "@mui/material";
import moment from "moment";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import BDatePicker from "../input/BDatePicker";
import PaymentDue from "../../order/component/PaymentDue";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function AddDeposit(props) {
  const { fulfillment, paymentSummary } = props;

  const [deposit, setDeposit] = useState("");
  const [dueDate, setDueDate] = useState("");

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);

  const validate = () => {
    let arr = [];
    if (!deposit) {
      arr.push("Deposit amount is missing");
    }
    if (!dueDate) {
      arr.push("Balance due date is missing");
    }

    return arr;
  };

  const handleSubmit = () => {
    const arr = validate();
    setErrors(arr);
    if (arr.length > 0) {
      return;
    } else {
      props.onChange({ deposit, dueDate });
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {paymentSummary.due === paymentSummary.total ? (
        <Button
          startIcon={<AddIcon />}
          color={props.required ? "error" : "primary"}
          onClick={(e) => {
            const daysToDueDate = moment(fulfillment.date).diff(moment(), "days", true);
            let defaultDaysBefore = 0;
            if (daysToDueDate > 7) {
              defaultDaysBefore = 7;
            } else if (daysToDueDate > 3) {
              defaultDaysBefore = 2;
            } else {
              defaultDaysBefore = 1;
            }
            setDueDate(moment(fulfillment.date, "YYYY-MM-DD").subtract(defaultDaysBefore, "days").format("YYYY-MM-DD"));

            setOpen(true);
          }}
        >
          Deposit
        </Button>
      ) : (
        <Link underline="none" onClick={() => setOpen(true)} sx={{ cursor: "pointer" }}>
          <Box p={1} sx={{ backgroundColor: "#ECECEC" }}>
          <PaymentDue total={paymentSummary.total} due={paymentSummary.due} date={fulfillment.date} />
          </Box>
        </Link>
      )}
      <Dialog fullScreen={true} open={open} scroll="paper" onClose={handleClose}>
        <DialogTitle
          sx={{
            padding: "7px 20px",
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0",
            backgroundColor: "#DEDEDE",
            borderBottom: "3px solid #DEDEDE"
          }}
        >
          <IconButton onClick={() => setOpen(false)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5">Deposit & Balance Payment</Typography>
          <Box>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              SUBMIT
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent maxWidth="sm">
          <Container maxWidth="sm">
            {errors?.length > 0 && (
              <Box>
                <Alert severity="error">{errors.join(", ")}</Alert>
              </Box>
            )}
            <Box py={4}>
              <Box py={4}>
                <TextField
                  id="deposit"
                  type="number"
                  value={deposit}
                  label="Deposit Amount"
                  name="deposit"
                  variant="outlined"
                  fullWidth
                  required
                  onChange={(e) => setDeposit(e.target.value)}
                  onWheel={(e) => {
                    e.target && e.target.blur();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  error={+deposit >= +paymentSummary.total}
                  helperText={+deposit >= +paymentSummary.total ? "Deposit amount should be less than total amount" : ""}
                />
              </Box>
              <FormControl variant="outlined" fullWidth>
                <BDatePicker
                  label="Balance Due Date"
                  value={dueDate}
                  onChange={(d) => setDueDate(d)}
                  disablePast
                  maxDate={moment(fulfillment.date, "YYYY-MM-DD").subtract(1, "days").toDate()}
                  required
                  autoOk
                />
              </FormControl>
            </Box>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
}
