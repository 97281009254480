import React, { useState, useEffect, useContext } from "react";
import { Backdrop, Box, Button, CircularProgress, Container, Grid, LinearProgress, Paper, TextField, Typography } from "@mui/material";
import bkstApi from "../../api";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import Price from "../../common/component/Price";

export default function CreateAddOn(props) {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const { orderId } = useParams();
  const [order, setOrder] = useState();

  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [total, setTotal] = useState("");
  const [includeFees, setIncludeFees] = useState(user.config?.transferFees === "false" ? false : true);

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    bkstApi(`/my/order/${orderId}`).then((res) => {
      setOrder(res.data);
      setLoading(false);
    });
  }, [orderId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    let payload = { description, amount, includeFees, total };

    bkstApi.post(`/my/order/${orderId}/add-on`, payload).then((res) => {
      navigate(`/order/${orderId}`);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <Container maxWidth="sm">
          <Box mt={5}>
            <Box mb={4} align="center">
              <Typography variant="h3" gutterBottom>
                Create Order Add-On
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Track changes requested by customer that require additional payment. Things like size upgrade, delivery fee, additional items like
                cupcakes, cookies, etc.
              </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Paper elevation={0}>
                <Box px={3} pt={5} pb={3}>
                  <Grid container spacing={3}>
                    <Grid item sm={6} xs={12}>
                      <Price
                        value={{ amount, includeFees }}
                        onChange={(value) => {
                          setAmount(value.amount);
                          setIncludeFees(value.includeFees);
                          setTotal(value.total);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        id="description"
                        value={description}
                        label="Description"
                        name="description"
                        variant="outlined"
                        fullWidth
                        required
                        onChange={(e) => setDescription(e.target.value)}
                        inputProps={{ maxLength: 100 }}
                        helperText="e.g. Delivery fee, 2 dozen cupcakes"
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
              <Box my={2} sx={{ display: "flex" }}>
                <Button fullWidth variant="outlined" color="secondary" onClick={(e) => navigate(`/order/${orderId}`)} style={{ marginRight: "1rem" }}>
                  Cancel
                </Button>
                <Button fullWidth type="submit" variant="contained" color="primary">
                  Create Add-On
                </Button>
              </Box>
            </form>
          </Box>
          {processing && (
            <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </Container>
      </Box>
    );
  }
}
