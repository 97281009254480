export const timeSlots = [
  "8am - 9am",
  "9am - 10am",
  "10am - 11am",
  "11am - Noon",
  "Noon - 1pm",
  "1pm - 2pm",
  "2pm - 3pm",
  "3pm - 4pm",
  "4pm - 5pm",
  "5pm - 6pm",
  "6pm - 7pm",
  "7pm - 8pm"
];
export const occasions = ["Anniversary", "Birthday", "Baby Shower", "Engagement", "Wedding", "Other"];
export const cakeBudget = ["60", "100", "150", "200", "300", "500", "500+"];
export const otherBudget = ["36 per dozen", "48 per dozen", "60 per dozen", "72 per dozen", "84 per dozen", "84+ per dozen"];
export const placeholderImg = "https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/icon/placeholder.png";
export const bakeStreetSquareLogo =
  "https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Flogo%2Flogo_sq.svg?alt=media&token=1025e184-2f37-464e-8332-e17322088223";
export const spinnerImg =
  "https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fspin.svg?alt=media&token=bf6baa18-0a42-4e42-8347-9e2ba3b8be3a";
export const BAKER_PIC_FOLDER = "https://storage.googleapis.com/bakestreet-f55a0.appspot.com/img/b";
export const STORAGE_BUCKET = "https://storage.googleapis.com/bakestreet-f55a0.appspot.com";
export const IMG_PLACEHOLDER = "/images/placeholder.png";
export const IMG_CELEBRATE =
  "https://firebasestorage.googleapis.com/v0/b/bakestreet-f55a0.appspot.com/o/images%2Fpage%2Forder%2Fparty.svg?alt=media&token=29ab0b62-8645-48ac-bc26-76bc87a4c5ae";

export const PRODUCT_TYPES = [
  "Cake",
  "Cake Bite",
  "Cake Jar",
  "Cake Pop",
  "Cakesicle",
  "Cookie",
  "Cupcake",
  "Dessert",
  "Donut",
  "Macaron",
  "Pie",
  "Pretzel",
  "Pretzel Rod",
  "Pudding",
  "Rice Krispie Treat",
  "Shooter",
  "Whoopie Pie",
  "Other"
];

export const PRODUCT_TYPES_V2 = [
  { name: "Cake", img: "/img/icon/outline/birthday-cake.png" },
  { name: "Cake Bite", img: "/img/icon/outline/bites.png" },
  { name: "Cake Jar", img: "/img/icon/outline/jar.png" },
  { name: "Cake Pop", img: "/img/icon/outline/lollipop.png" },
  { name: "Cakesicle", img: "/img/icon/outline/cakesicle.png" },
  { name: "Cookie", img: "/img/icon/outline/cookies.png" },
  { name: "Cupcake", img: "/img/icon/outline/cupcake.png" },
  { name: "Donut", img: "/img/icon/outline/donut.png" },
  { name: "Macaron", img: "/img/icon/outline/macarons.png" },
  { name: "Pie", img: "/img/icon/outline/tart.png" },
  { name: "Pretzel", img: "/img/icon/outline/pretzel.png" },
  { name: "Pretzel Rod", img: "/img/icon/outline/pretzel-rod.png" },
  { name: "Pudding", img: "/img/icon/outline/pudding.png" },
  { name: "Rice Krispie Treat", img: "/img/icon/outline/rice-krispy.png" },
  { name: "Shooter", img: "/img/icon/outline/mousse.png" },
  { name: "Other", img: "/img/icon/outline/menu.png" }
];

export const SHAPE = ["1-Tier", "2-Tier", "3-Tier", "4-Tier", "Sculpted", "Heart", "Sheet"];

export const SERVINGS = ["8-10", "12-15", "15-20", "20-25", "25-30", "35-40", "40-45", "55-60", "65-70", "80-100"];

export const FROSTING = [
  "Buttercream",
  "Chocolate Ganache",
  "Chantilly cream",
  "Cream cheese",
  "Dominican",
  "Fondant",
  "Suspiro",
  "Naked",
  "Whipped cream",
  "Whipped cream cheese"
];

export const ONE_TIER_SIZES = ["4 inch", "5 inch", "6 inch", "7 inch", "8 inch", "9 inch", "10 inch"];
export const TWO_TIER_SIZES = ["6 inch / 4 inch", "7 inch / 5 inch", "8 inch / 6 inch", "9 inch / 6 inch", "9 inch / 7 inch", "10 inch / 8 inch"];
export const THREE_TIER_SIZES = [
  "8 inch / 6 inch / 4 inch",
  "9 inch / 7 inch / 5 inch",
  "10 inch / 8 inch / 6 inch",
  "11 inch / 9 inch / 7 inch",
  "12 inch / 10 inch / 8 inch"
];
export const FOUR_TIER_SIZES = ["10 inch / 8 inch / 6 inch / 4 inch", "11 inch / 9 inch / 7 inch / 5 inch", "12 inch / 10 inch / 8 inch / 6 inch"];
export const FIVE_TIER_SIZES = ["12 inch / 10 inch / 8 inch / 6 inch / 4 inch", "14 inch / 12 inch / 10 inch / 8 inch / 6 inch"];
export const SIX_TIER_SIZES = ["14 inch / 12 inch / 10 inch / 8 inch / 6 inch / 4 inch", "16 inch / 14 inch / 12 inch / 10 inch / 8 inch / 6 inch"];
export const SHEET_SIZES = ["Quarter Sheet: 9 inch x 13 inch", "Half Sheet: 13 inch x 18 inch", "Full Sheet: 18 inch x 26 inch"];
export const CUSTOM_SIZES = ["3D: 8 inch x 6 inch", "3D: 10 inch x 6 inch"];
export const HEART_SIZES = ["6 inch", "7 inch", "8 inch"];

export const SHAPE_SIZE_MAP = {
  "1-Tier": ONE_TIER_SIZES,
  "2-Tier": TWO_TIER_SIZES,
  "3-Tier": THREE_TIER_SIZES,
  "4-Tier": FOUR_TIER_SIZES,
  "5-Tier": FIVE_TIER_SIZES,
  "6-Tier": SIX_TIER_SIZES,
  Sculpted: [],
  Heart: HEART_SIZES,
  Sheet: SHEET_SIZES
};

// roughly 15 cu in per serving
export const SIZE_SERVINGS_MAP = {
  "4 inch": "4-6",
  "5 inch": "6-8",
  "6 inch": "10-12",
  "7 inch": "14-16",
  "8 inch": "18-22",
  "9 inch": "28-32",
  "10 inch": "38-42",
  "12 inch": "55-60",
  "14 inch": "75-80",
  "6 inch / 4 inch": "15-20",
  "7 inch / 5 inch": "20-25",
  "8 inch / 6 inch": "30-35",
  "9 inch / 6 inch": "40-45",
  "9 inch / 7 inch": "45-50",
  "10 inch / 8 inch": "55-65",
  "8 inch / 6 inch / 4 inch": "35-40",
  "9 inch / 7 inch / 5 inch": "50-55",
  "10 inch / 8 inch / 6 inch": "65-75",
  "11 inch / 9 inch / 7 inch": "85-100",
  "12 inch / 10 inch / 8 inch": "110-125",
  "10 inch / 8 inch / 6 inch / 4 inch": "70-80",
  "11 inch / 9 inch / 7 inch / 5 inch": "90-105",
  "12 inch / 10 inch / 8 inch / 6 inch": "120-135",
  "12 inch / 10 inch / 8 inch / 6 inch / 4 inch": "125-140",
  "14 inch / 12 inch / 10 inch / 8 inch / 6 inch": "195-215",
  "14 inch / 12 inch / 10 inch / 8 inch / 6 inch / 4 inch": "200-220",
  "16 inch / 14 inch / 12 inch / 10 inch / 8 inch / 6 inch": "210-250",
  "Quarter Sheet: 9 inch x 13 inch": "25",
  "Half Sheet: 13 inch x 18 inch": "50",
  "Full Sheet: 18 inch x 26 inch": "100"
};

export const QUOTE_TAGS = [
  "3D Caricature (edible)",
  "3D Figure (toy)",
  "3D Figure (edible)",
  "3D Figure (non-edible)",
  "Candy",
  "Chocolate Shards",
  "Cut-Outs (edible)",
  "Cut-Outs (non-edible)",
  "Decoration (edible)",
  "Decoration (non-edible)",
  "Drip",
  "Feather",
  "Flowers (dried)",
  "Flowers (edible)",
  "Flowers (fresh)",
  "Flowers (silk)",
  "Fruits (fresh)",
  "Geode",
  "Lace",
  "Liquor bottle (mini)",
  "Macarons",
  "Pampas",
  "Photo (edible)",
  "Rosettes",
  "Ruffles",
  "Splash",
  "Stencil",
  "Topper (edible)",
  "Topper (non-edible)"
];

export const TIME_WINDOWS = [
  "8am - 9am",
  "9am - 10am",
  "10am - 11am",
  "11am - Noon",
  "Noon - 1pm",
  "1pm - 2pm",
  "2pm - 3pm",
  "3pm - 4pm",
  "4pm - 5pm",
  "5pm - 6pm",
  "6pm - 7pm",
  "7pm - 8pm"
];

export const ALL_SIZES = ONE_TIER_SIZES.concat(TWO_TIER_SIZES).concat(THREE_TIER_SIZES).concat(SHEET_SIZES).concat(CUSTOM_SIZES);

export const THEME = {
  palette: {
    primary: {
      main: "#78374a"
    },
    secondary: {
      main: "#818181"
    },
    success: {
      main: "#16a085"
    },
    background: {
      default: "#F7F7F7"
    },
    error: {
      main: "#d63031"
    }
  },
  typography: {
    h1: {
      fontSize: "2.25rem",
      fontWeight: "600"
    },
    h2: {
      fontSize: "2.0rem",
      fontWeight: "600"
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: "600"
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: "600"
    },
    h5: {
      fontSize: "1.2rem",
      fontWeight: "600"
    },
    h6: {
      fontSize: "1rem"
    },
    subtitle1: {
      fontSize: "1.1rem"
    },
    overline: {
      lineHeight: "2"
    }
  },
  components: {
    MuiAccordionSummary: {
      root: {
        padding: "0"
      },
      content: {
        "&$expanded": {
          paddingBottom: "10px",
          borderBottom: "1px solid #DDD"
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          margin: "4px"
        },
        outlinedSecondary: {
          borderColor: "#DDD"
        },
        outlinedSuccess: {
          color: "#16a085",
          borderColor: "#16a085"
        },
        outlinedInfo: {
          color: "#2980b9",
          borderColor: "#2980b9"
        },
        filledSuccess: {
          backgroundColor: "#1abc9c"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: "#f7f7f7",
          borderBottom: "1px solid #eee",
          marginBottom: "10px"
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "10px",
          border: "1px solid #eee",
          marginTop: "15px"
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "40px"
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        standardInfo: {
          color: "#2980b9"
        },
        filledSuccess: {
          color: "#fff",
          backgroundColor: "#10ac84"
        },
        filledWarning: {
          backgroundColor: "#ff9f43"
        }
      }
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          fontWeight: "600"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedSizeSmall: {
          fontSize: ".75rem"
        },
        containedSecondary: {
          backgroundColor: "#bdc3c7",
          color: "#555",
          "&:hover": {
            color: "#FFF"
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          minWidth: "300px",
          paddingTop: "20px"
        }
      }
    },
    MuiRating: {
      styleOverrides: {
        sizeSmall: {
          fontSize: "1rem"
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          backgroundColor: "inherit"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: 70
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        asterisk: {
          display: "none"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: ".85rem"
        }
      }
    },
    MuiTabScrollButton: {
      styleOverrides: {
        root: {
          padding: 0,
          "&.Mui-disabled": {
            opacity: 0.3
          }
        }
      }
    }
    /*MuiFormGroup: {
      styleOverrides: {
        root: {
          '& .MuiFormControlLabel-asterisk': {
            color: "#green"
          },
        }
      }
    }*/
  }
};

export const bakeStreetLogo = `${STORAGE_BUCKET}/img/logo/logo.png`;

export const ORDER_PROGRESS_STEPS = ["baking", "icing", "decorating", "review"];

export const SIZE_CHART = {
  Cake: {
    "1-Tier": [
      { size: "4 inch", servings: "4-6" },
      ,
      { size: "6 inch", servings: "6-8" },
      { size: "7 inch", servings: "8-10" },
      { size: "9 inch", servings: "10-15" },
      { size: "10 inch", servings: "20-25" },
      { size: "12 inch", servings: "25-30" }
    ],
    "2-Tier": [
      { size: "6 inch / 9 inch", servings: "25-30" },
      { size: "7 inch / 10 inch", servings: "40" }
    ],
    "3-Tier": [
      { size: "6 inch / 8 inch / 10 inch", servings: "60" },
      { size: "6 inch / 9 inch / 12 inch", servings: "75" },
      { size: "7 inch / 10 inch / 14 inch", servings: "100" }
    ],
    Sheet: [
      { size: "1/4 Sheet", servings: "10-15" },
      { size: "1/2 Sheet", servings: "25-30" },
      { size: "Full Sheet", servings: "40-60" }
    ]
  }
};
