import React, { useEffect, useState, useRef } from "react";
import { Autocomplete, TextField } from "@mui/material";
import bkstApi from "../../api";

export default function EditTags(props) {
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);

    useEffect(() => {
      // Cleanup the previous timeout on re-render
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    const debouncedCallback = (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };

    return debouncedCallback;
  };

  const handleSearch = useDebounce((query) => {
    callApi(query);
  }, 1000);

  const callApi = () => {
    bkstApi(`/my/lookup/tag?q=${q}`).then((res) => {
      const results = res.data;
      setOptions(results.map((o) => o.tag));
      setLoading(false);
    });
  };

  useEffect(() => {
    if (q.length > 1) {
      setLoading(true);
      handleSearch(q);
    } else {
      setOptions([]);
    }
  }, [q]);

  return (
    <Autocomplete
      value={props.value}
      onChange={(e, v) => { console.log('>>>', v); props.onChange(v)}}
      inputValue={q}
      onInputChange={(event, newInputValue) => {
        setQ(newInputValue);
      }}
      options={options}
      filterSelectedOptions
      filterOptions={(x) => x}
      renderInput={(params) => <TextField {...params} variant="outlined" label={props.label || "Tags"} placeholder="Tags" />}
      freeSolo
      multiple={Boolean(props.multiple)}
      blurOnSelect
    />
  );
}
