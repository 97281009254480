import React from "react";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import KitchenOrderSummary from "../../order/component/KitchenOrderSummary";

export default function KitchenSwimLanes(props) {
  const { orders } = props;

  let notStarted = [];
  let inProgress = [];
  let completed = [];

  for (const o of orders) {
    if (o.progress) {
      if (+o.progress?.step === 4) {
        completed.push(o);
      } else if (+o.progress?.step > 0 && o.progress.step < 4) {
        inProgress.push(o);
      } else {
        notStarted.push(o);
      }
    } else {
      notStarted.push(o);
    }
  }

  return (
    <Box>
      <Box my={4}>
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <Typography variant="subtitle1" gutterBottom>
              Not Started ({notStarted.length})
            </Typography>
            <Box sx={{ color: "#e74c3c" }}>
              <LinearProgress variant="determinate" value={100} color="inherit" />
            </Box>
            {notStarted.map((order, idx) => {
              return (
                <Box key={idx} my={2}>
                  <KitchenOrderSummary order={order} />
                </Box>
              );
            })}
          </Grid>
          <Grid item sm={4}>
            <Typography variant="subtitle1" gutterBottom>
              In-Progress ({inProgress.length})
            </Typography>
            <Box sx={{ color: "#fdcb6e" }}>
              <LinearProgress variant="determinate" value={100} color="inherit" />
            </Box>
            {inProgress.map((order, idx) => {
              return (
                <Box key={idx} my={2}>
                  <KitchenOrderSummary order={order} />
                </Box>
              );
            })}
          </Grid>
          <Grid item sm={4}>
            <Typography variant="subtitle1" gutterBottom>
              Completed ({completed.length})
            </Typography>
            <Box sx={{ color: "#00b894" }}>
              <LinearProgress variant="determinate" value={100} color="inherit" />
            </Box>
            {completed.map((order, idx) => {
              return (
                <Box key={idx} my={2}>
                  <KitchenOrderSummary order={order} />
                </Box>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
