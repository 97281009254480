import React, { useContext, useState, useEffect } from "react";
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
  Container
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { UserContext } from "../../context/UserContext";
import StripePaymentElement from "../../common/component/StripePaymentElement";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentStatus from "./PaymentStatus";
import PaymentDue from "../../order/component/PaymentDue";
import PaymentTotal from "../../order/component/PaymentTotal";
import currency from "currency.js";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router";
import bkstApi from "../../api";

const key = process.env.REACT_APP_STRIPE_KEY;
const promise = loadStripe(key);

export default function AddPaymentMethod(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const { paymentSummary, customer } = props;

  const [paymentMethod, setPaymentMethod] = useState(user.config?.readerId ? "reader" : "keyed");

  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState("");

  const [result, setResult] = useState("");

  const validate = () => {
    let arr = [];

    return arr;
  };

  const printReceipt = () => {
    bkstApi
      .post(`/my/printer/${user.config.receiptPrinterId}/job?orderId=${result.orderId || ""}&paymentIntentId=${result.paymentIntentId || ""}`)
      .then((res) => {});
  };

  const handleSubmit = (paymentMethod, paymentMethodId) => {
    const arr = validate();
    setErrors(arr);
    if (arr.length > 0) {
      return;
    } else {
      const promise = props.onSubmit(paymentMethod, paymentMethodId);
      setStatus("processing");

      if (promise) {
        promise
          .then((res) => {
            setResult(res.data);
            setStatus("success");
          })
          .catch((e) => {
            setStatus("fail");
            setErrors([`Error: ${e.response?.data?.message}`]);
          });
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button fullWidth variant="contained" onClick={() => setOpen(true)} disabled={props.disabled}>
        Proceed to Payment
      </Button>
      <Dialog fullScreen={true} open={open} scroll="paper" onClose={handleClose}>
        <DialogTitle
          sx={{
            padding: "15px",
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0",
            backgroundColor: "#DEDEDE",
            borderBottom: "3px solid #DEDEDE"
          }}
        >
          <IconButton
            onClick={() => {
              if (status === "success") {
                navigate("/pos");
              } else {
                setOpen(false);
              }
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h2">{currency(paymentSummary.due).format()} Due</Typography>
          <Box>&nbsp;</Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#F7F7F7" }}>
          <Container maxWidth="md">
            <Box py={4}>
              {status === "success" ? (
                <Box py={4} textAlign={"center"}>
                  <CheckCircleIcon sx={{ fontSize: "5rem", color: "#16a085" }} />
                  <Typography variant="h5" color="textSecondary">
                    {result.quoteId ? "Invoice Sent" : "Payment Received"}
                  </Typography>
                  <Box mt={4} sx={{ display: "flex", gap: "1rem", justifyContent: "center" }}>
                    {result.orderId && (
                      <Button color="secondary" variant="outlined" onClick={() => printReceipt()}>
                        Print Receipt
                      </Button>
                    )}
                    {result.orderId && (
                      <Button color="secondary" variant="outlined" component={RouterLink} to={`/order/${result.orderId}`}>
                        View Order
                      </Button>
                    )}
                    {result.quoteId && (
                      <Button color="secondary" variant="outlined" component={RouterLink} to={`/quote/${result.quoteId}`}>
                        View Invoice
                      </Button>
                    )}
                    <Button color="secondary" variant="outlined" component={RouterLink} to={`/pos`}>
                      New Sale
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box>
                  <Grid container spacing={4}>
                    <Grid item sm={4}>
                      <Box>
                        <Paper elevation={0}>
                          <Box p={2}>
                            <PaymentTotal payment={paymentSummary} />
                            <Box pt={1} mt={2} sx={{ borderTop: "1px solid #EEE" }}>
                              <PaymentDue total={paymentSummary.total} due={paymentSummary.due} date={paymentSummary.dueDate} />
                            </Box>
                          </Box>
                        </Paper>
                      </Box>
                    </Grid>
                    <Grid item sm={8}>
                      <Box>
                        <Box>
                          {errors?.length > 0 && (
                            <Box>
                              <Alert severity="error">{errors.join(", ")}</Alert>
                            </Box>
                          )}
                          <Box>
                            <Tabs value={paymentMethod} variant="scrollable" scrollButtons="auto">
                              {user.config?.readerId && <Tab value="reader" label="Card Reader" onClick={() => setPaymentMethod("reader")} />}
                              <Tab value="keyed" label="Virtual Terminal" onClick={() => setPaymentMethod("keyed")} />
                              <Tab value="cash" label="Cash" onClick={() => setPaymentMethod("cash")} />
                              <Tab value="invoice" label="Invoice" onClick={() => setPaymentMethod("invoice")} />
                            </Tabs>
                            <Box>
                              {paymentMethod === "reader" && (
                                <Box>
                                  <Paper elevation={0}>
                                    <Box p={2} pt={4}>
                                      <Typography variant="body1" color="textSecondary" gutterBottom>
                                        Click the button below to send a payment request to the card reader. Ask customer to Tap or insert card into
                                        the payment terminal to complete payment.
                                      </Typography>
                                    </Box>
                                  </Paper>
                                  <Box mt={2} mb={10}>
                                    <PaymentStatus
                                      amount={paymentSummary.due}
                                      onSubmit={() => props.onSubmit("reader")}
                                      onSuccess={(o) => {
                                        setResult(o);
                                        setStatus("success");
                                      }}
                                      readerId={user.config.readerId}
                                    />
                                  </Box>
                                </Box>
                              )}
                              {paymentMethod === "keyed" && (
                                <Box>
                                  <Paper elevation={0}>
                                    <Box p={2} pt={4}>
                                      <Typography color="textSecondary" variant="body1" gutterBottom>
                                        Virtual Terminal turns your device into a credit card terminal. Collect payments over phone or in-person by
                                        entering the customer's card details below.
                                      </Typography>
                                    </Box>
                                  </Paper>
                                  <Box mt={2} mb={10}>
                                    <Elements stripe={promise}>
                                      <StripePaymentElement
                                        validate={validate}
                                        chargePaymentMethod={(paymentMethodId) => handleSubmit("keyed", paymentMethodId)}
                                        amount={paymentSummary.due}
                                        name={customer.name}
                                        phone={customer.phone}
                                      />
                                    </Elements>
                                  </Box>
                                </Box>
                              )}
                              {paymentMethod === "cash" && (
                                <Box>
                                  <Paper elevation={0}>
                                    <Box p={2} pt={4}>
                                      <Typography color="textSecondary" variant="body1" gutterBottom>
                                        Be sure to collect the cash payment before you click the button below to create an order.
                                      </Typography>
                                      <Box mt={2}>
                                        <Alert severity="warning">
                                          Cash orders are for internal tracking only. They don't track payments, support in-app chat or send automated
                                          notifications like order confirmation, reminders or solicit review.
                                        </Alert>
                                      </Box>
                                    </Box>
                                  </Paper>
                                  <Box mt={2} mb={10}>
                                    <Button color="primary" variant="contained" onClick={() => handleSubmit("cash")} fullWidth>
                                      CREATE {currency(paymentSummary.due).format()} CASH ORDER
                                    </Button>
                                  </Box>
                                </Box>
                              )}
                              {paymentMethod === "invoice" && (
                                <Box>
                                  <Paper elevation={0}>
                                    <Box p={2} pt={4}>
                                      <Typography variant="body1" color="textSecondary" gutterBottom>
                                        Customer will be notified via text & email. The order will be confirmed when the customer pays for the order.
                                      </Typography>
                                    </Box>
                                  </Paper>
                                  <Box mt={2} mb={10}>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      onClick={() => handleSubmit("invoice")}
                                      fullWidth
                                      disabled={!customer}
                                    >
                                      SEND {currency(paymentSummary.due).format()} INVOICE
                                    </Button>
                                    {!customer && (
                                      <Box>
                                        <Typography color="error" variant="caption">
                                          Customer details like name, phone are required to send an invoice. Return to the previous screen to add
                                          customer details.
                                        </Typography>
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Container>
          {status === "processing" && (
            <Backdrop open={true} style={{ zIndex: "1000", color: "#fff" }}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
