import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Typography,
  DialogActions,
  Chip
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import currency from "currency.js";
import { capitalize } from "../../common/util";

export default function ProductOptions(props) {
  const { value } = props;

  const onChange = (mode, idx, data) => {
    const arr = [...value];

    if (mode === "add") {
      arr.push(data);
    } else if (mode === "edit") {
      arr[idx] = data;
    } else if (mode === "delete") {
      arr.splice(idx, 1);
    }
    props.onChange(arr);
  };

  return (
    <Paper elevation={0}>
      <Box>
        <Box px={2} py={1} sx={{ backgroundColor: "#F7F7F7" }}>
          <Grid container alignItems="center">
            <Grid item xs={9}>
              <Typography variant="h6">{props.type} Options</Typography>
            </Grid>
            <Grid item xs={3} align="right">
              <Entry
                category={props.type}
                variant="contained"
                color="default"
                mode="add"
                value=""
                onChange={(mode, data) => onChange(mode, 0, data)}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box px={2} py={1}>
          {value &&
            Object.entries(value)
              .sort()
              .map(([key, value], idx) => {
                return <Row key={key} idx={idx} mode="edit" value={value} category={props.type} onChange={onChange} />;
              })}
          {(!value || value.length === 0) && (
            <Box py={2}>
              <Typography variant="body2">Tap the + button to add {props.type} options</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
}

function Row(props) {
  const { idx, value, category } = props;

  const onChange = (mode, data) => {
    props.onChange(mode, idx, data);
  };

  return (
    <Box my={0.5}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={10}>
          <Typography variant="body1" style={{ wordWrap: "anywhere" }}>
            {value.name}
            {value.pricing?.type === "surcharge" && +value.pricing.amount > 0 && (
              <Chip size="small" label={`+ ${currency(value.pricing.amount).format()}`} />
            )}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {value.description}
          </Typography>
        </Grid>
        <Grid item xs={2} align="right">
          <Entry mode="edit" value={value} category={category} onChange={onChange} />
        </Grid>
      </Grid>
    </Box>
  );
}

function Entry(props) {
  const { mode, value, category } = props;

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setName(props.value?.name || "");
    setType(props.value.pricing?.type || "");
    setAmount(props.value.pricing?.amount || "");
    setDescription(props.value.description || "");
  }, [props.value]);

  const submit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let o = { name, description };
    if (type === "surcharge") {
      o.pricing = { type, amount };
    }
    props.onChange(mode, o);
    setOpen(false);
    reset();
  };

  const handleDelete = () => {
    props.onChange("delete");
    setOpen(false);
    reset();
  };

  const reset = () => {
    setName("");
    setType("");
    setAmount("");
    setDescription("");
  };

  return (
    <span>
      <IconButton size="small" onClick={() => setOpen(true)}>
        {mode === "edit" ? <EditOutlinedIcon /> : <AddIcon />}
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <form onSubmit={submit}>
          <DialogTitle>{`${capitalize(mode)} ${category}`}</DialogTitle>
          <DialogContent>
            <Box py={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    value={name}
                    variant="outlined"
                    fullWidth
                    id="name"
                    label="Name"
                    inputProps={{ maxLength: 40 }}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Grid>
                <Grid item sm={12} xs={12}>
                  <TextField
                    name="description"
                    value={description}
                    variant="outlined"
                    fullWidth
                    id="description"
                    label="Description (optional)"
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => setDescription(e.target.value)}
                    helperText="A short description to help customers compare options"
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <FormControlLabel
                    control={<Switch checked={type === "surcharge"} onChange={(e) => setType(e.target.checked ? "surcharge" : "")} />}
                    label="Premium option (add surcharge)"
                  />
                </Grid>
                {type === "surcharge" && (
                  <Grid item xs={12} sm={5}>
                    <TextField
                      name="amount"
                      value={amount}
                      variant="outlined"
                      id="amount"
                      label="Surcharge"
                      onChange={(e) => setAmount(e.target.value)}
                      onWheel={(e) => {
                        e.target && e.target.blur();
                      }}
                      type="text"
                      onInvalid={(e) => e.target.setCustomValidity("Enter a valid price")}
                      onInput={(e) => e.target.setCustomValidity("")}
                      inputProps={{
                        pattern: "^[0-9]*(.[0-9]{1,2})?$",
                        inputmode: "decimal"
                      }}
                      required
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AttachMoneyOutlinedIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Grid container>
              <Grid item xs={4}>
                {value && (
                  <Button onClick={handleDelete} style={{ color: "#e74c3c" }}>
                    Delete
                  </Button>
                )}
              </Grid>
              <Grid item xs={8}>
                <Box align="right">
                  <Button style={{ marginRight: "15px" }} onClick={() => setOpen(false)}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </span>
  );
}
