import React, { useContext, useEffect, useState } from "react";
import { Alert, Box, Container, Grid, Paper, Typography, IconButton } from "@mui/material";
import bkstApi from "../../api";
import { useNavigate, useLocation } from "react-router-dom";
import { calculatePaymentSummary, getDefaultQuoteExpiration } from "../../common/util";
import moment from "moment";
import { UserContext } from "../../context/UserContext";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import AllItems from "../../product/component/AllItems";
import AddCustomer from "../../customer/component/AddCustomer";
import AddFulfillment from "../../common/component/fulfillment/AddFulfillment";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import LineItemV3 from "../../common/component/lineitem/LineItemV3";
import AddPaymentMethod from "../../common/screen/AddPaymentMethod";
import AddDeposit from "../../common/component/AddDeposit";
import ButtonWithConfirmation from "../../common/ButtonWithConfirmation";
import BlockIcon from "@mui/icons-material/Block";
import PaymentTotal from "../component/PaymentTotal";

export default function POS(props) {
  const navigate = useNavigate();
  let location = useLocation();

  const theme = useTheme();
  const posMode = useMediaQuery(theme.breakpoints.up("sm"));

  const { user } = useContext(UserContext);

  const [items, setItems] = useState([]);
  const [customer, setCustomer] = useState("");
  const [fulfillment, setFulfillment] = useState("");

  const [acceptDeposit, setAcceptDeposit] = useState(false);
  const [deposit, setDeposit] = useState("");
  const [dueDate, setDueDate] = useState("");

  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state) {
      setItems(location.state.items);
      setCustomer(location.state.customer);
      setFulfillment({ ...fulfillment, date: location.state.fulfillment?.date });
    } else {
      setItems([]);
      setCustomer("");
      setFulfillment({
        type: posMode ? "togo" : "pickup",
        date: posMode ? moment().format("YYYY-MM-DD") : "",
        address: posMode ? user.config.storeLocation[0].address : "",
        location: posMode ? user.config.storeLocation[0].address : ""
      });
    }
  }, [location.key]);

  const validate = () => {
    let arr = [];
    if (!fulfillment.date) {
      arr.push("Date is missing");
    }
    if (!fulfillment.location) {
      if (fulfillment.type === "pickup") {
        arr.push("Pickup Location is missing");
      } else if (fulfillment.type === "delivery") {
        arr.push("Delivery Address is missing");
      }
    }
    if (fulfillment.type !== "togo") {
      if (!customer.firstName) {
        arr.push("First Name is missing");
      }
      if (!customer.phone) {
        arr.push("Phone is missing");
      }
    }

    return arr;
  };

  const chargePaymentMethod = (paymentMethodId, amountDue, dueDate) => {
    setLoading(true);

    const payload = {
      amount: amountDue,
      dueDate,
      items,
      fulfillment,
      customer,
      paymentMethodId
    };

    return bkstApi.post(`/my/payment`, payload);
  };

  const sendToCardReader = (amountDue, dueDate) => {
    const arr = validate();
    setErrors(arr);

    if (arr.length === 0) {
      const payload = {
        amount: amountDue,
        dueDate,
        items,
        fulfillment,
        customer
      };

      return bkstApi.post(`/my/reader/${user.config.readerId}`, payload);
    }
  };

  const createOfflineOrder = (amountDue, dueDate) => {
    const arr = validate();

    if (arr.length > 0) {
      setErrors(arr);
    } else {
      setLoading(true);

      const payload = {
        amount: amountDue,
        dueDate,
        items,
        fulfillment,
        customer
      };

      return bkstApi.post(`/my/order`, payload);
    }
  };

  const sendInvoice = () => {
    const arr = validate();

    if (arr.length > 0) {
      setErrors(arr);
    } else {
      setLoading(true);

      let f = { date: fulfillment.date, option: { pickup: {}, delivery: { type: "no" } } };
      if (fulfillment.type === "delivery") {
        if (fulfillment.provider?.name === "metrobi") {
          f.option.delivery.type = "metrobi";
        } else {
          f.option.delivery = fulfillment.option.delivery;

          f.option.delivery.type = "in-house";
          f.option.delivery.location = fulfillment.location;
        }
      }

      const payload = {
        items,
        fulfillment: f,
        customer,
        exdate: getDefaultQuoteExpiration(fulfillment.date),
        mode: "send"
      };

      return bkstApi.post(`/my/quote`, payload);
    }
  };

  const paymentSummary = calculatePaymentSummary(
    items,
    (fulfillment.type === "delivery" && fulfillment.option?.delivery?.total) || 0,
    acceptDeposit,
    deposit
  );
  const allowDeposit = moment(fulfillment.date).startOf("day").diff(moment(), "days", true) >= 1;

  return (
    <Container maxWidth="xl" sx={{ overscrollBehavior: "contain", overflow: "visible" }}>
      <Box my={2}>
        <Box sx={{ display: "flex", gap: "3rem" }}>
          <Box sx={{ flexGrow: "1" }}>
            <AllItems
              onChange={(item) => {
                const arr = [...items];
                arr.push(item);
                setItems(arr);
              }}
            />
          </Box>
          <Box>
            <Box sx={{ position: "-webkit-sticky", position: "sticky", top: "0" }}>
              <Paper elevation={0} sx={{ border: "2px dotted #CCC", minWidth: "300px" }}>
                <Box sx={{ height: "80vh", display: "flex", flexDirection: "column", justifyContent: "space-between", overflowY: "auto" }}>
                  <Box pl={1} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="textSecondary">
                      Current Sale
                    </Typography>
                    <ButtonWithConfirmation copy="Are you sure you want to clear this entry?" onConfirm={() => navigate(0)}>
                      <IconButton size="small">
                        <BlockIcon />
                      </IconButton>
                    </ButtonWithConfirmation>
                  </Box>
                  <Box px={1}>
                    <Box>
                      <AddFulfillment
                        fulfillment={fulfillment}
                        onChange={(o) => {
                          setFulfillment(o);
                        }}
                      />
                    </Box>
                    <Box my={1} sx={{ borderTop: "1px solid #ECECEC", borderBottom: "1px solid #ECECEC" }}>
                      <AddCustomer customer={customer} onChange={(o) => setCustomer(o)} required={fulfillment.type !== "togo"} />
                    </Box>
                  </Box>
                  <Box sx={{ flexGrow: "1" }}>
                    <Box>
                      {(!items || items.length === 0) && (
                        <Box p={2} py={4} sx={{ textAlign: "center" }}>
                          <ShoppingBagOutlinedIcon sx={{ fontSize: "3rem", color: "#999" }} />
                          <Typography variant="h5" gutterBottom color="textSecondary">
                            No items yet!
                          </Typography>
                        </Box>
                      )}
                      {items.map((o, idx) => (
                        <Box py={2} pr={1} key={idx}>
                          <LineItemV3
                            item={o}
                            view="min"
                            onChange={(o) => {
                              let arr = [...items];
                              if (!o) {
                                // delete
                                arr.splice(idx, 1);
                              } else {
                                arr[idx] = o;
                              }
                              setItems(arr);
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>
                  {items.length > 0 && (
                    <Box>
                      <Box px={1}>
                        <PaymentTotal payment={paymentSummary} />
                      </Box>
                      {allowDeposit && (
                        <AddDeposit
                          paymentSummary={paymentSummary}
                          fulfillment={fulfillment}
                          onChange={(o) => {
                            setDeposit(o.deposit);
                            setDueDate(o.dueDate);
                            setAcceptDeposit(true);
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Paper>
              {errors && errors.length > 0 && (
                <Box my={2}>
                  <Alert severity="error">{errors.join(". ")}</Alert>
                </Box>
              )}
              <Box my={2}>
                <AddPaymentMethod
                  disabled={items.length === 0 || (fulfillment.type !== "togo" && !customer)}
                  paymentSummary={paymentSummary}
                  customer={customer}
                  onSubmit={(paymentMethod, paymentMethodId) => {
                    if (paymentMethod === "reader") {
                      return sendToCardReader(paymentSummary.due, dueDate);
                    } else if (paymentMethod === "cash") {
                      return createOfflineOrder(paymentSummary.due, dueDate);
                    } else if (paymentMethod === "keyed") {
                      return chargePaymentMethod(paymentMethodId, paymentSummary.due, dueDate);
                    } else if (paymentMethod === "invoice") {
                      return sendInvoice();
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
