import React, { useContext, useState, useEffect } from "react";
import { Box, Button, Dialog, DialogContent, Typography, DialogActions, LinearProgress } from "@mui/material";
import bkstApi from "../../api";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContactlessOutlinedIcon from "@mui/icons-material/ContactlessOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import WarningOutlinedIcon from "@mui/icons-material/WarningOutlined";
import MultipleStopOutlinedIcon from "@mui/icons-material/MultipleStopOutlined";
import currency from "currency.js";
import { db } from "../../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { UserContext } from "../../context/UserContext";
import { Link as RouterLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function PaymentStatus(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const { readerId, amount } = props;

  const [open, setOpen] = useState(false);

  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [procId, setProcId] = useState("");

  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [unsubscribeFn, setUnsubscribeFn] = useState("");

  const sendToReader = () => {
    setPaymentIntentId("");

    const promise = props.onSubmit();
    if (promise) {
      setOpen(true);

      promise
        .then((res) => {
          setStatus("processing");
          setMessage("Tap or insert card into payment terminal");

          setPaymentIntentId(res.data.paymentIntentId);
        })
        .catch((e) => {
          setStatus(e.response?.data?.status || "failed");

          setPaymentIntentId(e.response?.data.paymentIntentId || "");
          setMessage(e.response?.data?.message);
        });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setStatus("");
    setMessage("");
    setPaymentIntentId("");
    setProcId("");

    if (unsubscribeFn) {
      unsubscribeFn();
      setUnsubscribeFn("");
    }
  };

  const cancelAction = () => {
    if (paymentIntentId) {
      bkstApi.delete(`/my/reader/${readerId}/payment/${paymentIntentId}`);
    }
    handleClose();
  };

  const retry = (override) => {
    console.log("retry with ", override);
    let payload = {};
    if (override) {
      payload.override = true;
    }

    if (paymentIntentId) {
      setStatus("");
      bkstApi
        .post(`/my/reader/${readerId}/payment/${paymentIntentId}`, payload)
        .then((res) => {
          setStatus("processing");
          setMessage("Tap or insert card into payment terminal");
        })
        .catch((e) => {
          setStatus(e.response?.data?.status || "failed");
          setPaymentIntentId(e.response?.data.paymentIntentId || "");
          setMessage(e.response?.data?.message);
        });
    } else {
      // if initial sendTorReader failed, there's no paymentIntent. Simply close the popup
      handleClose();
    }
  };

  useEffect(() => {
    // re-create subscription every time pi changes, since fn doesn't get the update pi state
    if (readerId && paymentIntentId && !unsubscribeFn) {
      console.log("CREATING NEW SUBSCRIPTION");
      checkReaderStatus();
    }
  }, [readerId, paymentIntentId]);

  const checkReaderStatus = () => {
    var docRef = doc(db, `/baker/${user.acctId}/card-reader/${readerId}`);
    const fn = onSnapshot(docRef, async (doc) => {
      console.log("-->", doc.data());
      const o = doc.data();

      const paymentIntentFromReader = o.action.process_payment_intent?.payment_intent;
      setProcId(paymentIntentFromReader);
      console.log("compare", paymentIntentId, paymentIntentFromReader);
      if (!paymentIntentFromReader || paymentIntentFromReader === paymentIntentId) {
        if (o.action.status === "in_progress") {
          setStatus("processing");
          setMessage("Tap or insert card into payment terminal");
        } else if (o.action.status === "succeeded") {
          if (props.onSuccess) {
            props.onSuccess({ paymentIntentId });
            handleClose();
          } else {
            setStatus("success");
            setMessage("Payment Successful");
          }
        } else if (o.action.status === "failed") {
          setStatus("failed");
          setMessage(`${o.action.failure_message?.split(". ")?.[0]}`);
        }
      }
    });
    setUnsubscribeFn(() => fn);
  };

  const checkPaymentIntentStatus = () => {
    bkstApi(`/my/reader/${readerId}/payment/${paymentIntentId}/status`).then((res) => {
      const cStatus = res.data.status;
      const msg = res.data.message;

      if (cStatus === "success") {
        setStatus("success");
        setMessage("Payment Successful");
      } else if (cStatus === "processing") {
        setStatus("processing");
        setMessage("Tap or insert card into payment terminal");
      } else if (cStatus === "failed") {
        setStatus("failed");
        setMessage(res.data.message || "This payment request has timed out");
      }
    });
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={sendToReader} fullWidth disabled={+amount <= 0.5}>
        CHARGE {currency(amount).format()}
      </Button>
      {+amount < 0.5 && (
        <Box my={0.25} textAlign={"center"}>
          <Typography variant="caption" color="error">
            Charge amount should be $0.50 or more
          </Typography>
        </Box>
      )}
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth="sm"
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "#333"
            }
          }}
        >
          <LinearProgress variant={!status || status === "processing" ? "indeterminate" : "determinate"} value={0} />
          <DialogContent>
            <Box textAlign={"center"}>
              <Box my={2}>
                <Typography variant="h3">{currency(amount).format()}</Typography>
              </Box>
              <Box>
                {!status && <MultipleStopOutlinedIcon sx={{ fontSize: "5rem", color: "#999" }} />}
                {status === "processing" && <ContactlessOutlinedIcon sx={{ fontSize: "5rem", color: "#999" }} />}
                {status === "success" && <CheckCircleIcon sx={{ fontSize: "5rem", color: "#16a085" }} />}
                {status === "busy" && <WarningOutlinedIcon sx={{ fontSize: "5rem", color: "#d35400" }} />}
                {status === "failed" && <ErrorIcon sx={{ fontSize: "5rem", color: "#c0392b" }} />}
                {status === "timeout" && <ErrorIcon sx={{ fontSize: "5rem", color: "yellow" }} />}
              </Box>
              <Typography>{message || (!status && "Sending to card reader")}</Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            {(!status || status === "processing") && (
              <>
                <Button color="secondary" variant="contained" onClick={cancelAction} fullWidth>
                  Cancel
                </Button>
              </>
            )}
            {status === "failed" && (
              <>
                <Button color="secondary" variant="contained" onClick={cancelAction} fullWidth>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={() => retry(false)} fullWidth>
                  Retry
                </Button>
              </>
            )}
            {status === "busy" && (
              <>
                <Button color="secondary" variant="contained" onClick={cancelAction} fullWidth>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={() => retry(true)} fullWidth>
                  Override
                </Button>
              </>
            )}
            {status === "success" && (
              <>
                <Button color="secondary" variant="outlined" to={`/order`} component={RouterLink} fullWidth>
                  View Recent Orders
                </Button>
                <Button color="primary" variant="contained" onClick={() => navigate(0)} fullWidth>
                  Start New Order
                </Button>
              </>
            )}
            {status === "timeout" && (
              <>
                <Button color="secondary" variant="outlined" onClick={cancelAction} fullWidth>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={checkPaymentIntentStatus} fullWidth>
                  Check Status
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
