import React, { useEffect, useState, useRef } from "react";
import { Box, Button, Chip, Container, IconButton, Link, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

const FILTERS = ["all", "order", "chat"];

export default function Notifications(props) {
  const ref = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("all");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [loading, setLoading] = useState(true);

  const COLUMNS = [
    {
      field: "description",
      headerName: "Details",
      renderCell: (params) => (
        <Box pt={1} sx={{ color: params.row.status === "closed" ? "#999" : "#000" }}>
          <Typography variant="body2" sx={{ fontWeight: "700" }}>
            {params.row.heading}
          </Typography>
          <Typography title={params.value} variant="body2">
            {params.value}
          </Typography>
          <IconButton size="small" to={params.row.link} component={RouterLink} onClick={() => changeStatus([params.row.id], "read")}>
            <VisibilityOutlinedIcon sx={{ color: params.row.status === "closed" ? "#999" : "#444" }} />
          </IconButton>
        </Box>
      ),
      flex: 1,
      maxWidth: isMobile ? 200 : 400
    },
    {
      field: "time",
      headerName: "Date",
      type: "datetime",
      valueGetter: ({ value }) => value && new Date(value),
      renderCell: (params) => (
        <Box sx={{ color: params.row.status === "closed" ? "#999" : "#000" }}>
          <Typography variant="caption" display="block">
            {moment(params.value).format("MMM DD")}
          </Typography>
          <Typography variant="caption" display="block">
            {moment(params.value).format("LT")}
          </Typography>
        </Box>
      ),
      width: 75
    }
  ];

  useEffect(() => {
    bkstApi.get("/my/notification?status=all").then((res) => {
      setData(res.data.notifications.sort(sort));
      setLoading(false);

      if (ref && ref.current) {
        ref.current.scrollIntoView();
      }
    });
  }, []);

  const sort = (o1, o2) => {
    if (o1.status === o2.status) {
      if (o1.status === "open") {
        if (o1.priority > o2.priority) {
          return 1;
        } else if (o1.priority < o2.priority) {
          return -1;
        } else {
          return o1.link.localeCompare(o2.link);
        }
      } else {
        // closed status sorted by ts
        return o1.ts._seconds < o2.ts._seconds ? 1 : -1;
      }
    } else {
      return o1.status === "closed" ? 1 : -1;
    }
  };

  const changeStatus = (arr, markAs) => {
    console.log("->", arr);
    const updates = data.map((o) => {
      if (arr.includes(o.id)) {
        o.status = markAs === "unread" ? "open" : "closed";
      }

      return o;
    });
    setData(updates);
    setRowSelectionModel([]);

    bkstApi.put(`/my/notification`, { ids: arr, markAs });
  };

  const show = (heading) => {
    /*if (filter === "unread" && status === "closed") {
      return false;
    } else if (filter === "read" && status !== "closed") {
      return false;
    } else {
      return true;
    }*/
    if (filter === "order" && heading !== "New Order" && !heading.includes("Order for")) {
      return false;
    } else if (filter === "add-on" && heading !== "Invoice Paid") {
      return false;
    } else if (filter === "chat" && !(heading.includes("sent a message") || heading.includes("messaged about"))) {
      return false;
    } else {
      return true;
    }
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    const filteredRows = data.filter((o) => show(o.heading));
    return (
      <Container maxWidth="sm" ref={ref}>
        <Box py={6}>
          <Box align="center" mb={1}>
            <Typography variant="h3">Notifications</Typography>
          </Box>
          <Box align="center">
            {FILTERS.map((o, idx) => {
              return (
                <Chip
                  key={idx}
                  size="medium"
                  color={filter === o ? "primary" : "secondary"}
                  variant="outlined"
                  label={o}
                  clickable
                  component={Link}
                  onClick={() => setFilter(o)}
                />
              );
            })}
          </Box>
        </Box>
        <Box>
          <Box mb={1} sx={{ display: "flex", gap: "5px", justifyContent: "flex-end", alignItems: "center" }}>
            <Button size="small" color="secondary" variant="outlined" onClick={() => changeStatus(rowSelectionModel, "read")}>
              Mark Read
            </Button>
            <Button size="small" color="secondary" variant="outlined" onClick={() => changeStatus(rowSelectionModel, "unread")}>
              Mark Unread
            </Button>
          </Box>
          <DataGrid
            rows={filteredRows.map((o) => {
              return {
                id: o.id,
                heading: o.heading,
                description: o.text,
                time: o.ts._seconds * 1000,
                link: o.link,
                status: o.status
              };
            })}
            columns={COLUMNS}
            autoHeight
            getRowHeight={() => "auto"}
            checkboxSelection
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableColumnMenu={true}
          />
        </Box>
      </Container>
    );
  }
}
