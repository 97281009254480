import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, Switch, DialogActions } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { capitalize } from "../../common/util";
import CloseIcon from "@mui/icons-material/Close";

export default function AddOption(props) {
  const { options, value } = props;

  const [open, setOpen] = useState(false);

  return (
    <span>
      <IconButton onClick={() => setOpen(true)}>
        <AddIcon />
      </IconButton>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>
          Options
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            {options.map((key) => (
              <Box mt={2}>
                <FormControlLabel
                  control={<Switch checked={value.includes(key)} onChange={(e) => props.onChange({ key, state: e.target.checked })} />}
                  label={capitalize(key)}
                />
              </Box>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setOpen(false)} fullWidth>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
