import React, { useContext, useState } from "react";
import {
  Alert,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  FormControl,
  TextField,
  Button,
  Link,
  IconButton,
  Paper,
  Typography,
  Container
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { formatPhoneNumber, isEmailValid } from "../../common/util";
import PhoneField from "../../common/input/PhoneField";
import EmailInput from "../../common/component/EmailInput";

export default function AddCustomer(props) {
  const { user } = useContext(UserContext);

  const [customer, setCustomer] = useState("");
  const [open, setOpen] = useState(false);

  const [errors, setErrors] = useState([]);

  const handleSubmit = () => {
    const arr = validate();
    setErrors(arr);
    if (arr.length > 0) {
      return;
    } else {
      props.onChange(customer);
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = () => {
    let arr = [];
    if (!customer.firstName) {
      arr.push("First Name is missing");
    }
    if (!customer.phone) {
      arr.push("Phone is missing");
    }
    if (customer.email && !isEmailValid(customer.email)) {
      arr.push("Email is incorrect");
    }
    return arr;
  };

  return (
    <>
      <Box>
        {customer ? (
          <Link
            color="inherit"
            underline="never"
            sx={{ cursor: "pointer", height: "100%" }}
            onClick={(e) => {
              setCustomer(props.customer || "");
              setOpen(true);
            }}
          >
            <Box px={1} py={0.5} sx={{ backgroundColor: "#F7F7F7" }}>
              <Typography sx={{ fontSize: "0.9rem" }}>
                {customer.firstName} {customer.lastName}
              </Typography>
              <Typography sx={{ fontSize: "0.8rem" }} color="textSecondary">
                {formatPhoneNumber(customer.phone)}
              </Typography>
            </Box>
          </Link>
        ) : (
          <Box>
            <Button
              startIcon={<AddIcon />}
              color={props.required ? "error" : "primary"}
              onClick={(e) => {
                setOpen(true);
              }}
            >
              Customer {props.required ? " (Required)" : ""}
            </Button>
          </Box>
        )}
      </Box>
      <Dialog fullScreen={true} open={open} scroll="paper">
        <DialogTitle
          sx={{
            padding: "7px 20px",
            display: "flex",
            gap: "10px",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0",
            backgroundColor: "#DEDEDE",
            borderBottom: "3px solid #DEDEDE"
          }}
        >
          <IconButton onClick={() => setOpen(false)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5">Set Customer</Typography>
          <Box>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              SUBMIT
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#F7F7F7" }}>
          <Container maxWidth="sm">
            <Box py={4}>
              <Paper elevation={0}>
                {errors && errors.length > 0 && (
                  <Box mb={2}>
                    <Alert severity="error">{errors.join(", ")}</Alert>
                  </Box>
                )}
                <Box px={2} py={4}>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        <FormControl variant="outlined" fullWidth>
                          <PhoneField name="Phone" value={customer?.phone} onChange={(phone) => setCustomer({ ...customer, phone })} required />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            value={customer?.firstName}
                            variant="outlined"
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            onChange={(e) => setCustomer({ ...customer, firstName: e.target.value })}
                            required
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            value={customer?.lastName}
                            variant="outlined"
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            onChange={(e) => setCustomer({ ...customer, lastName: e.target.value })}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <EmailInput value={customer?.email} onChange={(val) => setCustomer({ ...customer, email: val })} />
                      </Grid>
                    </Grid>
                    <Box></Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
}
