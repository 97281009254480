import React from "react";
import { Box, Typography, Link, Paper } from "@mui/material";

export default function Option(props) {
  const { price, img, title, description, selected } = props;

  return (
    <Link underline="none" onClick={() => props.onClick(!selected)} sx={{ color: "inherit", position: "relative" }}>
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "150px",
          minHeight: "75px",
          backgroundColor: selected ? "#faf3f5" : "#ECECEC",
          borderRadius: "4px",
          cursor: "pointer",
          border: selected ? "2px solid #78374a" : "none"
        }}
        elevation={selected ? 0 : 2}
      >
        <Box py={2} px={1}>
          {img ? (
            <Box textAlign="center">
              <img src={img} style={{ height: "50px", width: "50px" }} />
              <Typography variant="overline" display="block" sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {title}
              </Typography>
            </Box>
          ) : (
            <Box textAlign="center">
              <Typography variant="body1" sx={{ textAlign: "center"}}>{title}</Typography>
              <Typography variant="caption" sx={{ textAlign: "center" }}>
                {description}
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>
      {price && (
        <Box textAlign={"center"}>
          <Typography color="textSecondary" sx={{ paddingTop: "2px", paddingRight: "5px", fontSize: "12px" }}>
            {price}
          </Typography>
        </Box>
      )}
    </Link>
  );
}
