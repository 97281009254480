import React from "react";
import { Box, Collapse, Typography } from "@mui/material";
import currency from "currency.js";
import Option from "./Option";

export default function ProductVariantSelectionV3(props) {
  const { options, variant } = props;

  return (
    <Box>
      <Collapse in={options?.size && !variant?.size}>
        <Box mb={4}>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            Size
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
            {options?.size &&
              !variant?.size &&
              options.size.map((option, idx) => {
                let title = option.size;
                if (option.shape) {
                  title += ` ${option.shape?.toLowerCase()}`;
                }

                let description = option.description || "";
                if (option.servings) {
                  description += ` (${option.servings} servings)`;
                }
                if (option.frosting && option.shape) {
                  description += `${option.frosting} • ${option.shape}`;
                }

                return (
                  <Option
                    price={+option.pricing.amount > 0 ? currency(option.pricing.amount).format() : ""}
                    title={title}
                    description={description}
                    onClick={(selected) => props.onChange({ ...variant, size: selected ? option : "" })}
                    selected={variant?.size?.size === option.size}
                  />
                );
              })}
          </Box>
        </Box>
      </Collapse>
      <Collapse in={options?.flavor && !variant?.flavor?.base}>
        <Box mb={4}>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            Flavor
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
            {options?.flavor &&
              !variant?.flavor?.base &&
              options.flavor
                .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                .map((option, idx) => {
                  const tmp = options.flavor.filter((o) => o.name === option.name);
                  const surcharge = (tmp && tmp.length > 0 && tmp[0].pricing?.amount) || "";

                  return (
                    <Option
                      price={+surcharge > 0 ? currency(surcharge).format() : ""}
                      title={option.name}
                      onClick={(selected) => {
                        // change format
                        let o = variant?.flavor || {};
                        o.base = { name: option.name };
                        if (option.pricing?.amount && +option.pricing?.amount > 0) {
                          o.base.pricing = { amount: option.pricing?.amount };
                        }
                        props.onChange({ ...variant, flavor: selected ? o : "" });
                      }}
                      selected={variant?.flavor?.base?.name === option.name}
                    />
                  );
                })}
          </Box>
        </Box>
      </Collapse>
      <Collapse in={options?.filling && !variant?.flavor?.filling}>
        <Box mb={4}>
          <Typography variant="h4" color="textSecondary" gutterBottom>
            Filling
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
            {options?.filling &&
              !variant?.flavor?.filling &&
              options.filling
                .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                .map((option, idx) => {
                  const tmp = options.filling.filter((o) => o.name === option.name);
                  const surcharge = (tmp && tmp.length > 0 && tmp[0].pricing?.amount) || "";

                  return (
                    <Option
                      price={+surcharge > 0 ? currency(surcharge).format() : ""}
                      title={option.name}
                      onClick={(selected) => {
                        // change format
                        let o = variant?.flavor || {};
                        o.filling = { name: option.name };
                        if (option.pricing?.amount && +option.pricing?.amount > 0) {
                          o.filling.pricing = { amount: option.pricing?.amount };
                        }
                        props.onChange({ ...variant, flavor: selected ? o : "" });
                      }}
                      selected={variant?.flavor?.filling?.name === option.name}
                    />
                  );
                })}
          </Box>
        </Box>
      </Collapse>
      {Object.entries(options).map(([key, value]) => {
        if (["size", "flavor", "filling", "add-on"].includes(key)) {
          return <></>;
        } else {
          return (
            <Collapse in={!variant?.[key]}>
              <Box mb={4}>
                <Typography variant="h4" color="textSecondary" gutterBottom sx={{ textTransform: "capitalize" }}>
                  {key}
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
                  {!variant?.[key] &&
                    value
                      .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
                      .map((option, idx) => {
                        const surcharge = option?.pricing?.amount || "";
                        return (
                          <Option
                            price={+surcharge > 0 ? currency(surcharge).format() : ""}
                            title={option.name}
                            onClick={(selected) => {
                              let o = { ...variant };
                              o[key] = selected ? option : "";
                              props.onChange(o);
                            }}
                            selected={variant?.[key]?.name === option.name}
                          />
                        );
                      })}
                </Box>
              </Box>
            </Collapse>
          );
        }
      })}
      {options?.[`add-on`] && (
        <Box mb={4}>
          <Typography variant="h4" color="textSecondary" gutterBottom sx={{ textTransform: "capitalize" }}>
            Add-Ons (Optional)
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "30px" }}>
            {options?.[`add-on`]
              .sort((o1, o2) => (o1.name > o2.name ? 1 : -1))
              .map((option, idx) => {
                let isSelected = false;
                if (variant?.[`add-on`]?.filter((o) => o.name === option.name)?.length > 0) {
                  isSelected = true;
                }

                let surcharge = "";
                if (+option.pricing?.amount > 0) {
                  surcharge = currency(option.pricing?.amount).format();
                } else if (+option.pricing?.factor > 0) {
                  surcharge = `${+option.pricing?.factor * 100}%`;
                }

                return (
                  <Option
                    price={surcharge}
                    title={option.name}
                    onClick={(selected) => {
                      let arr = variant?.[`add-on`] || [];

                      if (selected) {
                        arr.push(option);
                      } else {
                        const index = arr.findIndex((o) => o.name === option.name);
                        arr.splice(index, 1);
                      }

                      let o = { ...variant };
                      o[`add-on`] = arr;
                      props.onChange(o);
                    }}
                    selected={isSelected}
                  />
                );
              })}
          </Box>
        </Box>
      )}
    </Box>
  );
}
