import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import Orders from "../../baker/screen/Orders";
import { useNavigate } from "react-router";
import KitchenOrdersByDate from "../../baker/screen/KitchenOrdersByDate";
import OwnerDashboard from "./OwnerDashboard";
import ManagerDashboard from "./ManagerDashboard";
import ClerkDashboard from "./ClerkDashboard";
import POS from "../../order/screen/POS";

export default function Dashboard(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  console.log("ROLE", user.role);
  if (user.role === "baker" || user.role === "owner") {
    return <OwnerDashboard />;
  } else if (user.role === "rep" || user.role === "manager") {
    return <ManagerDashboard />;
  } else if (user.role === "kitchen" || user.role === "kitchen leader") {
    return <Orders />;
  } else if (user.role === "clerk") {
    if (user.acctId === "99i69dd8bwTqlxzVLZUx" || user.acctId === "-MX2oaNrh4TcKVvnhcT9" || user.acctId === "-MVIf1diaYOCm5KynVDF") {
      return <POS />;
    } else {
      return <ClerkDashboard />;
    }
  }
}
