import React, { useContext, useState } from "react";
import {
  Box,
  Collapse,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import { bakeStreetLogo } from "../constants";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { UserContext } from "../../context/UserContext";
import { getDeviceToken } from "../../firebase";
import bkstApi from "../../api";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CreateMenu from "./CreateMenu";
import StarHalfOutlinedIcon from "@mui/icons-material/StarHalfOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PhotoLibraryOutlinedIcon from "@mui/icons-material/PhotoLibraryOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import AltRouteOutlinedIcon from "@mui/icons-material/AltRouteOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";

export default function ResponsiveDrawer(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [showPlanningMenu, setShowPlanningMenu] = useState(false);
  const [showReportsMenu, setShowReportsMenu] = useState(false);
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);

  const container = window !== undefined ? () => document.body : undefined;

  const enablePushNotifications = async (e) => {
    e.preventDefault();
    const token = await getDeviceToken();
    if (token) {
      bkstApi.post(`/fcm`, { token });
    }
  };

  const handleReportsMenuClick = (e) => {
    e.stopPropagation();
    setShowReportsMenu(!showReportsMenu);
  };

  const handlePlanningMenuClick = (e) => {
    e.stopPropagation();
    setShowPlanningMenu(!showPlanningMenu);
  };

  const handleSettingsMenuClick = (e) => {
    e.stopPropagation();
    setShowSettingsMenu(!showSettingsMenu);
  };

  let home = "/dashboard";
  if (user?.role === "kitchen" || user?.role === "kitchen leader") {
    home = "/";
  } else if (user?.role === "clerk") {
    home = "/";
  }

  const isOwner = user.role === "baker" || user.role === "owner";
  const isManager = user.role === "rep" || user.role === "manager";

  const drawer = (
    <div>
      <div>
        <Box sx={{ height: "65px", display: "flex", gap: "5px", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <Link component={RouterLink} to={home}>
            <img alt="logo" style={{ width: "110px" }} src={bakeStreetLogo}></img>
          </Link>
        </Box>
      </div>
      {(isOwner || isManager) && (
        <List sx={{ padding: 0 }}>
          <Box px={2} pb={2}>
            <CreateMenu />
          </Box>
          <ListItemButton component={RouterLink} to="/quote">
            <ListItemIcon>
              <LocalAtmOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={`Quotes`} />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/order">
            <ListItemIcon>
              <LocalMallOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Orders" />
          </ListItemButton>
          <Divider variant="middle" style={{ marginTop: "15px", marginBottom: "15px" }} />
          <Box>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/shop">
                <ListItemIcon>
                  <ShoppingCartOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={`Online Shop`} />
              </ListItemButton>
            </ListItem>
            <Divider variant="middle" style={{ marginTop: "15px", marginBottom: "15px" }} />
          </Box>
          <Box>
            <ListItem onClick={handlePlanningMenuClick} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <AssignmentOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Planning Tools" />
                {showPlanningMenu ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={showPlanningMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                  <ListItemButton component={RouterLink} to="/calendar">
                    <ListItemIcon>
                      <ChevronRightOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Calendar`} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                  <ListItemButton component={RouterLink} to="/planning/fulfillment">
                    <ListItemIcon>
                      <ChevronRightOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Fulfillment Planner`} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
          </Box>
          {isOwner && (
            <Box>
              <ListItem onClick={handleReportsMenuClick} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <AssessmentOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Reports" />
                  {showReportsMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={showReportsMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                    <ListItemButton component={RouterLink} to="/report/sales">
                      <ListItemIcon>
                        <ChevronRightOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Sales`} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                    <ListItemButton component={RouterLink} to="/report/tips">
                      <ListItemIcon>
                        <ChevronRightOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Tips`} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                    <ListItemButton component={RouterLink} to="/report/refunds">
                      <ListItemIcon>
                        <ChevronRightOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Refunds`} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                    <ListItemButton component={RouterLink} to="/report/payouts">
                      <ListItemIcon>
                        <ChevronRightOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Payouts`} />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Collapse>
              <Box>
                <Divider variant="middle" style={{ marginTop: "15px", marginBottom: "15px" }} />
                <Box>
                  <ListItem disablePadding>
                    <ListItemButton component={RouterLink} to="/loan">
                      <ListItemIcon>
                        <AccountBalanceOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Working Capital`} />
                    </ListItemButton>
                  </ListItem>
                </Box>
              </Box>
            </Box>
          )}
          <Box>
            <Box>
              <ListItem disablePadding>
                <ListItemButton component={RouterLink} to="/reviews">
                  <ListItemIcon>
                    <StarHalfOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Reviews`} />
                </ListItemButton>
              </ListItem>
            </Box>
            <ListItem onClick={handleSettingsMenuClick} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
                {showSettingsMenu ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={showSettingsMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {isOwner && (
                  <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                    <ListItemButton component={RouterLink} to="/settings/staff">
                      <ListItemIcon>
                        <Groups2OutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Staff`} />
                    </ListItemButton>
                  </ListItem>
                )}
                <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                  <ListItemButton component={RouterLink} to="/settings/store-location">
                    <ListItemIcon>
                      <StorefrontOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Stores`} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                  <ListItemButton component={RouterLink} to="/settings/photos">
                    <ListItemIcon>
                      <PhotoLibraryOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Photos`} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                  <ListItemButton component={RouterLink} to="/settings/variants">
                    <ListItemIcon>
                      <AltRouteOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={`Variants`} />
                  </ListItemButton>
                </ListItem>
                {isOwner && (
                  <ListItem disablePadding sx={{ paddingLeft: "1rem" }}>
                    <ListItemButton component={RouterLink} to="/plan">
                      <ListItemIcon>
                        <CardMembershipOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary={`Plan`} />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </Collapse>
            <Divider variant="middle" style={{ marginTop: "15px" }} />
          </Box>
        </List>
      )}
      <List>
        {(user.role === "kitchen" || user.role === "kitchen leader") && (
          <Box>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/calendar">
                <ListItemIcon>
                  <CalendarMonthOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={`Calendar`} />
              </ListItemButton>
            </ListItem>
            <Divider variant="middle" style={{ marginTop: "15px", marginBottom: "15px" }} />
          </Box>
        )}
        {user.role === "clerk" && (
          <Box>
            <Box px={2} pb={2}>
              <CreateMenu />
            </Box>
            <ListItem disablePadding>
              <ListItemButton component={RouterLink} to="/order">
                <ListItemIcon>
                  <LocalMallOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Orders" />
              </ListItemButton>
            </ListItem>
          </Box>
        )}
        {user.acctId === "-MX2oaNrh4TcKVvnhcT9" && (
          <ListItem disablePadding>
            <ListItemButton component={RouterLink} to="/pos">
              <ListItemIcon>
                <PointOfSaleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={`POS`} />
            </ListItemButton>
          </ListItem>
        )}
        <ListItem disablePadding>
          <ListItemButton component={RouterLink} to="/release-notes">
            <ListItemIcon>
              <NewReleasesOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={`Release Notes`} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              window.$zoho.salesiq.visitor.name(`${user.profile?.bizName} ${user.firstName ? `: ${user.firstName} ${user.lastName || ""}` : ""}`);
              window.$zoho.salesiq.chat.waittime(600);
              window.$zoho.salesiq.chat.start();
            }}
          >
            <ListItemIcon>
              <LiveHelpOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={`Help`} />
          </ListItemButton>
        </ListItem>
      </List>
      <Box p={4} align="center" sx={{ borderTop: "1px solid #EEE" }}>
        <Box>
          <Typography variant="caption" color="textSecondary" onClick={enablePushNotifications}>
            {process.env.REACT_APP_VERSION}
          </Typography>
        </Box>
        <Typography variant="caption" color="textSecondary" onClick={enablePushNotifications}>
          App Version
        </Typography>
      </Box>
    </div>
  );

  // collapsed menu for kitchen staff
  if (user.role === "clerk" || user.role === "kitchen" || user.role === "kitchen leader" || window.location.pathname === `/pos`) {
    return (
      <nav aria-label="main-menu">
        <Drawer
          container={container}
          variant="temporary"
          anchor={"left"}
          open={props.open}
          onClose={props.toggle}
          onClick={props.toggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            width: "240px",
            flexShrink: 0,
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: "240px" }
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    );
  } else {
    return (
      <nav aria-label="main-menu">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={"left"}
            open={props.open}
            onClose={props.toggle}
            onClick={props.toggle}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
            sx={{
              width: "240px",
              flexShrink: 0,
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: "240px" }
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            sx={{
              width: "240px",
              flexShrink: 0,
              "& .MuiDrawer-paper": { boxSizing: "border-box", width: "240px" },
              "& a": { color: "inherit" }
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    );
  }
}
