import React, { useContext, useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import { getAmountWithTxnFee } from "../util";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import currency from "currency.js";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "../../context/UserContext";

export default function Price(props) {
  const { amount, includeFees } = props.value;
  const { user } = useContext(UserContext);

  const [open, setOpen] = useState(false);

  const handleChange = (amount, includeFees) => {
    const total = includeFees ? getAmountWithTxnFee(amount, user.config?.fees) : amount;
    props.onChange({ amount, includeFees, total });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="amount"
            type="number"
            value={amount}
            label={props.label || "Amount"}
            name="amount"
            variant="outlined"
            fullWidth
            required
            onChange={(e) => handleChange(e.target.value, includeFees)}
            onWheel={(e) => {
              e.target && e.target.blur();
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: props.disabled ? "#F7F7F7" : "#FFFFFF"
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyOutlinedIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <Box>
                  {includeFees && +amount > 0 && (
                    <Box sx={{ textAlign: "right", minWidth: "4rem" }}>
                      <Link onClick={(e) => setOpen(true)} color="inherit" underline="none" sx={{ cursor: "pointer" }}>
                        <Typography sx={{ fontSize: "1rem", lineHeight: "1", color: "#777" }}>
                          {currency(getAmountWithTxnFee(amount, user.config?.fees)).format()}
                        </Typography>
                        <Typography variant="caption" color="textSecondary">
                          with fees
                        </Typography>
                      </Link>
                    </Box>
                  )}
                </Box>
              )
            }}
            disabled={props.disableAmount}
            helperText=""
          />
          <Box>
            <FormControlLabel
              className="includeFees"
              control={
                <Checkbox
                  checked={includeFees}
                  onChange={(e) => handleChange(amount, e.target.checked)}
                  name="consent"
                  color="secondary"
                  size="small"
                  disabled={props.disableFees}
                />
              }
              label="include transaction fees"
              sx={{ color: "#888", fontSize: "0.9rem" }}
            />
          </Box>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          Include Transaction Fees{" "}
          <IconButton
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Grid container>
              <Grid item xs={6} alignItems="center">
                <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                  <Typography variant="body1">{currency(amount).format()}</Typography>
                  <Typography variant="h5" sx={{ fontWeight: "700" }}>
                    &#8674; {currency(getAmountWithTxnFee(amount, user.config?.fees)).format()}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Box py={2}>
              <Typography variant="body1" color="textSecondary">
                You've chosen to add your transaction fees to the amount you've entered. Customer will see and pay the final amount shown above.
                You'll get the amount entered in your bank account.
              </Typography>
            </Box>
            <Typography variant="body1" color="textSecondary">
              If you do not wish to include transaction fees, simply close this popup and uncheck the "include transaction fees" checkbox.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
