import React, { useState, useRef } from "react";
import { Badge, Box, Chip, IconButton, InputAdornment, TextField } from "@mui/material";
import bkstApi from "../../api";
import SendIcon from "@mui/icons-material/Send";
import CancelIcon from "@mui/icons-material/Cancel";
import AttachPhotos from "./AttachPhotos";
import AttachFileIcon from "@mui/icons-material/AttachFile";

export default function NewMessage(props) {
  const inputRef = useRef(null);

  const [msg, setMsg] = useState("");
  const [pics, setPics] = useState([]);

  const [lineBreaks, setLineBreaks] = useState(1);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!processing) {
      setProcessing(true);
      const payload = { msg, pics, lastMsgTs: props.lastMsgTs };

      bkstApi.post(props.postUrl, payload).then((res) => {
        setProcessing(false);
      });

      setMsg("");
      setPics([]);
      setLineBreaks(1);

      props.handleNewMsg(payload);
    }
    e.target && e.target.blur();
  };

  const onEnter = (e) => {
    if (e.key === "Enter" && msg.trim().length > 0) {
      //handleSubmit(e);
      const tmp = lineBreaks + 1;
      setLineBreaks(tmp);
    }
  };

  const removePhoto = (idx) => {
    let arr = [...pics];
    arr.splice(idx, 1);
    setPics(arr);
  };

  let rows = Math.round(msg.length / 40) + +lineBreaks;
  rows = rows > 6 ? 6 : rows;

  return (
    <Box px={1} py={1}>
      <form onSubmit={handleSubmit}>
        <Box display="flex">
          <Box>
            <AttachPhotos
              folder={`chat`}
              onChange={(selected) => {
                setPics(selected);
                inputRef?.current?.focus();
              }}
            >
              <IconButton>
                <AttachFileIcon />
              </IconButton>
            </AttachPhotos>
          </Box>
          <Box sx={{ flexGrow: "1" }}>
            <TextField
              inputRef={inputRef}
              id="msg"
              value={msg}
              name="msg"
              variant="outlined"
              multiline
              rows={rows}
              fullWidth
              placeholder="Type a message"
              onChange={(e) => setMsg(e.target.value)}
              onKeyDown={onEnter}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="primary" type="submit" variant="contained" disabled={msg.length === 0 && pics.length === 0} size="small">
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  paddingRight: 0
                },
                backgroundColor: "#FFFFFF"
              }}
            />
            {pics && pics.length > 0 && (
              <Box
                align="center"
                style={{ display: "flex", gap: "15px", padding: "15px 0px 5px 10px", border: "1px dashed #DDD", backgroundColor: "#F7F7F7" }}
              >
                {pics.map((pic, idx) => {
                  return (
                    <Badge
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right"
                      }}
                      badgeContent={
                        <IconButton onClick={() => removePhoto(idx)} size="small" sx={{ backgroundColor: "#FFF", padding: "2px" }}>
                          <CancelIcon sx={{ fontSize: "15px" }} />
                        </IconButton>
                      }
                    >
                      <img key={idx} src={pic} style={{ width: "40px", height: "40px", objectFit: "cover" }} />
                    </Badge>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>
      </form>
      {props.templates && (
        <Box style={{ marginLeft: "30px" }}>
          {props.templates.map((t) => (
            <Chip key={t.name} size="small" label={t.name} component="a" onClick={() => setMsg(t.text)} clickable />
          ))}{" "}
        </Box>
      )}
    </Box>
  );
}
