import React, { useContext, useEffect, useState } from "react";
import { Avatar, Box, Button, Container, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import currency from "currency.js";
import SearchIcon from "@mui/icons-material/Search";
import { UserContext } from "../../context/UserContext";
import BDatePicker from "../../common/input/BDatePicker";
import { useLocation } from "react-router-dom";
import { capitalize, getQueryParam } from "../../common/util";
import { useNavigate } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link as RouterLink } from "react-router-dom";
import FeatureAccess from "../../baker/components/FeatureAccess";
import { STORAGE_BUCKET } from "../../common/constants";

const columns = [
  {
    field: "id",
    headerName: "Order",
    renderCell: (params) => (
      <IconButton component={RouterLink} to={`/order/${params.value}`}>
        <VisibilityOutlinedIcon />
      </IconButton>
    ),
    width: 50
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
    type: "datetime",
    valueGetter: ({ value }) => value && new Date(value),
    renderCell: (params) => (
      <div>
        <Box py={1}>
          <Typography variant="caption" display="block">
            {moment(params.value).format("ll")}
          </Typography>
          <Typography variant="overline" display="block" color="textSecondary">
            {moment(params.value).format("LT")}
          </Typography>
        </Box>
      </div>
    ),
    width: 100
  },
  {
    field: "refund",
    headerName: "Amount",
    type: "number",
    valueFormatter: ({ value }) => currency(value).format(),
    width: 80
  },
  {
    field: "uid",
    headerName: "By",
    renderCell: (params) => <Avatar src={`${STORAGE_BUCKET}/img/u/${params.value}.webp`} sx={{ width: "32px", height: "32px" }}></Avatar>,
    width: 50
  },
  {
    field: "reason",
    headerName: "Reason",
    width: 200,
    renderCell: (params) => (
      <div>
        <Typography variant="body2" display="block">
          {params.value}
        </Typography>
      </div>
    )
  },
  {
    field: "name",
    headerName: "Customer",
    width: 160
  }
];

export default function RefundsReport(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  let location = useLocation();

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [refunds, setRefunds] = useState([]);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);

  // Check params to identify search criteria
  useEffect(() => {
    let [qFrom, qTo] = [getQueryParam(window.location.search, "from"), getQueryParam(window.location.search, "to")];
    if (!qFrom || !qTo) {
      // reset dates
      qFrom = moment().subtract(7, "days").format("YYYY-MM-DD");
      qTo = moment().format("YYYY-MM-DD");
    }
    search(qFrom, qTo);
  }, [location.key]);

  useEffect(() => {
    if (toDt < fromDt) {
      setToDt("");
    }
  }, [fromDt]);

  const runDateSearch = (e) => {
    e.preventDefault();
    navigate(`/report/refunds?from=${fromDt}&to=${toDt}`);
  };

  const search = (fromDate, toDate) => {
    setLoading(true);
    bkstApi.get(`/my/report/refunds?fromDt=${fromDate}&toDt=${toDate}`).then((res) => {
      let arr = [];

      let [count, total] = [0, 0];
      for (const o of res.data.orders) {
        count++;

        for (const r of o.payment.refund) {
          total += +r.amount;
          arr.push({
            id: o.id,
            ts: r.ts,
            name: o.customer.name,
            amount: r.amount,
            reason: `${capitalize(r.reason)}. ${r.note}`,
            uid: r.uid,
            type: o.type
          });
        }
      }

      setTotal(total);

      setFromDt(fromDate);
      setToDt(toDate);

      arr.sort((o1, o2) => o1.ts - o2.ts);
      setRefunds(arr);

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <Container maxWidth="sm">
          <FeatureAccess plan={["starter", "professional", "premium"]} />
          <Box>
            <Box mt={4}>
              <Typography variant="h4" gutterBottom>
                Refunds Report
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Refunds issued over a given time period
              </Typography>
              <Box pt={1} my={2} sx={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}>
                <form onSubmit={runDateSearch}>
                  <Box py={2} align="center" sx={{ display: "flex", justifyContent: "center" }}>
                    <BDatePicker value={fromDt} label="From Date" onChange={(d) => setFromDt(d)} required autoOk disableFuture hideIcon={true} />
                    <BDatePicker value={toDt} label="To Date" onChange={(d) => setToDt(d)} required autoOk disableFuture hideIcon={true} />
                    <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
                      <SearchIcon />
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
          {refunds && refunds.length > 0 && (
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} align="center">
                  <Box px={2} py={3} style={{ backgroundColor: "#ECECEC" }}>
                    <Typography variant="h4">{currency(total).format()}</Typography>
                    <Typography variant="overline" color="textSecondary">
                      TOTAL
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} align="center">
                  <Box px={2} py={3} style={{ backgroundColor: "#ECECEC" }}>
                    <Typography variant="h4">{refunds.length}</Typography>
                    <Typography variant="overline" color="textSecondary">
                      REFUNDS
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box sx={{ width: "100%", display: "grid" }}>
            <DataGrid
              rows={refunds.map((o) => {
                return {
                  id: o.id,
                  timestamp: o.ts,
                  name: o.name,
                  refund: o.amount,
                  reason: o.reason,
                  uid: o.uid,
                  type: o.type
                };
              })}
              columns={columns}
              autoHeight
              getRowHeight={(params) => "auto"}
              disableRowSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "time", sort: "desc" }]
                }
              }}
            />
          </Box>
        </Container>
      </Box>
    );
  }
}
