import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Chip, Container, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import currency from "currency.js";
import SearchIcon from "@mui/icons-material/Search";
import { UserContext } from "../../context/UserContext";
import BDatePicker from "../../common/input/BDatePicker";
import { useLocation } from "react-router-dom";
import { getQueryParam } from "../../common/util";
import { useNavigate } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link as RouterLink } from "react-router-dom";
import FeatureAccess from "../../baker/components/FeatureAccess";

const columns = [
  {
    field: "payoutDate",
    headerName: "Payout Date",
    type: "datetime",
    valueGetter: ({ value }) => value && new Date(value),
    renderCell: (params) => (
      <div>
        <Box py={2}>
          <Typography variant="body2" display="block">
            {moment(params.value).format("ll")}
          </Typography>
        </Box>
      </div>
    ),
    width: 120
  },
  {
    field: "amount",
    headerName: "Total",
    type: "number",
    valueFormatter: ({ value }) => currency(value, { fromCents: true }).format(),
    width: 100
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    renderCell: (params) => <Chip size="small" label={params.value} color={params.value === "paid" ? "success" : "secondary"} />
  },
  {
    field: "settlementDate",
    headerName: "Available On",
    type: "datetime",
    valueGetter: ({ value }) => value && new Date(value),
    valueFormatter: ({ value }) => moment(value).add(12, "hours").format("ll"),
    width: 120
  },
  {
    field: "id",
    headerName: "Transaction History",
    renderCell: (params) =>
      params.row.automatic ? (
        <IconButton component={RouterLink} to={`/payout/${params.value}`}>
          <VisibilityOutlinedIcon />
        </IconButton>
      ) : (
        <Typography variant="caption">Manual Payout</Typography>
      ),
    width: 150
  }
];

export default function SalesReport(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  let location = useLocation();

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [payouts, setPayouts] = useState([]);

  const [totalAmmount, setTotalAmount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [loading, setLoading] = useState(true);

  // Check params to identify search criteria
  useEffect(() => {
    let [qFrom, qTo] = [getQueryParam(window.location.search, "from"), getQueryParam(window.location.search, "to")];
    if (!qFrom || !qTo) {
      // reset dates
      qFrom = moment().subtract(30, "days").format("YYYY-MM-DD");
      qTo = moment().format("YYYY-MM-DD");
    }
    search(qFrom, qTo);
  }, [location.key]);

  useEffect(() => {
    if (toDt < fromDt) {
      setToDt("");
    }
  }, [fromDt]);

  const runDateSearch = (e) => {
    e.preventDefault();
    navigate(`/report/payouts?from=${fromDt}&to=${toDt}`);
  };

  const search = (fromDate, toDate) => {
    setLoading(true);
    bkstApi.get(`/my/report/payouts?fromDate=${fromDate}&toDate=${toDate}`).then((res) => {
      setPayouts(res.data.payouts);

      let [count, total] = [0, 0];
      for (const o of res.data.payouts) {
        count++;
        total += +o.amount;
      }
      setTotalCount(count);
      setTotalAmount(total);

      setFromDt(fromDate);
      setToDt(toDate);

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box>
        <Container maxWidth="sm">
          <FeatureAccess plan={["starter", "professional", "premium"]} />
          <Box>
            <Box mt={4}>
              <Typography variant="h4" gutterBottom>
                Payouts Report
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Your payments are aggregated and the payouts are sent daily. Your bank may take upto 2 business days to make the funds available.
              </Typography>
              <Box pt={1} my={2} sx={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}>
                <form onSubmit={runDateSearch}>
                  <Box py={2} align="center" sx={{ display: "flex", justifyContent: "center" }}>
                    <BDatePicker value={fromDt} label="From Date" onChange={(d) => setFromDt(d)} required autoOk disableFuture hideIcon={true} />
                    <BDatePicker value={toDt} label="To Date" onChange={(d) => setToDt(d)} required autoOk disableFuture hideIcon={true} />
                    <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
                      <SearchIcon />
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
          {payouts && payouts.length > 0 && (
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} align="center">
                  <Box p={2} style={{ backgroundColor: "#ECECEC" }}>
                    <Typography variant="h6">{currency(totalAmmount, { fromCents: true }).format()}</Typography>
                    <Typography variant="overline" color="textSecondary">
                      TOTAL AMOUNT
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} align="center">
                  <Box p={2} style={{ backgroundColor: "#ECECEC" }}>
                    <Typography variant="h6">{totalCount}</Typography>
                    <Typography variant="overline" color="textSecondary">
                      PAYOUTS
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
          {/* orders && orders.length > 0 && (
            <Box my={2}>
              {" "}
              <Alert severity="info">Payouts are aggregated and sent out daily. Your bank may take 2-3 business days to process the funds.</Alert>
            </Box>
          )*/}
          <Box sx={{ width: "100%", display: "grid" }}>
            <DataGrid
              rows={payouts.map((o) => {
                return {
                  id: o.id,
                  payoutDate: o.created * 1000,
                  amount: o.amount,
                  status: o.status,
                  settlementDate: o.arrival_date * 1000,
                  automatic: o.automatic
                };
              })}
              columns={columns}
              autoHeight
              getRowHeight={(params) => "auto"}
              disableRowSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: "payoutDate", sort: "desc" }]
                }
              }}
            />
          </Box>
        </Container>
      </Box>
    );
  }
}
