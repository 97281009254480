import React, { useContext, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, Container, Grid, IconButton, LinearProgress, Typography } from "@mui/material";
import bkstApi from "../../api";
import currency from "currency.js";
import SearchIcon from "@mui/icons-material/Search";
import { UserContext } from "../../context/UserContext";
import BDatePicker from "../../common/input/BDatePicker";
import { useLocation } from "react-router-dom";
import { getCustomerName, getQueryParam } from "../../common/util";
import { useNavigate } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Link as RouterLink } from "react-router-dom";
import FeatureAccess from "../../baker/components/FeatureAccess";
import { equalsIgnoreCase } from "../../common/util";
import ItemFilter from "../../planner/component/ItemFilter";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { LineChart } from "@mui/x-charts/LineChart";
import { STORAGE_BUCKET } from "../../common/constants";

const columns = [
  {
    field: "id",
    headerName: "Order",
    renderCell: (params) => (
      <IconButton component={RouterLink} to={`/order/${params.value}`}>
        <VisibilityOutlinedIcon />
      </IconButton>
    ),
    width: 50
  },
  {
    field: "time",
    headerName: "Timestamp",
    type: "datetime",
    valueGetter: ({ value }) => value && new Date(value),
    renderCell: (params) => (
      <div>
        <Box py={1}>
          <Typography variant="caption" display="block">
            {moment(params.value).format("ll")}
          </Typography>
          <Typography variant="overline" display="block" color="textSecondary">
            {moment(params.value).format("LT")}
          </Typography>
        </Box>
      </div>
    ),
    width: 120
  },
  {
    field: "name",
    headerName: "Customer",
    width: 160
  },
  {
    field: "total",
    headerName: "Total",
    type: "number",
    valueFormatter: ({ value }) => currency(value).format(),
    width: 100
  }
];

export default function SalesReport(props) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  let location = useLocation();

  const [fromDt, setFromDt] = useState("");
  const [toDt, setToDt] = useState("");

  const [orders, setOrders] = useState([]);

  const [filter, setFilter] = useState();
  const [filteredResults, setFilteredResults] = useState([]);

  const [loading, setLoading] = useState(true);

  // Check params to identify search criteria
  useEffect(() => {
    let [qFrom, qTo] = [getQueryParam(window.location.search, "from"), getQueryParam(window.location.search, "to")];
    if (!qFrom || !qTo) {
      // reset dates
      qFrom = moment().subtract(7, "days").format("YYYY-MM-DD");
      qTo = moment().format("YYYY-MM-DD");
    }
    search(qFrom, qTo);
  }, [location.key]);

  useEffect(() => {
    if (toDt < fromDt) {
      setToDt("");
    }
  }, [fromDt]);

  useEffect(() => {
    let arr = [];
    arr = orders.filter((o) => {
      // check filters
      for (const [k, v] of Object.entries(filter)) {
        if (v.value) {
          if (k === "type") {
            if (v.value === "standard" && o.type !== "shop") {
              return false;
            } else if (v.value === "custom" && o.type === "shop") {
              return false;
            }
          } else if (k === "fulfillment" && !equalsIgnoreCase(o.fulfillment.type, v.value)) {
            return false;
          } else if (k === "store") {
            if (o.fulfillment.location) {
              if (!equalsIgnoreCase(o.fulfillment.location.street, v.value)) {
                return false;
              }
            } else if (!o.fulfillment.address?.toLowerCase().startsWith(v.value?.toLowerCase())) {
              // fallback to string address
              return false;
            }
          }
        }
      }
      // passed all filters
      return true;
    });
    setFilteredResults(arr);
  }, [filter, orders]);

  const runDateSearch = (e) => {
    e.preventDefault();
    navigate(`/report/sales?from=${fromDt}&to=${toDt}`);
  };

  const search = (fromDate, toDate) => {
    setLoading(true);
    bkstApi.get(`/my/report/sales?fromDt=${fromDate}&toDt=${toDate}`).then((res) => {
      setFilter("");
      setOrders(res.data.orders);

      setFromDt(fromDate);
      setToDt(toDate);

      setLoading(false);
    });
  };

  if (loading) {
    return <LinearProgress />;
  } else {
    let rollup = {};
    for (const order of filteredResults) {
      const key = moment(order.ts._seconds * 1000).format("YYYY-MM-DD");

      let arr = rollup[key]?.orders || [];
      arr.push(order);
      rollup[key] = {
        orders: arr,
        total: arr.reduce((acc, o) => +acc + +(o.src === "mkt" ? o.payment.bakerTotal : o.payment.total), 0),
        tips: arr.reduce((acc, o) => +acc + +o.payment.tip, 0)
      };
    }
    const summaryByDate = Object.entries(rollup)
      .sort(([k1, v1], [k2, v2]) => k1.localeCompare(k2))
      .map(([k, v]) => {
        return { date: k, sale: v.total, tips: v.tips, orders: v.orders };
      });

    return (
      <Box>
        <Container maxWidth="sm">
          <FeatureAccess plan={["starter", "professional", "premium"]} />
          <Box>
            <Box mt={4}>
              <Typography variant="h4" gutterBottom>
                Sales Report
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Daily Sales data by order placed date
              </Typography>
              <Box py={2} my={2} sx={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}>
                <form onSubmit={runDateSearch}>
                  <Box pt={1} align="center" sx={{ display: "flex", justifyContent: "center" }}>
                    <BDatePicker value={fromDt} label="From Date" onChange={(d) => setFromDt(d)} required autoOk disableFuture hideIcon={true} />
                    <BDatePicker value={toDt} label="To Date" onChange={(d) => setToDt(d)} required autoOk disableFuture hideIcon={true} />
                    <Button variant="contained" color="primary" type="submit" style={{ height: "56px" }}>
                      <SearchIcon />
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
          {orders && orders.length > 0 && (
            <Box>
              <Box my={2}>
                <ItemFilter
                  config={[
                    { name: "type", options: ["custom", "standard"] },
                    { name: "fulfillment", options: orders.map((o) => o.fulfillment?.type) },
                    filter?.["fulfillment"]?.value === "pickup"
                      ? {
                          name: "store",
                          options: orders.map((o) => o.fulfillment.type === "pickup" && o.fulfillment.location?.street)
                        }
                      : ""
                  ]}
                  data={orders}
                  onChange={(o) => {
                    let tmp = { ...filter, ...o };

                    // clear out store location filter if fulfillment is not pickup
                    if (tmp["store"]?.value) {
                      if (tmp["fulfillment"]?.value !== "pickup") {
                        delete tmp["store"];
                      }
                    }
                    setFilter(tmp);
                  }}
                />
              </Box>
            </Box>
          )}
          {summaryByDate && summaryByDate.length > 1 && (
            <Box sx={{ backgroundColor: "#ECECEC", borderRadius: "4px" }}>
              <LineChart
                dataset={summaryByDate.map((o) => {
                  return { date: moment(o.date, "YYYY-MM-DD").format("MMM D"), sale: o.sale };
                })}
                xAxis={[{ scaleType: "point", dataKey: "date" }]}
                series={[
                  {
                    dataKey: "sale",
                    color: "#78374a",
                    label: "Sales (USD)",
                    showMark: ({ index }) => index % 2 === 0
                  }
                ]}
                height={250}
              />
            </Box>
          )}
          {orders && orders.length > 0 && (
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} align="center">
                  <Box px={2} pt={2} pb={1} style={{ backgroundColor: "#ECECEC", borderRadius: "3px" }}>
                    <Typography variant="overline">SALES</Typography>
                    <Typography variant="h5" gutterBottom>
                      {currency(
                        filteredResults.reduce((acc, o) => +acc + +(o.src === "mkt" ? o.payment.bakerTotal : o.payment.total), 0),
                        { precision: 0 }
                      ).format()}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" display={"block"}>
                      {currency(
                        filteredResults.reduce((acc, o) => +acc + +o.payment.tip, 0),
                        { precision: 0 }
                      ).format()}{" "}
                      Tips
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} align="center">
                  <Box px={2} pt={2} pb={1} style={{ backgroundColor: "#ECECEC", borderRadius: "3px" }}>
                    <Typography variant="overline">ORDERS</Typography>
                    <Typography variant="h5" gutterBottom>
                      {filteredResults.length}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" display={"block"}>
                      &nbsp;
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box my={2}>
                <Alert severity="info">Future orders may not be paid in full yet.</Alert>
              </Box>
            </Box>
          )}
          <Box>
            {summaryByDate.map((o) => {
              return (
                <Box my={2}>
                  <Accordion elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                      <Box pt={1} sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <Box sx={{ flexGrow: "1", textAlign: "center" }}>
                          <Typography variant="overline">{moment(o.date, "YYYY-MM-DD").format("ddd")}</Typography>
                          <Typography variant="h6">{moment(o.date, "YYYY-MM-DD").format("MMM D")}</Typography>
                        </Box>
                        <Box sx={{ flexGrow: "1", textAlign: "center" }}>
                          <Typography variant="overline">Sales</Typography>
                          <Typography variant="h6">{currency(o.sale, { precision: 0 }).format()}</Typography>
                          <Typography variant="caption" color="textSecondary">
                            {currency(o.tips, { precision: 0 }).format()} Tips
                          </Typography>
                        </Box>
                        <Box sx={{ flexGrow: "1", textAlign: "center" }}>
                          <Typography variant="overline">Orders</Typography>
                          <Typography variant="h6">{o.orders.length}</Typography>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box>
                        <Box sx={{ width: "100%", display: "grid" }}>
                          <DataGrid
                            rows={o.orders.map((od) => {
                              return {
                                id: od.id,
                                time: od.ts._seconds * 1000,
                                name: getCustomerName(od.customer),
                                total: od.src === "mkt" ? od.payment.bakerTotal : od.payment.total,
                                type: od.type
                              };
                            })}
                            columns={columns}
                            autoHeight
                            getRowHeight={(params) => "auto"}
                            disableRowSelectionOnClick
                            initialState={{
                              sorting: {
                                sortModel: [{ field: "time", sort: "desc" }]
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
          </Box>
          {filteredResults.length === 0 && (
            <Box my={2} px={2} pb={2} textAlign={"center"} sx={{ border: "1px solid #DDD" }}>
              <img style={{ width: "250px" }} src={`${STORAGE_BUCKET}/img/icon/search-results.svg`} />
              <Typography variant="subtitle1">Nothing found</Typography>
              <Typography variant="body1" color="textSecondary">
                Please change your search criteria & try again.
              </Typography>
            </Box>
          )}
        </Container>
      </Box>
    );
  }
}
